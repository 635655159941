import React, { useEffect, useState } from "react";
import {
  FormContainer,
  LoginFormContainer,
  StyledAddUser,
  StyledLoginButton,
  StyledLoginInput,
} from "./../../../assets/styles/Login";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { BASE_URL } from "../../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Dialog, DialogTitle } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import TooltipComponent from "../TooltipComponent";

function AddUser({ addUser, setAddUser, getAllUser }) {
  const [params, setParams] = useState({
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    otp: "",
  });
  const [seePassword, setseePassword] = useState(false);
  const [seeConfirmPassword, setseeConfirmPassword] = useState(false);
  const token = sessionStorage.getItem("token");
  const onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setParams({
      ...params,
      [name]: value,
    });
  };

  let entity1, entity2, entity3, hold;
  function generatePassword() {
    var length = 12;
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper
    var numeric = "0123456789";
    var punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    var password = "";
    var character = "";
    while (password.length < length) {
      entity1 = Math.ceil(string.length * Math.random() * Math.random());
      entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
      hold = string.charAt(entity1);
      hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split("")
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join("");
    setParams({
      ...params,
      password: password.substr(0, length),
      confirm_password: password.substr(0, length),
    });
  }

  const dataValidation = () => {
    let passReg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+~`|}{[:;?><,./-=]).{8,}$/g;
    if (params?.password !== params?.confirm_password) {
      toast.error("Password and Confirm Password must be equal");
      return false;
    }
    if (!passReg.test(params?.password)) {
      toast.error(
        "Must have one Uppercase, Lowercase, digit, special character, Min. 8 character"
      );
      return false;
    }

    const nameRegex = /^[A-Za-z\s]+$/;

    if (!nameRegex.test(params.first_name)) {
      toast.error("Name should not contain Numbers or special characters");
      return false;
    }

    if (!nameRegex.test(params.last_name)) {
      toast.error("Name should not contain Numbers or special characters");
      return false;
    }

    return true;
  };

  const HandleSumbit = (e) => {
    e.preventDefault();
    if (dataValidation()) {
      try {
        axios
          .post(
            BASE_URL + "/signup-user",
            {
              email: params.email,
              password: params.password,
              first_name: params.first_name,
              last_name: params.last_name,
              confirm_password: params.confirm_password,
            },
            {
              headers: {
                "X-Auth-Token": token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response?.data?.success === true) {
              toast.success(response?.data?.message);
            }
            setAddUser(false);
            getAllUser();
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            toast.error(message);
          });
      } catch (error) {
        let message = error.response.data.message;
        toast.error(message);
      }
    }
  };
  const handleClose = () => {
    setAddUser(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "1rem",
          minWidth: "400px",
          width: "fit-content",
          minHeight: "279px",
          height: "fit-content",
        },
      }}
      sx={{ zIndex: 1000 }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={addUser}
    >
      <div
        className="cancle-btn"
        style={{
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "500px",
          background: "#AC2734 0% 0% no-repeat padding-box",
          position: "absolute",
          top: "-10px",
          right: "-10px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <RxCross2
          style={{
            fontSize: "1.2rem",
            color: "white",
          }}
        />
      </div>
      <DialogTitle style={{ display: "inline-flex", alignItems: "center" }}>
        Add User{" "}
        <TooltipComponent message="Either you create your own password or simply generate using 'Generate Password' button" />{" "}
      </DialogTitle>
      <FormContainer onSubmit={HandleSumbit}>
        <StyledAddUser
          type="text"
          name="first_name"
          onChange={onChangehandler}
          placeholder="First Name"
          value={params?.first_name}
          required
        />

        <StyledAddUser
          type="text"
          name="last_name"
          onChange={onChangehandler}
          placeholder="Last Name"
          value={params?.last_name}
          required
        />

        <StyledAddUser
          type="email"
          name="email"
          onChange={onChangehandler}
          placeholder="Email"
          value={params?.email}
          required
        />

        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          <StyledAddUser
            type={seePassword ? "text" : "password"}
            name="password"
            style={{
              paddingRight: "2.5rem",
            }}
            onChange={onChangehandler}
            placeholder="Password"
            value={params?.password}
            required
          />
          <div
            style={{
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
            onClick={() => {
              setseePassword(!seePassword);
            }}
          >
            {!seePassword ? <VscEye /> : <VscEyeClosed />}
          </div>
        </div>

        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          <StyledAddUser
            type={seeConfirmPassword ? "text" : "password"}
            name="confirm_password"
            style={{
              paddingRight: "2.5rem",
            }}
            onChange={onChangehandler}
            placeholder="Confirm Password"
            value={params?.confirm_password}
            required
          />

          <div
            style={{
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
            onClick={() => {
              setseeConfirmPassword(!seeConfirmPassword);
            }}
          >
            {!seeConfirmPassword ? <VscEye /> : <VscEyeClosed />}
          </div>
        </div>
        <Button onClick={generatePassword}>Generate password</Button>
        <StyledLoginButton type={"submit"}>Add User</StyledLoginButton>
      </FormContainer>
    </Dialog>
  );
}

export default AddUser;
