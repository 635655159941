import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import InputDragAndDrop from "./helperComponents/InputDragAndDrop";
import DashboardSummary from "./helperComponents/DashboardSummary";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../utils/index";
import UploadFileProgressBar from "./helperComponents/UploadFileProgressBar";
import { AdminDashboardJob } from "./helperComponents/Admin/AdminDashboardJob";
import { AdminDashboardSummary } from "./helperComponents/Admin/AdminDashboardSummary";
import { toast } from "react-toastify";
import { DeleteAllFiles } from "../utils/Services/api.pdfmasking";

function Dashboard() {
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  (async () => {
    if (sessionStorage.getItem("uuid")) {
      sessionStorage.removeItem("uuid");
      await DeleteAllFiles();
    }
  })();
  const role =
    temp && decryptStorage(sessionStorage.getItem("userDetails"))?.role;
  const { sidebarView, setSidebarView } = useContext(UserDataContext);
  const [loading, setloading] = useState(false);
  const { setUserInputData, setUserOutputData, setUserData } =
    useContext(UserDataContext);

  const [inputfilterjob, setinputfilterjob] = useState("");

  const [file, setFile] = useState({
    files: [],
    started: false,
  });

  useEffect(() => {
    if (role === "Admin") {
      handleAdminDashboardData();
    } else {
      handleUserTasks();
    }
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (role === "Admin") {
        handleAdminDashboardData(inputfilterjob);
      } else {
        handleUserTasks();
      }
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (role === "Admin") {
        handleAdminDashboardData(inputfilterjob);
      }
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputfilterjob]);

  const handleUserTasks = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-user-files-details", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          // toast.success(response?.data?.data?.message);
          setUserInputData(response?.data?.data?.root.Input);
          setUserOutputData(response?.data?.data?.root.Output);
          setUserData({
            completed: response?.data?.data?.root.Completed_Files,
            progress: response?.data.data.root.In_Progress_Files,
            reconsilated: response?.data.data.root.reconsilated,
            ruleset_list: response?.data.data.root.ruleset_list,
          });
        } else {
          return;
        }
      }
    } catch (error) {
      // toast.error(error.response.data.message);
      console.log("Something went wrong");
    }
  };

  const handleAdminDashboardData = async (filter) => {
    try {
      let response = await axios.get(BASE_URL + "/user-jobs", {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          name: filter,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          // toast.success(response?.data?.data?.message);
          setUserData(response?.data?.data);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log("something went wrong");
      // toast.error(error.response.data.message);
    }
  };

  if (!temp || temp === undefined) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
        <Header />
        <div className="right-container-content">
          {role === "User" ? (
            <>
              <DashboardSummary />
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "400px",
                  maxHeight: "400px",
                }}
              >
                <InputDragAndDrop
                  file={file}
                  setFile={setFile}
                  loading={loading}
                  setloading={setloading}
                />
                <UploadFileProgressBar
                  file={file}
                  setFile={setFile}
                  loading={loading}
                  setloading={setloading}
                  handleUserTasks={handleUserTasks}
                />
              </span>
            </>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", gap: "2%" }}>
              <AdminDashboardSummary
                handleAdminDashboardData={handleAdminDashboardData}
              />
              <AdminDashboardJob setinputfilterjob={setinputfilterjob} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
