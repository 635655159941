import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import TableGrid from "./helperComponents/TableGrid";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../utils";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { DeleteAllFiles } from "../utils/Services/api.pdfmasking";

function Reconciliated() {
  const {
    sidebarView,
    reconciliatedData,
    setReconciliatedData,
    selectedData,
    setSelectedData,
  } = useContext(UserDataContext);

  const tableHeading = [
    "File Name",
    "Document Type",
    "Status",
    "Extraction Date",
    "Action",
  ];

  (async () => {
    if (sessionStorage.getItem("uuid")) {
      sessionStorage.removeItem("uuid");
      await DeleteAllFiles();
    }
  })();
  const temp = sessionStorage?.getItem("token");
  const isAdmin =
    temp && decryptStorage(sessionStorage?.getItem("userDetails"))?.is_admin;
  const navigate = useNavigate();
  const handleReconciliatedData = async (
    filter = {
      filename: null,
      status: {},
      start_date: "",
      end_date: "",
    },
    setisloading
  ) => {
    try {
      if (typeof setisloading === "function") {
        setisloading(true);
      }

      let url = isAdmin ? "/admin/reconciliated" : "/reconciliated";
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          file_name: filter.filename,
          complete: filter.status.complete ? filter.status.complete : false,
          pending: filter.status.pending ? filter.status.pending : false,
          failed: filter.status.failed ? filter.status.failed : false,
          start_date:
            filter.start_date !== ""
              ? moment(filter.start_date).format("MM/DD/YYYY")
              : "",
          end_date:
            filter.end_date !== ""
              ? moment(filter.end_date).format("MM/DD/YYYY")
              : "",
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (typeof setisloading === "function") {
          setisloading(false);
        }
        if (response.status === 200) {
          setReconciliatedData(response.data.data);
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      if (typeof setisloading === "function") {
        setisloading(false);
      }
      console.log("Something went wrong..!");
    }
  };

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <TableGrid
            tableHeading={tableHeading}
            userTaskInputData={reconciliatedData}
            pageName="Reconciled"
            type="pdf"
            pageButton="reconciliated"
            allSelectEnable={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            handleReconciliatedData={handleReconciliatedData}
            action={{
              canEdit: false,
              canDelete: true,
              canDownload: true,
              canView: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Reconciliated;
