import React, { useContext, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import TableGrid from "./helperComponents/TableGrid";
import RuleSetListEmpty from "./EmptyScreens/RuleSetListEmpty";
import { Navigate } from "react-router-dom";
import { DeleteAllFiles } from "../utils/Services/api.pdfmasking";

function RulesetList() {
  const { ruleSetData, sidebarView, handleRulesetList } =
    useContext(UserDataContext);
    (async () => {
      if (sessionStorage.getItem("uuid")) {
        sessionStorage.removeItem("uuid");
        await DeleteAllFiles();
      }
    })();
  useEffect(() => {
    handleRulesetList();
  }, []);
  sessionStorage.removeItem("ruleArray");
  sessionStorage.removeItem("chooseTemplateIndex");
  sessionStorage.removeItem("selectedTemplate");
  sessionStorage.removeItem("rulesetName");
  const tableHeading = [
    "Ruleset Name",
    "Created by",
    "Creation Date",
    "Action",
  ];

  const temp = sessionStorage.getItem("token");

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <TableGrid
            tableHeading={tableHeading}
            userTaskInputData={ruleSetData}
            pageName="RuleSet List"
            type="pdf"
            pageButton="ruleset"
            allSelectEnable={false}
            action={{
              canEdit: true,
              canDelete: true,
              canDownload: false,
              canView: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RulesetList;
