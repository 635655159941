import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";
import { toast } from "react-toastify";

export default function MaskingByText({ ...data }) {
  let {
    setTextMask,
    setFile,
    textMask,
    pageNumber,
    setOpenApply,
    setCicularLoaderStatus,
  } = data;
  const [text, setText] = React.useState();
  const handleClose = () => {
    setCicularLoaderStatus(false);
    setTextMask({ state: false, data: {} });
  };
  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setText(value);
  };
  const handleClick = async (e) => {
    e.preventDefault();
    if (!String(text).trim()) {
      return toast.error("Input Cannot be empty!!");
    }
    setCicularLoaderStatus(true);
    handleClose();
    let payload = {
      page_number: pageNumber,
      mask_file_id: sessionStorage.getItem("mask_file_id"),
      masking_type: "text",
      text: String(text).trim(),
    };
    setOpenApply({
      state: true,
      data: payload,
      usestates: {
        setFile: setFile,
        page_number: pageNumber,
      },
    });
  };
  return (
    <Dialog
      open={textMask.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "10px",
          minWidth: "370px",
          width: "400px",
          minHeight: "150px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            height: "50px",
          }}
        >
          Enter the text you wish to mask
        </DialogContentText>
      </DialogContent>
      <form
        style={{
          textAlign: "center",
          height: "70px",
        }}
        onSubmit={handleClick}
      >
        <input
          type="text"
          onChange={handleChange}
          required
          style={{
            borderRadius: "7px",
            height: "33px",
            width: "212px",
            outline: "none",
            border: "1px solid black",
            padding: "5px",
            marginRight:"10px"
          }}
          placeholder="Enter text"
        />
        <StyledButton
          type="submit"
          style={{
            background: "#50dfb2",
            color: "white",
            borderRadius: "10px 10px ",
          }}
        >
          Submit
        </StyledButton>
      </form>
    </Dialog>
  );
}
