import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { UserDataContext } from "../../AppRouter";
import { Button } from "@mui/material";
import ExcelTemplate from "../helperComponents/ExcelTemplate";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router";
import CreateRulesetForm from "./CreateRulesetForm";
import UploadCSV from "../helperComponents/UploadCSV";
import Buildtable from "../helperComponents/Buildtable";
import { Navigate } from "react-router-dom";

function CreateCustomCsv() {
  const temp = sessionStorage.getItem("token");
  
  const { sidebarView, setSidebarView } = useContext(UserDataContext);
  const navigate = useNavigate();
  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
        <Header />
        <div className="right-container-content" style={{ overflow: "hidden" }}>
          <div className="task-container">
            <div className="task-header-container">
              <div className="task-heading">Create Custom Csv</div>
            </div>
            <div
              className="template-content-form"
              style={{
                background: "transparent",
                border: "none",
                padding: "20px",
                height: "100%",
                gap: "20px",
              }}
            >
              <UploadCSV />
              <Buildtable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCustomCsv;
