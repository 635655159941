import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { UserDataContext } from "../../AppRouter";
import { Button, Radio } from "@mui/material";
import ExcelTemplate from "../helperComponents/ExcelTemplate";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import ViewDataDialog from "../helperComponents/ViewDataDialog";
import { BsTrash } from "react-icons/bs";
import DeleteConfirmation from "../helperComponents/DeleteConfirmation";

function ChooseTemplate() {
  const {
    sidebarView,
    getRulesetTemplate,
    rulesetTemplate,
    setSelectedTemplate,
  } = useContext(UserDataContext);
  const [viewDialog, setViewDialog] = useState({ open: false, data: [] });
  const [checked, setChecked] = useState();
  const temp = sessionStorage.getItem("token");
  const [refresh, setrefresh] = useState(false);
  useEffect(() => {
    getRulesetTemplate();
    let index = sessionStorage.getItem("chooseTemplateIndex");
    setChecked(index);
  }, []);

  useEffect(() => {
    if (refresh) {
      getRulesetTemplate();
      let index = sessionStorage.getItem("chooseTemplateIndex");
      setChecked(index);
      setrefresh(false);
    }
  }, [refresh]);

  const navigate = useNavigate();
  const [openDeleteModal, setopenDeleteModal] = useState({
    open: false,
    data: undefined,
  });

  const handleRadioButton = (e, value) => {
    let index = e.target.name;
    sessionStorage.setItem("chooseTemplateIndex", index);
    sessionStorage.setItem("selectedTemplate", JSON.stringify(value));
    setChecked(index);
    setSelectedTemplate(value);
  };
  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }
  const handleView = (e, data) => {
    e.preventDefault();
    setViewDialog({
      open: true,
      data: data,
    });
  };

  const HandleDeleteTemplate = (e, value) => {
    e.preventDefault();
    setopenDeleteModal({
      open: true,
      data: value.id,
    });
  };

  return (
    <>
      <div className="main-container">
        <div className={sidebarView.cssLeftContainer}>
          <Sidebar />
        </div>
        <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
          <Header />
          <div
            className="right-container-content"
            style={{ overflow: "hidden" }}
          >
            <div className="task-container">
              <div className="task-header-container">
                <div className="task-heading">Create New Ruleset</div>
                <div className="task-heading-button-container">
                  <Button
                    variant="filled"
                    sx={{
                      color: "white",
                      background: "#50DFB2",
                      border: "1px solid #50DFB2",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "white",
                        background: "#50DFB2",
                        border: "1px solid #50DFB2",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      sessionStorage.removeItem("uploadCSVpayload");
                      navigate("/create-custom-csv");
                    }}
                  >
                    Create Custom CSV{" "}
                  </Button>
                </div>
              </div>
              <div className="template-content">
                <heading>Choose CSV Template</heading>
                <templateContainer>
                  {rulesetTemplate &&
                    rulesetTemplate.map((value, index) => {
                      let data = value.data.map((templateData) => {
                        return templateData.map((innerData) => {
                          return { value: innerData, readOnly: true };
                        });
                      });

                      let newData = [[...value.modified_column], ...value.data];
                      return (
                        <div className="csv-template-container">
                          <templateHeading style={{ height: "15%" }}>
                            {value.name}
                          </templateHeading>
                          <div
                            className="excel-template-conatiner"
                            style={{ height: "75%" }}
                          >
                            <ExcelTemplate
                              data={data}
                              column={value.modified_column}
                            />
                          </div>
                          <div
                            className="template-buttons"
                            style={{ height: "10%" }}
                          >
                            <button
                              onClick={(e) => {
                                HandleDeleteTemplate(e, value);
                              }}
                            >
                              Delete
                              <BsTrash
                                style={{
                                  pointerEvents: "none",
                                  fontSize: "15px",
                                }}
                              />
                            </button>
                            <button
                              onClick={(e) => {
                                handleView(e, newData);
                              }}
                            >
                              view{" "}
                              <AiOutlineEye style={{ pointerEvents: "none" }} />{" "}
                            </button>
                            <button
                              htmlFor={"chooseButton" + index}
                              style={{ cursor: "pointer" }}
                            >
                              <label
                                htmlFor={"chooseButton" + index}
                                style={{ cursor: "pointer" }}
                              >
                                Choose
                              </label>
                              <Radio
                                id={"chooseButton" + index}
                                key={index}
                                style={{ padding: "0", color: "white" }}
                                name={index}
                                size="20px"
                                onChange={(e) => handleRadioButton(e, value)}
                                checked={checked == index}
                              />{" "}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </templateContainer>
              </div>
              <div className="choose-ruleset-buttons">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  back
                </button>
                <button
                  style={{
                    background: checked ? "#50DFB2" : "transparent",
                    border: checked ? "none" : "1px solid grey",
                    color: checked ? "white" : "grey",
                  }}
                  disabled={!Boolean(checked)}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/createNewRuleset/createRuleset", {
                      state: { type: "new" },
                    });
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewDialog && (
        <ViewDataDialog viewDialog={viewDialog} setViewDialog={setViewDialog} />
      )}

      {openDeleteModal.open && (
        <DeleteConfirmation
          isopen={openDeleteModal}
          setIsopen={setopenDeleteModal}
          detailsProps={{
            url: "/",
            title: "This action will delete the Template",
          }}
          page={"ruleset_choose_template"}
          setrefresh={setrefresh}
        />
      )}
    </>
  );
}

export default ChooseTemplate;
