import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import { UserDataContext } from "../../AppRouter";
import Header from "../Header";
import { Navigate, useNavigate } from "react-router-dom";
import FileInputAutoSubmission from "./FileInputAutoSubmission";
import { v4 } from "uuid";
import Canvas from "./Canvas";
import { decryptStorage } from "../../utils";
import { Grid } from "@mui/material";

function MaskingPage() {
  const { sidebarView, cicularLoaderStatus, setCicularLoaderStatus } =
    useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [viewer, setViewer] = useState(false);
  const role = decryptStorage(sessionStorage.getItem("userDetails"))?.role;
  const [loading, setloading] = useState(false);
  const drawingRef = useRef();
  const uuid = v4();

  const [file, setFile] = useState({
    files: [],
    started: false,
    mask_file_id: "",
  });
  if (!sessionStorage.getItem("token")) {
    return <Navigate to={"/"}></Navigate>;
  }
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
        <Header />
        <div
          className="right-container-content"
          style={{ padding: "10px 20px" }}
        >
          <Grid className="pdf-masking-main-div">
            {!viewer ? (
              <>
                <Grid className="task-heading" style={{ marginBottom: "3%" }}>
                  {" "}
                  PDF Masking
                </Grid>
                <FileInputAutoSubmission
                  setFile={setFile}
                  loading={loading}
                  setloading={setloading}
                  uuid={uuid}
                  setViewer={setViewer}
                />
              </>
            ) : (
              <Canvas
                loading={loading}
                setloading={setloading}
                file={file.files}
                mask_file_id={file.mask_file_id}
                uuid={uuid}
                setFile={setFile}
                setViewer={setViewer}
                cicularLoaderStatus={cicularLoaderStatus}
                setCicularLoaderStatus={setCicularLoaderStatus}
              />
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default MaskingPage;
