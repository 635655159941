import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";
import { ExtractPDF } from "../../utils/Services/api.pdfmasking";
import { toast } from "react-toastify";

export default function ExtractPdfConfirm({ ...data }) {
  let { setExtractPdf, extractPdf, setCicularLoaderStatus } = data;
  const navigate = useNavigate();
  const handleClose = () => {
    setCicularLoaderStatus(false);
    setExtractPdf({ state: false, data: {} });
  };
  const handleClick = async () => {
    let payload = sessionStorage.getItem("mask_file_id");
    setCicularLoaderStatus(true);
    await ExtractPDF(payload)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          toast.success(res.data.message);
          navigate("/taskinprogress");
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleClose();
      });
  };
  return (
    <Dialog
      open={extractPdf.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "10px",
          minWidth: "370px",
          width: "500px",
          height: "300",
          minHeight: "209px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            height: "50px",
          }}
        >
          Are you absolutely sure you want to proceed?
          <br />
          <span
            style={{ color: "grey", fontSize: "small", fontWeight: "normal" }}
          >
            Initiating Extraction will permanently delete your progress.
          </span>
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "center",
          height: "70px",
          padding: "10px",
        }}
      >
        <StyledButton
          autoFocus
          style={{
            background: "#50dfb2",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          Confirm
        </StyledButton>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
          style={{
            marginLeft: "5rem",
            border: "2px solid red",
            background: "transparent",
            color: "red",
          }}
        >
          Cancel
        </StyledButton>
      </div>
    </Dialog>
  );
}
