import React, { useContext } from "react";
import { AdminJobCard } from "./AdminJobCard";
import { UserDataContext } from "../../../AppRouter";

export const AdminDashboardJob = ({ setinputfilterjob }) => {
  const {
    userData: { job_details },
  } = useContext(UserDataContext);

  return (
    <div className="admin-dasbhboard-job" style={{ overflow: "hidden" }}>
      <div
        className="admin-dashboard-job-wrapper"
        style={{ height: "inherit" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <div className="admin-dashboard-job-head">No. of job by user</div>
            <span
              style={{
                width: "40px",
                border: "2px solid #50DFB2",
                display: "block",
              }}
            ></span>
          </div>
          <input
            type="text"
            onChange={(e) => {
              setinputfilterjob(e.target.value);
            }}
            style={{
              transform: "translateY(0px)",
              padding: "0.6rem 1rem",
              fontSize: "0.9rem",
              border: "1px solid grey",
              borderRadius: "10px",
              outline: "none",
              width: "50%",
              textOverflow: "ellipsis",
            }}
            name="name"
            placeholder="Start Searching..."
          />
        </div>

        <div className="admin-dashboard-job-body">
          {job_details &&
            job_details.map((user) => {
              return (
                <React.Fragment key={user.name}>
                  <AdminJobCard data={user} />{" "}
                  <span
                    style={{
                      border: "1px solid #F3F6F8",
                      display: "block",
                      marginBottom: "10px",
                      width: "95%",
                    }}
                  ></span>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};
