import React, { useEffect, useState } from "react";
import {
  ForgotPasswordContainer,
  FormContainer,
  LoginContainer,
  LoginFormContainer,
  LoginLeft,
  LoginRight,
  LogoBox,
  LogoContainer,
  NameContainer,
  StyledLoginButton,
  StyledLoginInput,
} from "./../assets/styles/Login";
import { Link, useNavigate } from "react-router-dom/dist";
import Logo from "./../assets/images/logo2x.png";
import axios from "axios";
import { BASE_URL } from "../utils";
import { toast } from "react-toastify";
import { Flex } from "../assets/styles/Chatbot";

function ForgetPassword() {
  const [params, setParams] = useState({
    email: "",
    password: "",
  });
  const temp = sessionStorage.getItem("token");

  const onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setParams({
      ...params,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (temp) {
      navigate("/dashboard");
    }
  }, []);

  const HandleSumbit = (e) => {
    e.preventDefault();

    try {
      axios
        .post(
          BASE_URL + "/forget-password",
          {
            email: params.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.success === true) {
            toast.success(response?.data?.message);
            navigate("/");
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.message;
          toast.error(message);
        });
    } catch (error) {
      let message = error.response.data.message;
      toast.error(message);
    }
  };
  return (
    <LoginContainer>
      <LoginRight></LoginRight>
      <LoginLeft>
        <LogoContainer>
          {/* <img
            style={{
              width: "70px",
              height: "70px",
              objectFit: "contain",
            }}
            className="foliomax"
            src={Logo}
            alt="Foliomax"
          />{" "} */}

          <Flex direction="column" align="center">
            <div className="sidebar-logo">
              <img
                src={Logo}
                style={{
                  width: "80px",
                }}
              />
            </div>

            <Flex
              style={{
                fontSize: "1.1rem",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                letterSpacing: "4px",
                margin: "0px",
                position: "relative",
              }}
            >
              FOLIOMAX
              <div className="logo__circle-R logo__circle-sidebar"></div>
            </Flex>
          </Flex>
        </LogoContainer>
        <LoginFormContainer>
          <div className="login_circle">
            <div className="login_circle-2"></div>
          </div>

          <div className="login-title">Forgot Password</div>
          <div className="login-subtitle">Welcome to FolioMax</div>
          <FormContainer onSubmit={HandleSumbit}>
            <StyledLoginInput
              type="email"
              name="email"
              onChange={onChangehandler}
              placeholder="Email"
              value={params?.email}
              required
            />

            <ForgotPasswordContainer>
              <Link to={"/"}>Go Back</Link>
            </ForgotPasswordContainer>

            <StyledLoginButton type="submit">Send Mail</StyledLoginButton>
          </FormContainer>
        </LoginFormContainer>
      </LoginLeft>
    </LoginContainer>
  );
}

export default ForgetPassword;
