import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { PDFViewer } from "./PDFViewer";
import PageViewer from "./PageViewer";
import ApplyChangeDialog from "./ApplyChangeDialog";

function Canvas({
  mask_file_id,
  file,
  setFile,
  loading,
  setLoading,
  setViewer,
  cicularLoaderStatus,
  setCicularLoaderStatus,
}) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);
  const [coordinates, setCoordinates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const drawingRef = useRef();
  const [highlight, setHighlight] = useState(1);
  const [openApply, setOpenApply] = useState({
    state: false,
    data: {},
  });

  const sendBboxCoordinates = async (data) => {
    const x0 = data.x0 / 0.8;
    const x1 = data.x1 / 0.8;
    const y0 = data.y0 / 0.8;
    const y1 = data.y1 / 0.8;
    if (x0 === x1 || y0 === y1) return;
    setCoordinates({
      x0: x0,
      x1: x1,
      y0: y0,
      y1: y1,
    });
    let payload = {
      mask_file_id: mask_file_id,
      coordinates: [
        {
          x0: x0,
          x1: x1,
          y0: y0,
          y1: y1,
        },
      ],
    };
    setOpenApply({
      state: true,
      data: payload,
      usestates: {
        setFile: setFile,
        file: file,
        page_number: pageNumber,
      },
    });
  };
  return (
    <>
      <Grid
        width="100%"
        height={"78vh"}
        position={"relative"}
        border={"1px solid #8080805c"}
        borderRadius={"10px"}
        overflow={"hidden"}
        boxShadow={"0px 0px 4px rgb(0 0 0 / 41%)"}
      >
        <Grid flex={1} background="#f9f9f9">
          <Grid style={{ display: "flex" }} className="pdf-viewer-container">
            <Grid className="pdf-container">
              <PDFViewer
                page={"drag"}
                url={`data:application/pdf;base64,${file?.split("'")[1]}`}
                isDrawing={isDrawing}
                setIsDrawing={setIsDrawing}
                startPosition={startPosition}
                setStartPosition={setStartPosition}
                endPosition={endPosition}
                setEndPosition={setEndPosition}
                coordinates={coordinates}
                setCoordinates={setCoordinates}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                numPagesg={numPages}
                setNumPages={setNumPages}
                sendBboxCoordinates={sendBboxCoordinates}
                ref={drawingRef}
                file={file}
                setFile={setFile}
                setLoading={setLoading}
                loading={loading}
                setViewer={setViewer}
                openApply={openApply}
                setOpenApply={setOpenApply}
                setCicularLoaderStatus={setCicularLoaderStatus}
                cicularLoaderStatus={cicularLoaderStatus}
              />
            </Grid>
            <Grid overflow={"auto"} className="page-selection-conatiner">
              {[...Array(numPages).keys()].map((value) => {
                return (
                  <Grid className="pdf-page-viewer">
                    <PageViewer
                      value={value}
                      url={`data:application/pdf;base64,${file?.split("'")[1]}`}
                      pageNumber={value + 1}
                      setPageNumber={setPageNumber}
                      setNumPages={setNumPages}
                      ref={drawingRef}
                      highlight={highlight}
                      setHighlight={setHighlight}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        <ApplyChangeDialog
          openApply={openApply}
          setOpenApply={setOpenApply}
          setCicularLoaderStatus={setCicularLoaderStatus}
        />
      }
    </>
  );
}
export default Canvas;
