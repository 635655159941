import { Tooltip } from "@mui/material";
import { Flex } from "../../assets/styles/Chatbot";
import { MdReadMore } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import NoDatasvg from "../../assets/images/Nodata.svg";
import LoadingStarter from "./LoadingStarter";

function ConversationStarters({
  conversations,
  HandleSearch,
  setShowStarter,
  grid = true,
  loading,
}) {
  const HandleMouseMove = (e) => {
    const { target: target } = e;
    const rect = target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;

    target.style.setProperty("--mouse-x", `${x}px`);
    target.style.setProperty("--mouse-y", `${y}px`);
  };

  const handleCloseStarter = () => {
    if (typeof setShowStarter === "function") {
      setShowStarter(false);
    }
  };

  return (
    <div
      className={grid && "conversation_main"}
      style={{
        padding: !grid ? "5px" : "auto",
        overflowY: !grid ? "auto" : "auto",
        width: "100%",
        height: "100%",
      }}
    >
      {!grid && (
        <Flex
          align={"center"}
          padding="5px"
          width="100%"
          style={{
            color: "white",
            fontSize: "0.9rem",
            margin: "0",
            marginBottom: "10px",
          }}
        >
          <MdReadMore
            style={{
              color: "white",
              marginRight: "5px",
              fontSize: "1.2rem",
            }}
          />
          Starters
          <Flex
            style={{
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={handleCloseStarter}
          >
            <MdCancel />
          </Flex>
        </Flex>
      )}

      {conversations?.length === 0 && !loading && (
        <Flex
          direction="column"
          width="100%"
          height="100%"
          align="center"
          justify="center"
          style={{
            marginTop: "-3rem",
          }}
        >
          <img
            src={NoDatasvg}
            alt="no data found"
            style={{
              width: "100px",
              objectFit: "contain",
            }}
          />

          <p
            style={{
              color: "white",
            }}
          >
            No Starter found
          </p>
        </Flex>
      )}

      {loading && (
        <Flex
          direction="column"
          width="100%"
          height="100%"
          align="center"
          justify="center"
          style={{
            marginTop: "-3rem",
          }}
        >
          <LoadingStarter />
        </Flex>
      )}

      {conversations.map((convo) => {
        return (
          <Tooltip title={convo} placement="bottom">
            <Flex
              onMouseMove={HandleMouseMove}
              onClick={() => {
                HandleSearch(convo);
              }}
              style={{
                marginBottom: !grid ? "10px" : "auto",
              }}
              align="center"
              justify="center"
              padding="10px"
              className="conversation_main_box"
            >
              <span>
                {convo.length <= 85 ? convo : `${convo.substring(0, 80)}...`}
              </span>
            </Flex>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ConversationStarters;
