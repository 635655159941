import { CircularProgress } from "@mui/material";
import React from "react";

function CicularLoader() {
  return (
    <div className="loader-container">
      <CircularProgress style={{ color: "#50dfb2" }} />
    </div>
  );
}

export default CicularLoader;
