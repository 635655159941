import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, FormGroup } from "@mui/material";

export default function StatusSelection({ setinputstatus, setSelectedData }) {
  const [state, setState] = React.useState({
    complete: false,
    pending: false,
    failed: false,
  });

  const handleChange = (event) => {
    let name = event.target.name;
    setState((prev) => ({ ...prev, [name]: event.target.checked }));
    setinputstatus((prev) => ({ ...prev, [name]: event.target.checked }));
    setSelectedData([]);
  };
  const statusLabel = ["complete", "pending", "failed"];

  return (
    <>
      <FormControl
        className="status-selection-form"
        component="form"
        variant="standard"
        inputprops={{ "aria-label": "controlled" }}
      >
        <FormGroup>
          {statusLabel.map((value) => {
            return (
              <FormControlLabel
                key={value}
                style={{
                  textTransform: "capitalize",
                }}
                control={
                  <Checkbox
                    checked={state[value]}
                    onChange={handleChange}
                    name={value}
                    style={{
                      color: "#50DFB2",
                      padding: "5px",
                    }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  />
                }
                label={value}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </>
  );
}
