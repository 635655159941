import { Button, ListItem } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  padding: 0.3rem 1rem;
  background: #50dfb2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 0.9rem;

  &:hover {
    color: #ffffff;
    background: #50dfb2 0% 0% no-repeat padding-box;
  }
`;

export const StyledHeading = styled.h2`
  text-align: left;
  font: normal normal medium 22px/25px Poppins;
  letter-spacing: 0px;
  color: #0e0e4f;
  opacity: 1;
  font-size: 1.2rem;
`;

export const StyledInput = styled.input`
  border: 1px solid #50dfb2;
  border-radius: 5px;
  padding: 0.6rem 1.2rem;
  width: 100%;
  opacity: 1;
  transition: all 0.15s linear;
  text-align: left;
  font: normal normal normal 15px/23px Poppins;
  letter-spacing: 0px;
  color: black;
  text-transform: capitalize;
  opacity: 1;

  &::placeholder {
    color:#bdbdbd
  }

  &:focus {
    border: 2px solid #50dfb2;
    outline: none;
  }
`;

export const StyledInputLabel = styled.label`
  text-align: left;
  font: normal normal normal 14px/25px Poppins;
  letter-spacing: 0px;
  color: #a9aeac;
  opacity: 1;
  font-size: 0.9rem;
`;

export const StyledListItem = styled(ListItem)`
  padding-left: 0rem;
  padding-right: 0rem;
`;
