import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { UserDataContext } from "../AppRouter";
import Header from "./Header";
import TableGrid from "./helperComponents/TableGrid";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/index";
import { Navigate, useNavigate } from "react-router-dom";
import { DeleteAllFiles } from "../utils/Services/api.pdfmasking";

const TaskInProgess = () => {
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  (async () => {
    if (sessionStorage.getItem("uuid")) {
      sessionStorage.removeItem("uuid");
      await DeleteAllFiles();
    }
  })();
  useEffect(() => {
    handleUserTasks();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleUserTasks();
    }, [15000]);
    return () => clearInterval(timeout);
  });

  const {
    userInputData,
    setUserInputData,
    setUserOutputData,
    sidebarView,
    setSidebarView,
    selectedData,
    setSelectedData,
  } = useContext(UserDataContext);

  const [userTaskInputData, setUserTaskInputData] = useState(userInputData);

  useEffect(() => {
    setUserTaskInputData(userInputData);
  }, [userInputData]);
  const handleUserTasks = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-user-files-details", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          setUserInputData(response?.data?.data?.root.Input);
          setUserOutputData(response?.data?.data?.root.Output);
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went worng..!");
    }
  };

  if (!sessionStorage.getItem("token")) {
    return <Navigate to={"/"}></Navigate>;
  }

  const tableHeading = ["File Name", "Document Type", "Update date", "Action"];
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} `}>
        <Header />
        <div className="right-container-content">
          <TableGrid
            tableHeading={tableHeading}
            userTaskInputData={userTaskInputData}
            pageName="Tasks In Progress"
            type="pdf"
            pageButton="taskProgress"
            allSelectEnable={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            action={{
              canEdit: false,
              canDelete: true,
              canDownload: true,
              canView: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskInProgess;
