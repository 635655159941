import React from "react";
import Avatar from "../../../assets/images/profile.png";

export const AdminJobCard = ({ data }) => {
  const { username, no_of_jobs, email } = data;

  return (
    <div className="job-card">
      <div>
        <img className="job-card-avatar" src={Avatar} alt="" />
      </div>
      <div className="job-card-data">
        <p>{username}</p>
        <p>{email}</p>
      </div>
      <div className="job-card-job">{no_of_jobs}</div>
    </div>
  );
};
