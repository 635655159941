import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { UserDataContext } from "../../AppRouter";
import Spreadsheet from "react-spreadsheet";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FcInfo } from "react-icons/fc";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { Checkbox, Switch } from "@mui/material";
import { Navigate } from "react-router-dom";
import TooltipComponent from "../helperComponents/TooltipComponent";
import { BsInfoCircle } from "react-icons/bs";

export default function CustomCSVTable() {
  const { rowCol } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const row =
    rowCol.row !== 0
      ? rowCol.row
      : JSON.parse(sessionStorage.getItem("rowCol")).row;
  const col =
    rowCol.column !== 0
      ? rowCol.column
      : JSON.parse(sessionStorage.getItem("rowCol")).column;
  const { sidebarView } = useContext(UserDataContext);
  const { uploadedCSV, setUploadedCsv } = useContext(UserDataContext);
  const [column, setColumn] = useState();
  const [isNum, setIsNum] = useState(false);
  const navigate = useNavigate();
  const [columnHeading, setColumnHeading] = useState([]);
  const [templateName, setTemplateName] = useState("");

  let numArray;
  let data = [];

  for (let i = 0; i < row; i++) {
    data[i] = [];
    for (let j = 0; j < col; j++) {
      data[i][j] = { value: "" };
    }
  }
  const handleSheetUpdate = (data) => {
    sessionStorage.setItem("uploadCSVpayload", JSON.stringify(data));
  };

  const updateColumnHeading = () => {
    setColumnHeading([]);
    for (let i = 0; i < col; i++) {
      let char = String.fromCharCode(65 + i);
      if (numArray?.includes(char.charCodeAt(0) - 65)) char += "(num)";
      setColumnHeading((prev) => [...prev, char]);
    }
  };
  useEffect(() => {
    let data = sessionStorage.getItem("uploadCSVpayload");
    updateColumnHeading();
    if (data) {
      setUploadedCsv(JSON.parse(data));
    } else {
      data = [];
      for (let i = 0; i < row; i++) {
        data[i] = [];
        for (let j = 0; j < col; j++) {
          data[i][j] = { value: "" };
        }
      }
      sessionStorage.setItem("uploadCSVpayload", JSON.stringify(data));
      setUploadedCsv(data);
    }
  }, []);

  const checkIsNum = () => {
    let array = sessionStorage.getItem("numArray")
      ? JSON.parse(sessionStorage.getItem("numArray"))
      : [];
    let contains = array.some((value) => {
      return value == column;
    });
    if (contains) {
      setIsNum(true);
    } else {
      setIsNum(false);
    }
  };
  useEffect(() => {
    checkIsNum();
  }, [column]);

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  const dataConvertor = (item) => {
    let itr = item?.map((value) => {
      return value.map((innerItem) => {
        return innerItem.value;
      });
    });
    return itr;
  };

  const handleActivate = (event) => {
    setColumn(event.column);
    checkIsNum();
  };

  const handleToggle = (e) => {
    e.preventDefault();
    if (column === undefined) {
      toast("Select a column To delete!", {
        icon: <FcInfo style={{ width: "30px", height: "35px" }} />,
      });
      return;
    }
    let isChecked = e.target.checked;
    numArray = sessionStorage.getItem("numArray")
      ? JSON.parse(sessionStorage.getItem("numArray"))
      : [];
    if (isChecked) {
      numArray.push(column);
    } else {
      numArray = numArray.filter((value) => {
        return value != column;
      });
    }
    updateColumnHeading();
    setIsNum(isChecked);
    sessionStorage.setItem("numArray", JSON.stringify(numArray));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let columnsName = [];
    let temp = sessionStorage.getItem("token");
    if (!templateName) {
      toast.error("Please Enter Template Name");
      return;
    }
    for (let alpha = 0; alpha < uploadedCSV[0].length; alpha++) {
      columnsName.push(String.fromCharCode(65 + alpha));
    }
    let numColumns = [];
    let numArray = [];
    if (sessionStorage.getItem("numArray"))
      numArray = JSON.parse(sessionStorage.getItem("numArray"));
    for (let alpha = 0; alpha < numArray.length; alpha++) {
      numColumns.push(String.fromCharCode(65 + numArray[alpha]));
    }

    let payload = {
      name: templateName,
      number_of_columns: uploadedCSV[0].length,
      columns: columnsName,
      numeric_columns: numColumns,
      data: dataConvertor(
        JSON.parse(sessionStorage.getItem("uploadCSVpayload"))
      ),
    };
    try {
      let response = await axios.post(BASE_URL + "/ruleset-template", payload, {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.data.success) {
          toast.success(response?.data?.data?.message);
          navigate("/createNewRuleset/choosetemplate");
          sessionStorage.removeItem("numArray");
          sessionStorage.removeItem("uploadCSVpayload");
          setColumn();
          setIsNum();
          setUploadedCsv();
        }
        if (!response.data.success) {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };
  const toolTipMessage = (
    <ol>
      <li>Select the column.</li>
      <li>Click on "isNum" checkbox to make any column numeric</li>
      <li>Select the any cell to write in spreadsheet</li>
      <br />
      <em>
        *Note:
        <ul>
          <li>
            If any cell remains empty at the time of save, than it will fill
            with any random value.{" "}
          </li>
          <li>Rules only apply on numeric columns.</li>
        </ul>
      </em>
    </ol>
  );
  const handleChange = (e) => {
    e.preventDefault();
    setTemplateName(e.target.value);
  };
  return (
    <>
      <div className="main-container">
        <div className={sidebarView.cssLeftContainer}>
          <Sidebar />
        </div>
        <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
          <Header />
          <div
            className="right-container-content"
            style={{ overflow: "hidden" }}
          >
            <div className="task-container">
              <div className="task-header-container">
                <div
                  className="task-heading"
                  style={{ display: "inline-flex", alignItems: "center" }}
                >
                  Build Table
                  <TooltipComponent
                    message={toolTipMessage}
                    position="bottom"
                  />
                </div>
                <div className="template-name-container">
                  <input
                    type="text"
                    name="template_name"
                    placeholder="Template Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="add-delete-upload-csv">
                  <button
                    htmlFor="is-num-button"
                    style={{
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      checked={isNum}
                      id="is-num-button"
                      style={{
                        color: "#50DFB2",
                        padding: "0",
                        borderRadius: "100% !important",
                      }}
                      size="20px"
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "controlled" }}
                      value={isNum}
                    />
                    <label
                      htmlFor="is-num-button"
                      style={{
                        fontFamily: "poppins",
                        fontWeight: "600",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      Num
                    </label>
                  </button>
                </div>
              </div>
              <div
                className="template-content-form"
                style={{
                  background: "transparent",
                  border: "none",
                  padding: "20px",
                  height: "80%",
                  width: "100%",
                  boxShadow: "0px 15px 16px #a4a7ac29",
                }}
              >
                {uploadedCSV && (
                  <Spreadsheet
                    data={uploadedCSV}
                    onChange={handleSheetUpdate}
                    onActivate={handleActivate}
                    columnLabels={columnHeading}
                  />
                )}
              </div>
              <div className="choose-ruleset-buttons">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                    sessionStorage.removeItem("numArray");
                    sessionStorage.removeItem("uploadCSVpayload");
                    sessionStorage.removeItem("rowCol");
                  }}
                >
                  back
                </button>
                <button onClick={handleSubmit}>Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
