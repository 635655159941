import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import TableGrid from "./helperComponents/TableGrid";
import axios from "axios";
import { BASE_URL } from "../utils";
import ConfirmDialogBox from "./helperComponents/ConfirmDialogBox";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { DeleteAllFiles } from "../utils/Services/api.pdfmasking";

function TaskCompleted() {
  const { userOutputData } = useContext(UserDataContext);
  const navigate = useNavigate();
  (async () => {
    if (sessionStorage.getItem("uuid")) {
      sessionStorage.removeItem("uuid");
      await DeleteAllFiles();
    }
  })();
  const [enableApplyReconciliation, setEnableApplyReconciliation] =
    useState(false);
  const temp = sessionStorage?.getItem("token");
  const {
    userInputData,
    setUserInputData,
    setUserOutputData,
    sidebarView,
    setSidebarView,
    selectedData,
    setSelectedData,
  } = useContext(UserDataContext);

  useEffect(() => {
    if (temp) {
      handleUserTasks();
    }
  }, []);

  const tableHeading = [
    "File Name",
    "Document Type",
    "Extraction Date",
    "Action",
  ];

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }
  const handleUserTasks = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-user-files-details", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          // toast.success(response?.data?.message);
          setUserInputData(response?.data?.data?.root.Input);
          setUserOutputData(response?.data?.data?.root.Output);
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <TableGrid
            tableHeading={tableHeading}
            userTaskInputData={userOutputData}
            pageName="Tasks Completed"
            reconciliation={true}
            type="excel"
            pageButton="taskCompleted"
            allSelectEnable={true}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            action={{
              canEdit: false,
              canDelete: true,
              canDownload: true,
              canView: false,
            }}
            setEnableApplyReconciliation={setEnableApplyReconciliation}
          />
          {enableApplyReconciliation && (
            <ConfirmDialogBox
              enableApplyReconciliation={enableApplyReconciliation}
              setEnableApplyReconciliation={setEnableApplyReconciliation}
              selectedData={selectedData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TaskCompleted;
