import { CircularProgress } from "@mui/material";
import { Flex } from "../../assets/styles/Chatbot";

const Loader = () => {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        className="loader"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Flex>
    </>
  );
};

export default Loader;
