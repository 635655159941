import * as React from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IconButton } from "@mui/material";

export default function TooltipComponent(data) {
  const { message, position } = data;
  return (
    <Box>
      <Tooltip title={message} placement={position}>
        <IconButton sx={{ m: 1 }}>
          <AiOutlineInfoCircle style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
