import React from "react";
import UploadImage from "../../assets/images/uploadImage.svg";
/**
 * Description placeholder
 * @date 10/4/2023 - 11:13:30 PM
 *
 * @param {{ file: any; setFile: any; }} { file, setFile }
 * @returns {*}
 */
function InputDragAndDrop({ file, setFile, loading, setloading }) {
  const onFileCapture = async (e) => {
    e.preventDefault();
    let fileArray = file.files ? file.files : [];
    for (let fileItem of e.target.files) {
      fileArray.push(fileItem);
    }

    setFile({ ...file, files: fileArray, started: true });
  };
  return (
    <form action="" id="drag-and-drop-form" style={{ cursor: "pointer" }}>
      <div
        className="drag-and-drop-div"
        style={{
          cursor: "pointer",
        }}
      >
        {!loading && (
          <input
            type="file"
            id="drag-and-drop"
            onChange={onFileCapture}
            multiple
            accept=".pdf"
            style={{
              cursor: "pointer",
            }}
          />
        )}
        <label htmlFor="drag-and-drop">
          <div
            className="input-drag-drop-div"
            style={{
              height: file.files.length === 0 ? "400px" : "200px",
            }}
          >
            <img src={UploadImage} alt="" />
            <h1>
              Drag and Drop or <span>Upload a file</span> here
            </h1>
          </div>
        </label>
      </div>
    </form>
  );
}

export default InputDragAndDrop;
