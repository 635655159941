import React, { useState } from "react";
import UploadImage from "../../assets/images/uploadImage.svg";
import {
  UploadPDFAWS,
  UploadPDFForMasking,
} from "../../utils/Services/api.pdfmasking";
import { ReactAWSS3 } from "../../utils/FileUpload";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { encryptStorage } from "../../utils";
/**
 * Description placeholder
 * @date 10/4/2023 - 11:13:30 PM
 *
 * @param {{ file: any; setFile: any; }} { file, setFile }
 * @returns {*}
 */
function FileInputAutoSubmission({
  setFile,
  uuid,
  loading,
  setloading,
  setViewer,
}) {
  const [progress, setProgress] = useState([]);

  const onFileCapture = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem("mask_file_id");
    const file = e.target.files[0];
    setFile({ files: file, started: true });
    setloading(true);
    await ReactAWSS3(file, uuid, setProgress, 0);
    setProgress([]);
    setFile({});
    setloading(false);
    setViewer(true);
    await UploadPDFForMasking(uuid)
      .then((res) => {
        setFile({
          ...file,
          files: res.data.data.data,
          mask_file_id: res.data.data["mask-file"].id,
        });
        if (res.data.success) {
          sessionStorage.setItem(
            "mask_file_id",
            String(res.data.data["mask-file"].id)
          );
          sessionStorage.setItem("uuid", uuid);
          toast.success(res.data.message);
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <form action="" id="drag-and-drop-form" style={{ cursor: "pointer" }}>
      <div
        className="drag-and-drop-div"
        style={{
          cursor: "pointer",
        }}
      >
        {!loading && (
          <input
            type="file"
            id="drag-and-drop"
            onChange={onFileCapture}
            accept=".pdf"
            style={{
              cursor: "pointer",
            }}
          />
        )}
        <label htmlFor="drag-and-drop">
          <div
            className="input-drag-drop-div"
            style={{
              height: "400px",
            }}
          >
            <img src={UploadImage} alt="" />
            <h1>
              Drag and Drop or <span>Upload a file</span> here for masking.
            </h1>
            {progress[0] > 0 && (
              <Grid className="progress-bar-container">
                <progress value={progress[0]} max={100} />
                <span>{progress[0]}%</span>
              </Grid>
            )}
          </div>
        </label>
      </div>
    </form>
  );
}

export default FileInputAutoSubmission;
