import * as React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { RxCross2 } from "react-icons/rx";
import { BsTrash3 } from "react-icons/bs";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoCloseCircleSharp } from "react-icons/io5";
import { BASE_URL } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledHeading,
} from "../../assets/styles/InputOutputFolder";
import { Box } from "@mui/material";
import { MdDone } from "react-icons/md";

export default function ApplyreconciliationModal({
  isopen,
  setisopen,
  data,
  fileDetails,
}) {
  const navigate = useNavigate();
  const temp = sessionStorage.getItem("token");

  const handleClose = () => {
    setisopen(false);
  };

  const HandleSumbit = async (e) => {
    e.preventDefault();
    try {
      let response = await axios.put(
        BASE_URL + `/extracted-file/${fileDetails?.file_detail_id}`,
        {
          data: data,
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.error) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return () => {};
        }
      } else {
        if (response.status === 200) {
          toast.success(response?.data?.message);
          navigate("/reconciliated");
        } else {
          return;
        }
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      // toast.error(error.response?.data?.message);
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "0rem 1rem",
            width: "380px",
            minHeight: "279px",
          },
        }}
        sx={{ zIndex: 1000 }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isopen}
      >
        <Box
          component={"form"}
          method="POST"
          onSubmit={HandleSumbit}
          style={{
            background: "white",
            overflow: "unset",
            position: "relative",
          }}
        >
          <div
            className="cancle-btn"
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "500px",
              background: "#AC2734 0% 0% no-repeat padding-box",
              position: "absolute",
              top: "-10px",
              right: "-30px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <RxCross2
              style={{
                fontSize: "1.2rem",
                color: "white",
              }}
            />
          </div>

          <DialogContent disablePadding>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #50DFB2",
                  margin: "0 auto",
                }}
              >
                <MdDone
                  style={{
                    fontSize: "3rem",
                    color: "#50DFB2",
                  }}
                />
              </div>

              <StyledHeading
                style={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  margin: "0",
                  marginTop: "0.7rem",
                }}
              >
                Are you sure you want to Apply Reconcilation?
              </StyledHeading>

              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <StyledButton
                  style={{
                    padding: "0.3rem 1rem",
                    background: "#50dfb2 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    fontSize: "0.9rem",
                    color: "white",
                  }}
                  type="submit"
                >
                  Confirm
                </StyledButton>
                <StyledButton
                  style={{
                    marginLeft: "1rem",
                    border: "2px solid #AC2635",
                    background: "transparent",
                    color: "#AC2635",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </StyledButton>
              </div>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
