import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Flex } from "../../assets/styles/Chatbot";
import { BASE_URL } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";

function EditConversationDialog({ isopen, setConvoClose }) {
  const [loading, setLoading] = useState(false);
  const temp = sessionStorage.getItem("token");
  const [value, setValue] = useState(() => {
    return isopen?.data?.conversation_starter || "";
  });

  useEffect(() => {
    if (isopen.open) {
      setValue(isopen?.data?.conversation_starter);
    }
  }, [isopen.open]);

  const HandleEditConvo = async () => {
    try {
      setLoading(true);
      let response = await axios.patch(
        BASE_URL + "/update-starter",
        { starter_id: isopen.data.id, conversation_starter: value },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          toast.success(response.data.message);
          setValue("");
          setLoading(false);
          setConvoClose({
            open: false,
            data: null,
          });
        } else {
          return;
        }
      }
    } catch (error) {
      //   console.log(error.response?.data?.message);
      setLoading(false);
      toast.error(error.response?.data?.message);
    }

    // setLoading(true);
    // http()
    //   .patch("update-starter", {
    //     starter_id: isopen.data.id,
    //     conversation_starter: value,
    //   })
    //   .then((res) => {
    //     Notification.success(res.data.message);
    //     setLoading(false);
    //     setValue("");
    //     setConvoClose({
    //       open: false,
    //       data: null,
    //     });
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     Notification.error(err.response.data.message);
    //   });
  };

  return (
    <React.Fragment>
      <Dialog
        open={isopen.open}
        onClose={() => {
          setLoading(false);
          setValue("");
          setConvoClose({
            open: false,
            data: null,
          });
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Edit Conversation
        </DialogTitle>
        <DialogContent
          sx={{
            width: "470px",
          }}
        >
          <Flex flex="1">
            <textarea
              placeholder="Enter custom conversation here..."
              className="custom_textarea"
              autoSize={{ minRows: 5 }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              style={{
                marginTop: "1rem",
                fontSize: "0.9rem",
              }}
            ></textarea>
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button
            key="back"
            variant="outlined"
            color="error"
            sx={{
              textTransform: "none",
              fontSize: "0.8rem",
            }}
            onClick={() => {
              setLoading(false);
              setValue("");
              setConvoClose({
                open: false,
                data: null,
              });
            }}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            variant="contained"
            //   loading={loading}
            onClick={HandleEditConvo}
            sx={{
              backgroundColor: "#1677ff",
              textTransform: "none",
              fontSize: "0.8rem",
            }}
          >
            {loading && (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />
            )}
            Save conversation
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditConversationDialog;
