import styled from "styled-components";
import LoginBackground from "./../images/LandingPage.jpg";
import { Button } from "@mui/material";

export const ForgotPasswordContainer = styled.div`
  display: flex;
  padding: 0.4rem 0rem;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins";
  margin-left: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: gray;

  & .remember_me {
    display: flex;
    align-items: center;
    > label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      margin-left: 0.3rem;
    }
  }

  > a {
    text-decoration: none;
    font-family: "Poppins";
    margin-left: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    margin-left: 0.3rem;
    color: #101060;
  }
`;

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #000000;
  display: flex;
`;

export const LoginRight = styled.div`
  width: 68%;
  padding: 4rem;
  display: flex;
  align-items: flex-end;
  background: url(${LoginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;

  & p {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.4;
    color: #ffdc5e;
    > span {
      display: block;
    }
  }
`;

export const LoginLeft = styled.div`
  flex: 1;
  position: relative;
  padding: 4rem 3rem;
`;

export const LoginFormContainer = styled.div`
  min-height: 310px;
  width: 300px;
  padding: 2rem;
  border-radius: 20px;
  position: absolute;
  top: 30%;
  left: -30%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #eaeaea;

  & .login-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: black;
    margin-bottom: 0.2rem;
  }

  & .login-subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 12px;
    color: #4b5455;
    margin-bottom: 1rem;
  }

  & .login_circle {
    width: 35px;
    height: 35px;
    background-color: white;
    position: absolute;
    border-radius: 500px;
    top: 7px;
    left: 7px;
    transform: translate(-50%, -50%);
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  & .login_circle-2 {
    width: 22px;
    height: 22px;
    background-color: #ffdc5e;
    position: absolute;
    border-radius: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoBox = styled.div`
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 0.5rem;
  width: 65px;
  height: 59px;
  & .MentorwiseW {
    position: absolute;
    top: 21px;
    width: 48px;
    left: 50%;
    transform: translateX(-50%);
  }

  & .MentorwiseM {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 33px;
  }
`;

export const NameContainer = styled.div`
  margin-left: 0.8rem;
  & p {
    padding: 0;
    margin: 0;
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
`;

export const StyledLoginInput = styled.input`
  width: 100%;
  background-color: #ffffff;
  border-radius: 60px;
  border: none;
  outline: none;
  padding: 0.7rem 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: grey;
  margin-bottom: 0.7rem;
  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: rgba(198, 198, 198, 0.7);
  }
`;

export const StyledLoginButton = styled(Button)`
  color: white !important;
  background: #000000 !important;
  border-radius: 60px !important;
  margin-top: 0.7rem !important;
  text-transform: none !important;
  font-family: "Poppins" !important;
  font-weight: 500 !important;
`;

export const StyledAddUser = styled.input`
  width: 100%;
  background-color: #ffffff;
  border-radius: 60px;
  border: none;
  outline: none;
  padding: 0.7rem 1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: black;
  background: #e8f0fe;
  margin-bottom: 0.7rem;
  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #bdbdbd;
  }
`;
