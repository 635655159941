import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Flex } from "../../assets/styles/Chatbot";

export default function PDFCarousel({ files }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = files.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Flex width="100%" height="100%" direction="column">
      {/* <Paper
        square
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          height: 50,
          pl: 2,
          bgcolor: "background.default",
        }}
      >
        <Typography>{files[activeStep].file_name}</Typography>
      </Paper> */}
      <Flex flex="1" width="100%">
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <embed
            src={files[activeStep]?.binary_url}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{
              backgroundColor: "white",
            }}
          />{" "}
        </div>
      </Flex>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        sx={{
          width: "100%",
          background: "#001529",
          color: "white",
          fontSize: "0.85rem",
          textTransform: "none",
        }}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            variant="outlined"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <IoIosArrowBack />
            ) : (
              <IoIosArrowForward />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <IoIosArrowForward />
            ) : (
              <IoIosArrowBack />
            )}
            Back
          </Button>
        }
      />
    </Flex>
  );
}
