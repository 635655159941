import React, { useContext, useEffect } from "react";
import RightHeadedArrow from "../../../assets/images/Path 2710.svg";
import AddArrow from "../../../assets/images/Group 37503.svg";
import { UserDataContext } from "../../../AppRouter";
import { useNavigate } from "react-router";
import { IoOpenOutline } from "react-icons/io5";
import CryptoJS from "crypto-js";
import { decryptStorage } from "../../../utils";

export const AdminDashboardSummary = ({ handleAdminDashboardData }) => {
  const {
    userData: { file_details },
  } = useContext(UserDataContext);
  const navigate = useNavigate();

  const email = CryptoJS.AES.encrypt(
    decryptStorage(sessionStorage.getItem("userDetails")).email,
    process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY
  ).toString();
  const password = CryptoJS.AES.encrypt(
    decryptStorage(sessionStorage.getItem("userDetails")).password,
    process.env.REACT_APP_ENCRYPT_DECRYPT_SECRET_KEY
  ).toString();

  const cardData = [
    {
      count: file_details?.Task_in_progress ? file_details.Task_in_progress : 0,
      heading: "PDF Extraction",
      status: "In Progress",
      viewListURL: "#",
      fontColor: "#fafafa",
      background: "#0E0E4F",
      cursor: "auto",
    },
    {
      count: file_details?.Task_Completed ? file_details.Task_Completed : 0,
      heading: "PDF Extraction",
      status: "Completed",
      viewListURL: "#",
      fontColor: "#fafafa",
      background: "#50DFB2",
      cursor: "auto",
    },
    {
      count: file_details?.reconsilated ? file_details.reconsilated : 0,
      heading: "Total Files",
      status: "Reconciled",
      viewListURL: "/reconciliated",
      fontColor: "#fafafa",
      background: "#FC8A2E",
      cursor: "pointer",
    },
    {
      count: file_details?.ruleset_list ? file_details?.ruleset_list : 0,
      heading: "Total",
      status: "Rulesets",
      viewListURL: "/rulesetlist",
      fontColor: "#fafafa",
      background: "#2e6afc",
    },
    {
      count: <IoOpenOutline fontSize={""} />,
      heading: "Shortcut to",
      status: "PDF Extractor",
      viewListURL: `${process.env.REACT_APP_PDF_EXTRACTOR_URL}/?${email}&&${password}`,
      fontColor: "#fafafa",
      background: "#fc2e9d",
    },
  ];
  return (
    <div className="admin-summary-main-div">
      {cardData.map((value) => {
        return (
          <a
            href={value.viewListURL}
            target={value.status === "PDF Extractor" ? "_blank" : ""}
            className="summary-card admin-summary-card"
            style={{ cursor: value.cursor }}
            rel="noreferrer"
          >
            <div
              className="summary-card-number"
              style={{
                color: value.fontColor,
                background: value.background,
              }}
            >
              <div>{value.count}</div>
            </div>
            <div
              className="summary-card-data"
              style={{
                cursor: value.cursor,
              }}
            >
              <div className="summary-card-headings">
                <p
                  style={{
                    color: value.background,
                  }}
                >
                  {value.heading}
                </p>
                <h4
                  style={{
                    color: value.background,
                  }}
                >
                  {value.status}
                </h4>
              </div>
            </div>
          </a>
        );
      })}
      <div
        className="summary-card admin-summary-card"
        style={{ display: "flex", flexDirection: "column", gap: "0" }}
      >
        <div className="admin-card-sub-wrapper">
          <div
            className="summary-card-number"
            style={{
              color: "#fafafa",
              background: "#8486fa",
            }}
          >
            <div>{file_details?.input_files}</div>
          </div>
          <div
            className="summary-card-data"
            style={{}}
            onClick={() => {
              navigate("/inputfolder");
            }}
          >
            <div className="summary-card-headings">
              <p
                style={{
                  color: "#8486fa",
                }}
              >
                Total Files
              </p>
              <h4
                style={{
                  color: "#8486fa",
                }}
              >
                Input Files
              </h4>
            </div>
          </div>
        </div>
        <div className="admin-card-sub-wrapper">
          <div
            className="summary-card-number"
            style={{
              color: "#fafafa",
              background: "#8486fa",
            }}
          >
            <div>{file_details?.output_files}</div>
          </div>
          <div
            className="summary-card-data"
            onClick={() => {
              navigate("/outputfolder");
            }}
          >
            <div className="summary-card-headings">
              <p
                style={{
                  color: "#8486fa",
                }}
              >
                Total Files
              </p>
              <h4
                style={{
                  color: "#8486fa",
                }}
              >
                Output Files
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
