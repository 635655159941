import React from "react";
import { Flex } from "../../assets/styles/Chatbot";
import { Button, CircularProgress } from "@mui/material";

function Search({
  placeholder = "",
  loading = false,
  value,
  onChange,
  enterButton,
  onSearch,
}) {
  const HandleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(value);
    }
  };

  return (
    <Flex
      flex="1"
      width="100%"
      borderRadius={"6px"}
      align="center"
      className="chat-search-box"
      onKeyDown={HandleKeyPress}
    >
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <Button
        className="chat-search-btn"
        onClick={() => {
          onSearch(value);
        }}
        disabled={loading}
      >
        {loading && <CircularProgress className="chat-search-progress" />}
        {enterButton}
      </Button>
    </Flex>
  );
}

export default Search;
