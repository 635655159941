import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiReset, BiFilterAlt } from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { UserDataContext } from "../../AppRouter";
import { FaRegFolderOpen } from "react-icons/fa";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import AdminSubTable from "./AdminSubTable";
import { BsFolderPlus } from "react-icons/bs";
import InputOutputFolder from "./InputOutputFolder";
import { AiFillFolderOpen } from "react-icons/ai";
import DeleteConfirmation from "./DeleteConfirmation";
import { Navigate, useNavigate } from "react-router";
import moment from "moment/moment";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import DateFormatter from "../../utils/DateFormatter";

function AdminTableGrid({ ...data }) {
  const { tableHeading, userTaskInputData, pageName, type, heading } = data;

  const { setUserInputData, setUserOutputData } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [currentFolder, setcurrentFolder] = useState(undefined);
  const [openCreateFolderModal, setopenCreateFolderModal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState({
    open: false,
    data: undefined,
  });

  const [searchFilter, setSearchFilter] = useState(false);
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [filename, setFileName] = useState();

  const [startDate, setstartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [dateDialog, setDateDialog] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [folder, setFolder] = useState({
    isFolder: false,
    folderName: "",
    folderContent: [],
  });

  const [sortByName, setsortByName] = useState(false);
  const [sortByDate, setsortByDate] = useState(false);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((id) => {
          // eslint-disable-next-line eqeqeq
          return id != value;
        });
      });
    }
  };

  const allCheckboxHandler = () => {
    if (userTaskInputData.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      let allFileId = userTaskInputData.map((value) => value.file_id);
      setSelectedItems(allFileId);
    }
  };
  const handleUserTasks = async (setisloading) => {
    let url =
      pageName === "Output Folder" || pageName === "Input Folder"
        ? "/get-io-folder-details"
        : "/get-user-files-details";
    try {
      if (typeof setisloading === "function") {
        setisloading(true);
      }
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },

        params: {
          folder_name: filename,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (typeof setisloading === "function") {
          setisloading(false);
        }
        if (response.status === 200) {
          if (pageName === "Output Folder" || pageName === "Input Folder") {
            setUserInputData(response?.data?.data?.Input);
            setUserOutputData(response?.data?.data?.Output);
          } else {
            setUserInputData(response?.data?.data?.root.Input);
            setUserOutputData(response?.data?.data?.root.Output);
          }
        } else {
          return;
        }
      }
    } catch (error) {
      if (typeof setisloading === "function") {
        setisloading(false);
      }
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const deleteHandler = async (file) => {
    try {
      let response = await axios.post(
        BASE_URL + "/remove-s3-file",
        {
          file_id: file,
          file_type: String(type).toUpperCase(),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.status === 200) {
          handleUserTasks();
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const handleOpenFolder = (e, userTaskInputData) => {
    e.preventDefault();
    setFolder({
      isFolder: !folder.isFolder,
      //   folderName: name,
      folderContent:
        pageName === "Output Folder" || pageName === "Input Folder"
          ? userTaskInputData?.children
          : userTaskInputData,
    });

    setcurrentFolder(userTaskInputData);
  };

  useEffect(() => {
    if (currentFolder !== undefined && folder.isFolder) {
      const res = userTaskInputData?.find((data) => {
        return data?.folder_id === currentFolder?.folder_id;
      });

      setcurrentFolder(res);
      setFolder({
        isFolder: folder.isFolder,
        folderContent:
          pageName === "Output Folder" || pageName === "Input Folder"
            ? res?.children
            : "",
      });
    }
  }, [userTaskInputData]);

  useEffect(() => {
    handleUserTasks(setisloading);
  }, [filename]);

  const handleFilterOpening = (e) => {
    setSearchFilter(!searchFilter);
    if (searchFilter) setFileName();
  };
  const handleDateFilter = (e) => {
    setDateDialog(!dateDialog);
    if (dateDialog) {
      setDate("");
      setstartDate("");
      setEndDate("");
      handleDateFilterApply(e);
    }
  };

  const Filter = ({ value }) => {
    if (value === "Folder Name") {
      return (
        <>
          <button
            onClick={handleFilterOpening}
            className="filter-state-handler"
          >
            {searchFilter ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
    if (value === "Created On") {
      return (
        <>
          <button onClick={handleDateFilter} className="filter-state-handler">
            {dateDialog ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
  };

  const handleDateFilterApply = async (e) => {
    e.preventDefault();
    let url =
      pageName === "Output Folder" || pageName === "Input Folder"
        ? "/get-io-folder-details"
        : "/get-user-files-details";
    try {
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },

        params: {
          folder_name: filename,
          folder_start_date:
            startDate !== "" ? moment(startDate).format("MM/DD/YYYY") : null,
          folder_end_date:
            EndDate !== "" ? moment(EndDate).format("MM/DD/YYYY") : null,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          if (pageName === "Output Folder" || pageName === "Input Folder") {
            setUserInputData(response?.data?.data?.Input);
            setUserOutputData(response?.data?.data?.Output);
          } else {
            setUserInputData(response?.data?.data?.root.Input);
            setUserOutputData(response?.data?.data?.root.Output);
          }
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const DateFilterComponent = () => {
    return (
      <>
        <form className="dateFilterForm" onSubmit={handleDateFilterApply}>
          <input
            type="date"
            className="date-filter"
            placeholder="From"
            value={startDate}
            onChange={(e) => {
              e.preventDefault();
              setstartDate(e.target.value);
            }}
          />
          <input
            type="date"
            className="date-filter"
            min={startDate}
            placeholder="To"
            value={EndDate}
            onChange={(e) => {
              e.preventDefault();
              setEndDate(e.target.value);
            }}
          />
          <div style={{ display: "flex" }}>
            <button className="apply-button" type="submit">
              Apply
            </button>
            <button
              className="reset-button"
              type="reset"
              onClick={() => {
                setDate("");
                setstartDate("");
                setEndDate("");
              }}
            >
              <BiReset />
            </button>
          </div>
        </form>
      </>
    );
  };

  const SearchFilterComponent = useCallback(() => {
    return (
      <>
        <div className="searchFilterForm">
          <input
            type="text"
            placeholder="..search"
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            value={filename}
          />
        </div>
      </>
    );
  }, []);

  function sortAscendingName() {
    const arr = userTaskInputData.slice().sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    setUserInputData([...arr]);
  }

  // Function to sort in descending order

  function compareNameAscending(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name?.toUpperCase();
    const bandB = b.name?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  function compareNameDescending(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name?.toUpperCase();
    const bandB = b.name?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  return (
    <>
      <div className="task-container">
        <div className="task-header-container">
          <div className="task-heading">{heading}</div>
          <div className="task-heading-button-container">
            <Button
              sx={{
                background: "#50DFB2 0% 0% no-repeat padding-box",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  background: "#50DFB2 0% 0% no-repeat padding-box",
                  color: "white",
                },
              }}
              onClick={() => {
                setopenCreateFolderModal(true);
              }}
            >
              Create Folder
              <BsFolderPlus
                style={{
                  fontSize: "1.2rem",
                  marginLeft: "0.2rem",
                }}
              />
            </Button>
          </div>
        </div>
        {!folder.isFolder ? (
          <div
            className="table-body-container"
            style={{
              position: "relative",
            }}
          >
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {pageName === "Output Folder" ||
                  pageName === "Input Folder" ? null : (
                    <TableCell
                      align="center"
                      sx={{
                        padding: "0",
                        backgroundColor: "#EEF3F6",
                        border: "none",
                      }}
                    >
                      <MenuItem>
                        <Checkbox
                          size="small"
                          style={{ margin: "0 auto", color: "#50DFB2" }}
                          onChange={allCheckboxHandler}
                          checked={
                            userTaskInputData.length > 0 &&
                            userTaskInputData.length === selectedItems.length
                          }
                        ></Checkbox>
                      </MenuItem>
                    </TableCell>
                  )}

                  {tableHeading &&
                    tableHeading.map((value) => {
                      return (
                        <>
                          <TableCell
                            sx={{
                              font: "normal normal 700 15px/21px Segoe UI",
                              backgroundColor: "#EEF3F6",
                              border: "none",
                            }}
                          >
                            <span>
                              {value === "Folder Name" && (
                                <button
                                  style={{
                                    marginLeft: "0px",
                                  }}
                                  className="filter-state-handler"
                                  onClick={() => {
                                    setsortByName(!sortByName);

                                    if (sortByName) {
                                      userTaskInputData.sort(
                                        compareNameAscending
                                      );
                                    }

                                    if (!sortByName) {
                                      userTaskInputData.sort(
                                        compareNameDescending
                                      );
                                    }
                                  }}
                                >
                                  {sortByName ? <FaSortDown /> : <FaSortUp />}
                                </button>
                              )}

                              {value === "Created On" && (
                                <button
                                  style={{
                                    marginLeft: "0px",
                                  }}
                                  className="filter-state-handler"
                                  onClick={() => {
                                    setsortByDate(!sortByDate);

                                    if (sortByDate) {
                                      userTaskInputData.sort((a, b) => {
                                        return (
                                          new Date(a?.created_at) -
                                          new Date(b?.created_at)
                                        );
                                      });
                                    }

                                    if (!sortByDate) {
                                      userTaskInputData.sort((a, b) => {
                                        return (
                                          new Date(b?.created_at) -
                                          new Date(a?.created_at)
                                        );
                                      });
                                    }
                                  }}
                                >
                                  {sortByDate ? <FaSortDown /> : <FaSortUp />}
                                </button>
                              )}
                              {value}
                              {<Filter value={value} />}
                            </span>

                            {/* Name Filter */}

                            {value === "Folder Name" && searchFilter && (
                              <SearchFilterComponent />
                            )}

                            {/* Date Filter */}
                            {value === "Created On" && dateDialog && (
                              <DateFilterComponent />
                            )}
                          </TableCell>
                        </>
                      );
                    })}
                </TableRow>
              </TableHead>

              {isloading ? (
                <div
                  style={{
                    position: "absolute",
                    inset: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress> </CircularProgress>
                </div>
              ) : (
                <TableBody>
                  {userTaskInputData &&
                    userTaskInputData?.map((inputData, index) => (
                      <TableRow>
                        {pageName === "Output Folder" ||
                        pageName === "Input Folder" ? null : (
                          <TableCell align="center" sx={{ padding: "0" }}>
                            <Checkbox
                              size="small"
                              style={{ margin: "0 auto", color: "#50DFB2" }}
                              onChange={checkboxHandler}
                              value={inputData.file_id}
                              checked={selectedItems.includes(
                                inputData.file_id
                              )}
                            />
                          </TableCell>
                        )}

                        <TableCell
                          sx={{
                            font: "normal normal 500 13px/21px Segoe UI",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                          name={inputData.filename}
                          onClick={(e) =>
                            handleOpenFolder(
                              e,
                              pageName === "Output Folder" ||
                                pageName === "Input Folder"
                                ? inputData
                                : userTaskInputData
                            )
                          }
                        >
                          <AiFillFolderOpen
                            style={{
                              fontSize: "1rem",
                              marginRight: "0.25rem",
                            }}
                          />
                          {pageName === "Output Folder" ||
                          pageName === "Input Folder"
                            ? inputData?.name
                            : inputData.filename}
                        </TableCell>
                        <TableCell
                          sx={{ font: "normal normal 500 13px/21px Segoe UI" }}
                        >
                          {pageName === "Output Folder" ||
                          pageName === "Input Folder"
                            ? inputData?.created_at
                            : inputData.filename}
                        </TableCell>
                        <TableCell
                          sx={{
                            font: "normal normal 500 13px/21px Segoe UI",
                            textAlign: "left",
                            paddingLeft: "20px",
                          }}
                        >
                          {pageName === "Output Folder" ||
                          pageName === "Input Folder"
                            ? DateFormatter(inputData?.created_at)
                            : ""}
                        </TableCell>
                        {pageName === "Output Folder" ||
                        pageName === "Input Folder" ? null : (
                          <TableCell
                            sx={{
                              font: "normal normal 500 13px/21px Segoe UI",
                            }}
                          >
                            {pageName === "Output Folder" ||
                            pageName === "Input Folder"
                              ? "Admin"
                              : DateFormatter(inputData.created_at)}
                          </TableCell>
                        )}

                        <TableCell>
                          <div
                            className="table-action-column"
                            style={{ justifyContent: "flex-start" }}
                          >
                            <button
                              style={{
                                border: "2px solid #E11D1D",
                              }}
                              onClick={() => {
                                if (
                                  pageName === "Output Folder" ||
                                  pageName === "Input Folder"
                                ) {
                                  setopenDeleteModal({
                                    open: true,
                                    data: {
                                      folder_id: inputData?.folder_id,
                                    },
                                  });
                                } else {
                                  deleteHandler([inputData.file_id]);
                                }
                              }}
                            >
                              <RiDeleteBinLine
                                style={{
                                  color: "#E11D1D",
                                }}
                              />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </div>
        ) : (
          <div className="table-body-container">
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      padding: "0",
                      width: "20px",
                      height: "40px",
                    }}
                  >
                    <button
                      className="table-back-button"
                      onClick={(e) => {
                        handleUserTasks();
                        handleOpenFolder(e);
                      }}
                    >
                      <HiOutlineArrowSmLeft />{" "}
                    </button>
                  </TableCell>

                  <TableCell colSpan={tableHeading.length}>
                    {" "}
                    <p
                      style={{
                        margin: "0rem",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      /
                      <AiFillFolderOpen
                        style={{
                          fontSize: "1.3rem",
                          margin: "0rem 0.25rem",
                        }}
                      />{" "}
                      {currentFolder?.name}
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageName === "Output Folder" ||
                pageName === "Input Folder" ? null : (
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ padding: "0", width: "30px" }}
                    ></TableCell>
                    <TableCell
                      sx={{ font: "normal normal 500 13px/21px Segoe UI" }}
                    >
                      <FaRegFolderOpen />
                      {/* {inputData.filename} */}
                      {pageName === "Output Folder" ||
                      pageName === "Input Folder"
                        ? currentFolder?.name
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{ font: "normal normal 500 13px/21px Segoe UI" }}
                    >
                      {/* {inputData.filename} */}
                      {pageName === "Output Folder" ||
                      pageName === "Input Folder"
                        ? DateFormatter(currentFolder?.created_at)
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        font: "normal normal 500 13px/21px Segoe UI",
                        textAlign: "left",
                        paddingLeft: "20px",
                      }}
                    >
                      {pageName === "Output Folder" ||
                      pageName === "Input Folder"
                        ? DateFormatter(currentFolder?.created_at)
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{ font: "normal normal 500 13px/21px Segoe UI" }}
                    >
                      {pageName === "Output Folder" ||
                      pageName === "Input Folder"
                        ? "Admin"
                        : ""}
                    </TableCell>
                  </TableRow>
                )}

                <AdminSubTable
                  tableHeading={[
                    "File Name",
                    "Upload Date",
                    "Upload By",
                    "Action",
                  ]}
                  userTaskInputData={folder.folderContent}
                  type={pageName === "Input Folder" ? "PDF" : "excel"}
                  pageName={pageName}
                />
              </TableBody>
            </Table>
          </div>
        )}
      </div>

      {openCreateFolderModal && (
        <InputOutputFolder
          isopen={openCreateFolderModal}
          setIsopen={setopenCreateFolderModal}
          handleUserTasks={handleUserTasks}
        />
      )}

      {openDeleteModal && (
        <DeleteConfirmation
          isopen={openDeleteModal}
          setIsopen={setopenDeleteModal}
          detailsProps={{
            url: "/",
            title: "This action will delete the folder?",
          }}
          page={pageName}
          handleUserTasks={handleUserTasks}
          setSelectedItems={setSelectedItems}
        />
      )}

    </>
  );
}

export default AdminTableGrid;
