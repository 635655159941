import { Flex } from "../../assets/styles/Chatbot";
import NoDatasvg from "../../assets/images/Nodata.svg";

function NoData({
  width = "300px",
  height = "300px",
  color = "white",
  text = " No File Found/Selected",
}) {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <img
        src={NoDatasvg}
        alt="no data found"
        style={{
          width: width,
          objectFit: "contain",
        }}
      />
      <span
        style={{
          fontSize: "1.4rem",
          fontWeight: "500",
          color: color,
          marginTop: "1rem",
        }}
      >
        {text}
      </span>
    </Flex>
  );
}

export default NoData;
