import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledHeading,
  StyledInput,
  StyledInputLabel,
  StyledListItem,
} from "../../assets/styles/InputOutputFolder";

function EditReconcillationResult({
  isopen,
  setIsopen,
  editableData,
  seteditableData,
  setreconData,
}) {
  const handleCloseManual = () => {
    setIsopen({
      open: false,
      row: undefined,
      column: undefined,
    });
  };

  const [value, setValue] = useState(() => {
    return String(editableData[isopen.row][isopen.column]);
  });

  const HandleSave = (e) => {
    e.preventDefault();
    const newData = [...editableData];
    newData[isopen.row][isopen.column] = value;
    seteditableData(newData);
    setreconData(newData);
    handleCloseManual();
  };

  const HandleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "0rem 1rem",
            minWidth: "400px",
            minHeight: "279px",
          },
        }}
        sx={{ zIndex: 1000 }}
        onClose={handleCloseManual}
        aria-labelledby="customized-dialog-title"
        open={isopen}
      >
        <Box
          component={"form"}
          style={{
            background: "white",
            overflow: "unset",
            position: "relative",
          }}
        >
          <div
            className="cancle-btn"
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "500px",
              background: "#AC2734 0% 0% no-repeat padding-box",
              position: "absolute",
              top: "-10px",
              right: "-30px",
              cursor: "pointer",
            }}
            onClick={handleCloseManual}
          >
            <RxCross2
              style={{
                fontSize: "1.2rem",
                color: "white",
              }}
            />
          </div>

          <DialogContent disablePadding>
            <StyledHeading>Edit Cell Value</StyledHeading>

            <List
              style={{
                padding: "0",
              }}
            >
              <StyledListItem>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    width: "100%",
                  }}
                >
                  <StyledInputLabel htmlFor="folder_name">
                    New Value
                  </StyledInputLabel>
                  <StyledInput
                    type="text"
                    name="folder_name"
                    id="folder_name"
                    placeholder="Folder Name"
                    value={value}
                    onChange={HandleChange}
                    required
                  />
                </div>
              </StyledListItem>

              <StyledListItem
                style={{
                  marginTop: "1rem",
                }}
              >
                <StyledButton
                  style={{
                    padding: "0.3rem 1rem",
                    background: "#50dfb2 0% 0% no-repeat padding-box",
                    borderRadius: "5px",
                    fontSize: "0.9rem",
                    color: "white",
                  }}
                  onClick={HandleSave}
                >
                  Save
                </StyledButton>
                <StyledButton
                  style={{
                    marginLeft: "1rem",
                    border: "2px solid #AC2635",
                    background: "transparent",
                    color: "#AC2635",
                  }}
                  onClick={handleCloseManual}
                >
                  Cancel
                </StyledButton>
              </StyledListItem>
            </List>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default EditReconcillationResult;
