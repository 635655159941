import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import { BsFiletypePdf, BsFiletypeXls, BsFiletypeCsv } from "react-icons/bs";
import {
  BiEdit,
  BiReset,
  BiFilterAlt,
  BiSolidUserPlus,
  BiTrash,
} from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { UserDataContext } from "../../AppRouter";
import { AiOutlineEye } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router";
import { BiBlock } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import DashboardSearchBar from "./DashboardSearchBar";
import DeleteConfirmation from "./DeleteConfirmation";
import moment from "moment-timezone";
import StatusSelection from "./StatusSelection";
import AddUser from "./Admin/AddUser";
import DeleteUserModal from "./DeleteUserModal";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import DateFormatter from "../../utils/DateFormatter";
import TooltipComponent from "./TooltipComponent";

function TableGrid({ ...data }) {
  let {
    tableHeading,
    userTaskInputData,
    pageName,
    reconciliation,
    type,
    pageButton,
    allSelectEnable,
    action,
    setEnableApplyReconciliation,
    setrefresh,
    setinputtyped,
    inputtyped,
    selectedData,
    setSelectedData,
    getAllUser,
    handleReconciliatedData,
    filterName,
  } = data;

  const [isloading, setisloading] = useState(false);

  const statusColor = {
    failed: "#E11D1D",
    pending: "#FC8A2E",
    completed: "#50DFB2",
  };

  const statusBGColor = {
    failed: "#f7baba",
    pending: "#FCEEE2",
    completed: "#DFFCF3",
  };

  let dataSelected = [];
  const { handleRulesetList } = useContext(UserDataContext);
  const isAdmin = sessionStorage.getItem("userDetails").is_admin;
  const navigate = useNavigate();
  const { setUserInputData, setUserOutputData } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);
  const [filename, setFileName] = useState();
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [startDate, setstartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [dateDialog, setDateDialog] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState({
    open: false,
    data: undefined,
    isMultiple: false,
  });
  const [deleteUser, setDeleteUser] = useState({
    open: false,
    data: "",
  });
  const [filterstatus, setfilterstatus] = useState(false);
  const [inputstatus, setinputstatus] = useState({});

  const [sortByName, setsortByName] = useState(false);
  const [sortByDate, setsortByDate] = useState(false);
  useEffect(() => {
    if (pageButton === "taskProgress" || pageButton === "taskCompleted") {
      const timeout = setTimeout(() => {
        handleUserTasks(pageButton);
      }, [15000]);
      return () => clearInterval(timeout);
    }
  });

  useEffect(() => {
    if (pageButton === "taskProgress" || pageButton === "taskCompleted") {
      handleUserTasks(pageButton, setisloading);
    }
    if (pageButton === "ruleset") {
      handleRulesetList(
        {
          filename: filename,
          start_date: startDate,
          end_date: EndDate,
        },
        setisloading
      );
    }
    if (pageButton === "reconciliated") {
      handleReconciliatedData(
        {
          filename: filename,
          status: inputstatus,
          start_date: startDate,
          end_date: EndDate,
        },
        setisloading
      );
    }
    if (pageButton === "importhistory") {
      handleImportHistory(setisloading);
    }
  }, [filename, date, inputstatus]);

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);
    setSelectedData(dataSelected);
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
      userTaskInputData.forEach((input) => {
        Object.entries(input).filter(([key, keyValue]) => {
          if (keyValue == value) {
            let data = {};
            data["id"] = parseInt(input.file_id);
            data["filename"] = input.filename;
            dataSelected.push({ ...data });
          }
        });
      });
      setSelectedData(dataSelected);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((id) => {
          // eslint-disable-next-line eqeqeq
          return id != value;
        });
      });
      dataSelected = dataSelected.filter(function (data) {
        return data.id != value;
      });
      setSelectedData(dataSelected);
    }
  };

  const allCheckboxHandler = () => {
    if (userTaskInputData.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      let selectedDataItem = [];
      let allFileId = userTaskInputData.map((value) => value.file_id);
      userTaskInputData.map((input) => {
        const data = {};
        data["id"] = parseInt(input.file_id);
        data["filename"] = input.filename;
        selectedDataItem.push(data);
      });
      setSelectedData(selectedDataItem);
      setSelectedItems(allFileId);
    }
  };

  const handleImportHistory = async (setisloading) => {
    let url = isAdmin ? "/admin-files-history" : "/user-files-history";
    try {
      if (typeof setisloading === "function") {
        setisloading(true);
      }
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          file_name: filename,
          start_date:
            startDate !== "" ? moment(startDate).format("MM/DD/YYYY") : "",
          end_date: EndDate !== "" ? moment(EndDate).format("MM/DD/YYYY") : "",
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (typeof setisloading === "function") {
          setisloading(false);
        }
        if (response.data.success) {
          setUserInputData(response?.data?.data?.files);
        } else {
          return;
        }
      }
    } catch (error) {
      if (typeof setisloading === "function") {
        setisloading(false);
      }
      console.log("Something went wrong..!");
    }
  };
  const handleUserTasks = async (page, setisloading) => {
    try {
      if (typeof setisloading === "function") {
        setisloading(true);
      }
      let response = await axios.get(BASE_URL + "/get-user-files-details", {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          file_name: filename,
          tab: page === "taskCompleted" ? "complete" : "progress",
          start_date:
            startDate !== "" ? moment(startDate).format("MM/DD/YYYY") : "",
          end_date: EndDate !== "" ? moment(EndDate).format("MM/DD/YYYY") : "",
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (typeof setisloading === "function") {
          setisloading(false);
        }
        if (response.status === 200) {
          setUserInputData(response?.data?.data?.root.Input);
          setUserOutputData(response?.data?.data?.root.Output);
        } else {
          return;
        }
      }
    } catch (error) {
      if (typeof setisloading === "function") {
        setisloading(false);
      }
      console.log("Something went wrong..!");
    }
  };

  const deleteHandler = async (file) => {
    if (window.location.pathname.split("/")[1] === "importhistory")
      handleImportHistory();
    else handleUserTasks(pageButton);
    try {
      let response = await axios.post(
        BASE_URL + "/remove-s3-file",
        {
          file_id: file,
          file_type: String(type).toUpperCase(),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        toast.success("Successfully Deleted...!");
        setSelectedItems([]);
        setopenDeleteModal({
          open: false,
          data: undefined,
        });
        if (response?.data.success) {
          if (pageButton === "importhistory") handleImportHistory();
          else handleUserTasks(pageButton);
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const deleteRulesetHandler = async (file) => {
    try {
      let response = await axios.delete(BASE_URL + `/rule-set/${file}`, {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.status === 200) {
          toast.success(response.data.message);
          handleRulesetList();
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const reconciliatedViewResultHandler = async (id) => {
    try {
      let response = await axios.get(
        BASE_URL + `/reconciliated/${id}/data-result`,
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          navigate("/reconciliated-result", {
            state: response.data.data,
          });
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went Wrong..!");
    }
  };

  const deleteReconciliationHandler = async (id) => {
    try {
      let response = await axios.delete(BASE_URL + `/reconciliated/${id}`, {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.status === 200) {
          toast.success(response?.data?.message);
          handleReconciliatedData();
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const base64ToArrayBuffer = (base, contentType, fileName) => {
    let binaryString = window.atob(base);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    switch (contentType) {
      case "txt":
        contentType = "text/plain";
        break;
      case "xlsx":
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "xls":
        contentType = "application/vnd.ms-excel";
        break;
      case "pdf":
        contentType = "application/pdf";
        break;
      case "pptx":
        contentType =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      case "ppt":
        contentType = "application/vnd.ms-powerpoint";
        break;
      case "docx":
        contentType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "doc":
        contentType = "application/msword";
        break;
      case "csv":
        contentType = "text/csv";
        break;
      default:
        contentType = "";
    }
    saveByteArray(fileName, bytes, contentType);
  };

  /**
   *
   * @param {String} reportName | File name
   * @param {Byte} byte | Byte array
   * @param {String} contentType | Type of content/file
   */
  const saveByteArray = (reportName, byte, contentType) => {
    let blob = new Blob([byte], { type: contentType });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
    toast.success("Successfully Downloaded...!");
  };

  const downloadHandler = async (file) => {
    try {
      let response = await axios.post(
        BASE_URL + "/get-s3-file",
        {
          file_id: file,
          file_type: String(type).toUpperCase(),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.status === 200) {
          // eslint-disable-next-line array-callback-return
          response.data.data.map((value) => {
            let file = String(value.file).slice(2, -1);
            base64ToArrayBuffer(
              file,
              String(value.file_type).toLowerCase(),
              value.filename
            );
          });
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const reconciliatedDownloadHandler = async (id, filename) => {
    try {
      let response = await axios.get(BASE_URL + `/reconciliated/${id}/data`, {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.status === 200) {
          // eslint-disable-next-line array-callback-return
          filename = filename.slice(0, -4);
          let value = response.data.data.data;
          let file = value.slice(2, -1);
          base64ToArrayBuffer(file, "csv", filename);
        } else {
          return;
        }
      }
    } catch (error) {
      toast.error("Download Error");
    }
  };

  const HandleBlockUnblock = (data) => {
    axios
      .post(
        BASE_URL + "/block-unblock-user",
        {
          user_id: data?.user_id,
          user_state_to: data?.is_blocked === false ? "BLOCK" : "UNBLOCK",
        },
        {
          headers: {
            "X-Auth-Token": temp,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          navigate("/");
        }
        if (response.data?.success === true) {
          toast.success(response.data?.message);
        }
        setrefresh(true);
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        toast.error(message);
      });
  };

  const viewHandler = (data) => {
    let array = [];
    for (let i = 0; i < data.rules.length; i++) {
      array.push(data.rules[i].rule);
    }
    sessionStorage.setItem("ruleArray", JSON.stringify(array));
    navigate("/createNewRuleset/createRuleset", {
      state: { type: "view", data },
    });
  };

  const editHandler = (data) => {
    let array = [];
    for (let i = 0; i < data.rules.length; i++) {
      array.push(data.rules[i].rule);
    }
    sessionStorage.setItem("ruleArray", JSON.stringify(array));
    navigate("/createNewRuleset/createRuleset", {
      state: { type: "edit", data },
    });
  };
  const handleFilterOpening = (e) => {
    setSearchFilter(!searchFilter);
    if (searchFilter) setFileName();
  };
  const handleDateFilter = (e) => {
    setDateDialog(!dateDialog);
    if (dateDialog) {
      handleDateFilterApply(e);
    }
  };

  const SearchFilterComponent = useCallback(() => {
    return (
      <>
        <div className="searchFilterForm">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            value={filename}
          />
        </div>
      </>
    );
  }, []);

  const handleDateFilterApply = (e) => {
    e.preventDefault();
    setDateDialog(false);
    if (pageButton === "taskProgress" || pageButton === "taskCompleted") {
      handleUserTasks(pageButton);
    }
    if (pageButton === "ruleset") {
      handleRulesetList({
        filename: filename,
        start_date: startDate,
        end_date: EndDate,
      });
    }
    if (pageButton === "reconciliated") {
      handleReconciliatedData({
        filename: filename,
        status: inputstatus,
        start_date: startDate,
        end_date: EndDate,
      });
    }
    if (filterName === "inHistory") {
      handleImportHistory();
    }
  };
  const DateFilterComponent = () => {
    return (
      <>
        <form className="dateFilterForm" onSubmit={handleDateFilterApply}>
          <input
            type="date"
            className="date-filter"
            placeholder="From"
            value={startDate}
            onChange={(e) => {
              e.preventDefault();
              setstartDate(e.target.value);
            }}
          />
          <input
            type="date"
            className="date-filter"
            min={startDate}
            placeholder="To"
            value={EndDate}
            onChange={(e) => {
              e.preventDefault();
              setEndDate(e.target.value);
            }}
          />
          <div style={{ display: "flex" }}>
            <button className="apply-button" type="submit">
              Apply
            </button>
            <button
              className="reset-button"
              type="reset"
              onClick={() => {
                setDate("");
                setstartDate("");
                setEndDate("");
              }}
            >
              <BiReset />
            </button>
          </div>
        </form>
      </>
    );
  };

  const Filter = ({ value }) => {
    if (pageButton === "reconciliated" && value === "Status") {
      return (
        <>
          <button
            onClick={() => {
              setfilterstatus(!filterstatus);
              if (filterstatus) setinputstatus({});
            }}
            className="filter-state-handler"
          >
            {filterstatus ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }

    if (value === "Ruleset Name" || value === "File Name") {
      return (
        <>
          <button
            onClick={handleFilterOpening}
            className="filter-state-handler"
          >
            {searchFilter ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
    if (
      value === "Update date" ||
      value === "Extraction Date" ||
      value === "Creation Date" ||
      value === "Upload date"
    ) {
      return (
        <>
          <button onClick={handleDateFilter} className="filter-state-handler">
            {dateDialog ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
  };
  const handleMultipleFiles = (files, type) => {
    if (type === "delete") {
      files.forEach((fileId) => {
        deleteReconciliationHandler(fileId);
      });

      setTimeout(() => {
        setopenDeleteModal({
          open: false,
          data: undefined,
        });
      }, 2000);
    } else {
      let filesTobeDownloaded = [];
      for (let i = 0; i < files.length; i++) {
        let file = userTaskInputData.filter(
          (file) => file.file_id === files[i]
        );
        filesTobeDownloaded.push(file[0]);
      }
      filesTobeDownloaded.forEach((file) => {
        reconciliatedDownloadHandler(file.file_id, file.filename);
      });
    }
  };

  const handleUserDelete = async (e, data) => {
    await axios
      .post(
        BASE_URL + "/delete-user",
        {
          user_id: data,
        },
        {
          headers: {
            "X-Auth-Token": temp,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) toast.success(response.data.message);
        if (!response.data.success) toast.error(response.data.message);
        getAllUser();
        setDeleteUser({
          open: false,
          data: "",
        });
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        toast.error(message);
      });
  };

  const HandleMergeExcel = async () => {
    axios
      .post(
        BASE_URL + "/merge-excel",
        {
          file_ids: [...selectedItems],
          page: pageButton,
        },
        {
          headers: {
            "X-Auth-Token": temp,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let base64String = response?.data?.data?.file;
        base64String = String(base64String).slice(2, -1);
        base64ToArrayBuffer(base64String, "xlsx", "myfile");
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        toast.error(message);
      });
  };

  function compareNameAscending(a, b) {
    // Use toUpperCase() to ignore character casing

    const bandA =
      pageButton === "admin_setting"
        ? a?.first_name?.toUpperCase()
        : pageButton === "ruleset"
        ? a?.name?.toUpperCase()
        : a?.filename?.toUpperCase();
    const bandB =
      pageButton === "admin_setting"
        ? b?.first_name?.toUpperCase()
        : pageButton === "ruleset"
        ? b?.name?.toUpperCase()
        : b?.filename?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  function compareNameDescending(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA =
      pageButton === "admin_setting"
        ? a?.first_name?.toUpperCase()
        : pageButton === "ruleset"
        ? a?.name?.toUpperCase()
        : a?.filename?.toUpperCase();
    const bandB =
      pageButton === "admin_setting"
        ? b?.first_name?.toUpperCase()
        : pageButton === "ruleset"
        ? b?.name?.toUpperCase()
        : b?.filename?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }
  const message = (
    <>
      <ul>
        <li>The merging order is based on the file selection sequence.</li>
        <li>
          The error message initially identifies the file names, followed by the
          sheet number.
        </li>
      </ul>
    </>
  );

  return (
    <>
      <div className="task-container">
        <div className="task-header-container">
          <div
            className="task-heading"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            {pageName}
            {pageName == "Tasks Completed" ? (
              <TooltipComponent message={message} position="bottom" />
            ) : (
              ""
            )}
          </div>
          <div className="task-heading-button-container">
            {((pageButton) => {
              switch (pageButton) {
                case "ruleset":
                  return (
                    <>
                      <Button
                        variant="filled"
                        sx={{
                          color: "white",
                          background: "#50DFB2",
                          border: "1px solid #50DFB2",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "white",
                            background: "#50DFB2",
                            border: "1px solid #50DFB2",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/createNewRuleset/choosetemplate");
                        }}
                      >
                        Create Ruleset{" "}
                      </Button>
                    </>
                  );

                case "admin_setting":
                  return (
                    <>
                      <div
                        style={{
                          width: "450px",
                          position: "relative",
                          display: "inline-flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <DashboardSearchBar
                          onChange={(e) => {
                            setinputtyped(e.target.value);
                          }}
                          value={inputtyped}
                          placeholder="Start Searching here..."
                        />
                        <Tooltip title="Add New User" placement="bottom">
                          <Button
                            className="add-user-button"
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              setAddUser(true);
                            }}
                          >
                            <BiSolidUserPlus />
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  );
                default:
                  return (
                    <>
                      {(pageButton === "reconciliated" ||
                        pageButton === "taskCompleted") && (
                        <Button
                          variant="outlined"
                          sx={{
                            color: "#50DFB2",
                            border: "1px solid #50DFB2",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            "&:hover": {
                              border: "1px solid #50DFB2",
                            },
                          }}
                          disabled={!(selectedItems.length > 1)}
                          onClick={HandleMergeExcel}
                        >
                          <HiDownload />
                          Merge Files
                        </Button>
                      )}

                      <Button
                        variant="outlined"
                        sx={{
                          color: "#50DFB2",
                          border: "1px solid #50DFB2",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            border: "1px solid #50DFB2",
                          },
                        }}
                        disabled={!(selectedItems.length > 0)}
                        onClick={() => {
                          pageButton === "reconciliated"
                            ? handleMultipleFiles(selectedItems, "download")
                            : downloadHandler(selectedItems);
                        }}
                      >
                        <HiDownload />
                        Download{" "}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          borderRadius: "10px",
                          textTransform: "capitalize",
                        }}
                        disabled={!(selectedItems.length > 0)}
                        onClick={() => {
                          setopenDeleteModal({
                            open: true,
                            data: selectedItems,
                            isMultiple: true,
                            function:
                              pageButton === "reconciliated"
                                ? handleMultipleFiles
                                : deleteHandler,
                          });
                          // pageButton === "reconciliated"
                          //   ? handleMultipleFiles(selectedItems, "delete")
                          //   : deleteHandler(selectedItems);
                        }}
                      >
                        <RiDeleteBinLine />
                        Delete{" "}
                      </Button>
                      {reconciliation && (
                        <>
                          <Button
                            variant="filled"
                            sx={{
                              color: "white",
                              background: "#50DFB2",
                              border: "1px solid #50DFB2",
                              borderRadius: "10px",
                              textTransform: "capitalize",
                              "&:hover": {
                                color: "white",
                                background: "#50DFB2",
                                border: "1px solid #50DFB2",
                              },
                            }}
                            disabled={!(selectedItems.length > 0)}
                            onClick={(e) => {
                              setEnableApplyReconciliation(true);
                            }}
                          >
                            Apply Ruleset{" "}
                          </Button>
                        </>
                      )}
                    </>
                  );
              }
            })(pageButton)}
          </div>
        </div>

        <div
          className="table-body-container"
          style={{
            position: "relative",
          }}
        >
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {allSelectEnable && (
                  <TableCell
                    align="center"
                    sx={{
                      padding: "0",
                      backgroundColor: "#EEF3F6",
                      border: "none",
                    }}
                  >
                    <MenuItem>
                      <Checkbox
                        size="small"
                        style={{
                          margin: "0 auto",
                          color: "#50DFB2",
                          padding: "0px",
                        }}
                        onChange={allCheckboxHandler}
                        checked={
                          userTaskInputData?.length > 0 &&
                          userTaskInputData?.length === selectedItems.length
                        }
                      ></Checkbox>
                    </MenuItem>
                  </TableCell>
                )}
                {tableHeading &&
                  tableHeading.map((value) => {
                    return (
                      <>
                        <TableCell
                          sx={{
                            font: "normal normal 700 15px/21px Segoe UI",
                            backgroundColor: "#EEF3F6",
                            border: "none",
                            textAlign: value === "Action" ? "center" : "start",
                            paddingLeft: value === "Status" ? "35px" : "",
                          }}
                        >
                          <span>
                            {(value === "File Name" ||
                              value === "Ruleset Name" ||
                              value === "First Name") && (
                              <button
                                style={{
                                  marginLeft: "0px",
                                }}
                                className="filter-state-handler"
                                onClick={() => {
                                  setsortByName(!sortByName);

                                  if (sortByName) {
                                    userTaskInputData.sort(
                                      compareNameAscending
                                    );
                                  }

                                  if (!sortByName) {
                                    userTaskInputData.sort(
                                      compareNameDescending
                                    );
                                  }
                                }}
                              >
                                {sortByName ? <FaSortDown /> : <FaSortUp />}
                              </button>
                            )}

                            {(value === "Update date" ||
                              value === "Extraction Date" ||
                              value === "Creation Date" ||
                              value === "Upload date") && (
                              <button
                                style={{
                                  marginLeft: "0px",
                                }}
                                className="filter-state-handler"
                                onClick={() => {
                                  setsortByDate(!sortByDate);

                                  if (sortByDate) {
                                    userTaskInputData.sort((a, b) => {
                                      return (
                                        new Date(a?.created_at) -
                                        new Date(b?.created_at)
                                      );
                                    });
                                  }

                                  if (!sortByDate) {
                                    userTaskInputData.sort((a, b) => {
                                      return (
                                        new Date(b?.created_at) -
                                        new Date(a?.created_at)
                                      );
                                    });
                                  }
                                }}
                              >
                                {sortByDate ? <FaSortDown /> : <FaSortUp />}
                              </button>
                            )}

                            {value}
                            {<Filter value={value} />}
                          </span>

                          {pageButton === "reconciliated" &&
                            value === "Status" &&
                            filterstatus && (
                              <StatusSelection
                                setSelectedData={setSelectedItems}
                                setinputstatus={setinputstatus}
                              />
                            )}

                          {/* Name Filter */}
                          {(value === "File Name" ||
                            value === "Ruleset Name") &&
                            searchFilter && <SearchFilterComponent />}

                          {/* Date Filter */}
                          {(value === "Update date" ||
                            value === "Extraction Date" ||
                            value === "Creation Date" ||
                            value === "Upload date") &&
                            dateDialog && <DateFilterComponent />}
                        </TableCell>
                      </>
                    );
                  })}
              </TableRow>
            </TableHead>

            {isloading ? (
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress> </CircularProgress>
              </div>
            ) : (
              <TableBody>
                {userTaskInputData &&
                  userTaskInputData?.map((inputData, index) => {
                    if (inputData.action) {
                      action.canEdit = inputData.action.can_edit;
                      action.canView = inputData.action.can_view;
                      action.canDelete = inputData.action.can_delete;
                    }
                    return (
                      <TableRow>
                        {allSelectEnable && (
                          <TableCell align="center" sx={{ padding: "0" }}>
                            <Checkbox
                              size="small"
                              style={{ margin: "0 auto", color: "#50DFB2" }}
                              onChange={checkboxHandler}
                              value={
                                pageButton === "admin_setting"
                                  ? inputData?.user_id
                                  : inputData.file_id
                              }
                              checked={selectedItems.includes(
                                pageButton === "admin_setting"
                                  ? inputData?.user_id
                                  : inputData.file_id
                              )}
                            />
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            font: "normal normal 500 13px/21px Segoe UI",
                            margin: "0 auto",
                            maxWidth: "300px",
                            minWidth: "180px",
                          }}
                        >
                          {pageButton === "ruleset"
                            ? inputData.name
                            : pageButton === "admin_setting"
                            ? inputData?.first_name
                            : inputData.filename}
                        </TableCell>
                        {pageButton === "reconciliated" ? (
                          <TableCell
                            sx={{
                              font: "normal normal 500 12px Segoe UI",
                              margin: "0 auto",
                              marginLeft: "20px",
                            }}
                          >
                            {inputData.file_type === "pdf" ? (
                              <>
                                <BsFiletypePdf
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                                PDF
                              </>
                            ) : inputData.file_type === "xls" ? (
                              <>
                                <BsFiletypeXls
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                                XLS
                              </>
                            ) : inputData.file_type === "CSV" ||
                              inputData.file_type === "csv" ? (
                              <>
                                <BsFiletypeCsv
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                                CSV
                              </>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{
                              font: "normal normal 500 13px/21px Segoe UI",
                              textAlign: "left",
                              paddingLeft: "20px",
                            }}
                          >
                            {pageButton === "ruleset" ? (
                              inputData.created_by
                            ) : pageButton === "admin_setting" ? (
                              inputData?.last_name
                            ) : type === "pdf" ? (
                              <>
                                <BsFiletypePdf
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                                PDF
                              </>
                            ) : (
                              <>
                                <BsFiletypeXls
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "5px",
                                    marginTop: "5px",
                                  }}
                                />
                                XLS
                              </>
                            )}
                          </TableCell>
                        )}
                        {pageButton === "reconciliated" && (
                          <TableCell
                            sx={{
                              font: "normal normal 500 12px Segoe UI",
                              margin: "0 auto",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  statusBGColor[inputData.status],
                                color: statusColor[inputData.status],
                                height: "26px",
                                width: "90px",
                                borderRadius: "10px",
                                border: "2px solid",
                                textAlign: "center",
                                paddingTop: "2px",
                                textTransform: "capitalize",
                                borderColor: statusColor[inputData.status],
                              }}
                            >
                              {inputData.status}
                            </div>
                          </TableCell>
                        )}

                        <TableCell
                          sx={{
                            font: "normal normal 500 13px/21px Segoe UI",
                          }}
                        >
                          {pageButton === "admin_setting"
                            ? inputData?.usermail
                            : DateFormatter(inputData.created_at)}
                        </TableCell>
                        <TableCell className="table-action-column">
                          {pageButton === "admin_setting" && (
                            <>
                              <Button
                                sx={{
                                  borderRadius: "0% !important",
                                  width: "fit-content",
                                  padding: 0,
                                  minWidth: 0,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  HandleBlockUnblock(inputData);
                                }}
                              >
                                {inputData?.is_blocked ? (
                                  <Tooltip title="Blocked" placement="bottom">
                                    <IconButton>
                                      <BiBlock
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "red",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Unblocked" placement="bottom">
                                    <IconButton>
                                      <BiBlock
                                        style={{
                                          fontSize: "1.5rem",
                                          color: "green",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Button>
                              <Button
                                style={{
                                  color: "red",
                                  fontSize: "18px",
                                  width: "fit-content",
                                  height: "fit-content", // padding: "3px",
                                  borderRadius: "0% !important",
                                  minWidth: 0,
                                }}
                                onClick={(e) =>
                                  setDeleteUser({
                                    data: inputData?.user_id,
                                    open: true,
                                  })
                                }
                              >
                                <BiTrash />
                              </Button>
                            </>
                          )}

                          {action.canEdit && (
                            <button
                              style={{
                                color: "#0E0E4F",
                                border: "1px solid #0E0E4F",
                                marginBottom: "4px",
                              }}
                              onClick={() => editHandler(inputData)}
                            >
                              <BiEdit />
                            </button>
                          )}
                          {action.canView && (
                            <button
                              disabled={
                                inputData.status !== "completed" &&
                                pageButton === "reconciliated"
                                  ? true
                                  : false
                              }
                              style={{
                                color:
                                  pageButton === "reconciliated"
                                    ? "#0E0E4F"
                                    : "#50DFB2",
                                border:
                                  pageButton === "reconciliated"
                                    ? "1px solid #0E0E4F"
                                    : "1px solid #50DFB2",
                                marginBottom: "4px",
                              }}
                              onClick={() => {
                                pageButton === "reconciliated"
                                  ? reconciliatedViewResultHandler([
                                      inputData.file_id,
                                    ])
                                  : viewHandler(inputData);
                              }}
                            >
                              <AiOutlineEye />
                            </button>
                          )}
                          {action.canDownload && (
                            <button
                              disabled={
                                inputData.status !== "completed" &&
                                pageButton === "reconciliated"
                                  ? true
                                  : false
                              }
                              style={{
                                color: "#50DFB2",
                                border: "1px solid #50DFB2",
                                marginBottom: "4px",
                              }}
                              onClick={() => {
                                pageButton === "reconciliated"
                                  ? reconciliatedDownloadHandler(
                                      inputData.file_id,
                                      inputData.filename
                                    )
                                  : downloadHandler([inputData.file_id]);
                              }}
                            >
                              <HiDownload />
                            </button>
                          )}

                          {action.canDelete && (
                            <button
                              style={{
                                color: "#E11D1D",
                                border: "1px solid #E11D1D",
                                marginBottom: "4px",
                              }}
                              onClick={() => {
                                setopenDeleteModal({
                                  open: true,
                                  data: {
                                    payload:
                                      pageButton === "ruleset"
                                        ? inputData.id
                                        : pageButton === "reconciliated"
                                        ? inputData.file_id
                                        : [inputData.file_id],
                                  },
                                });
                              }}
                            >
                              <RiDeleteBinLine />
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </div>
      </div>

      {openDeleteModal && (
        <DeleteConfirmation
          isopen={openDeleteModal}
          setIsopen={setopenDeleteModal}
          detailsProps={{
            url: "/",
            title: "This action will delete the file",
            heading:
              pageButton === "taskCompleted"
                ? "Do you want to delete the file permanently ?"
                : pageButton === "importhistory"
                ? "Do you want to delete the file from the logs ?"
                : undefined,
          }}
          setSelectedItems={setSelectedItems}
          page={pageButton}
          handleUserTasks={handleUserTasks}
          type={type}
          handleReconciliatedData={handleReconciliatedData}
          handleImportHistory={handleImportHistory}
        />
      )}
      {deleteUser.open && (
        <DeleteUserModal
          handleUserDelete={handleUserDelete}
          deleteUser={deleteUser}
          setDeleteUser={setDeleteUser}
        />
      )}
      {addUser && (
        <AddUser
          addUser={addUser}
          getAllUser={getAllUser}
          setAddUser={setAddUser}
        />
      )}
    </>
  );
}

export default TableGrid;
