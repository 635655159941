import React from "react";
import { Dialog, DialogContent, Box } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import {
  StyledButton,
  StyledHeading,
} from "../../assets/styles/InputOutputFolder";
import { BsTrash3 } from "react-icons/bs";

function DeleteStarterModal({ open, setOpen, HandledeleteStarter }) {
  const handleCloseManual = () => {
    setOpen({
      open: false,
      data: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    HandledeleteStarter(open.data);
  };
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "1rem",
            minWidth: "300px",
            width: "400px",
            minHeight: "179px",
          },
        }}
        onClose={handleCloseManual}
        open={open.open}
      >
        <Box
          component={"form"}
          method="POST"
          onSubmit={handleSubmit}
          style={{
            background: "white",
            overflow: "unset",
            position: "relative",
          }}
        >
          <div
            className="cancle-btn"
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "500px",
              background: "#AC2734 0% 0% no-repeat padding-box",
              position: "absolute",
              top: "-30px",
              right: "-30px",
              cursor: "pointer",
            }}
            onClick={handleCloseManual}
          >
            <RxCross2
              style={{
                fontSize: "1.2rem",
                color: "white",
              }}
            />
          </div>

          <DialogContent disablePadding>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #AC2635",
                  margin: "0 auto",
                }}
              >
                <BsTrash3
                  style={{
                    fontSize: "1.5rem",
                    color: "#AC2635",
                  }}
                />
              </div>

              <StyledHeading
                style={{
                  textAlign: "center",
                  fontSize: "1.1rem",
                  margin: "0",
                  marginTop: "0.7rem",
                }}
              >
                Are you sure you want to delete starter?
              </StyledHeading>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <StyledButton
                  style={{
                    background: "#50dfb2",
                    color: "white",
                  }}
                  type="submit"
                >
                  Confirm
                </StyledButton>
                <StyledButton
                  style={{
                    marginLeft: "1rem",
                    border: "2px solid #AC2635",
                    background: "transparent",
                    color: "#AC2635",
                  }}
                  onClick={handleCloseManual}
                >
                  Cancel
                </StyledButton>
              </div>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteStarterModal;
