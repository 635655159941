import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import EditReconcillationResult from "./EditReconcillationResult";

function ReconciliationResultTable({
  data,
  finalColor,
  Edit,
  editableData,
  seteditableData,
  setreconData,
  isView,
}) {
  let horizontalHeader = data[0];

  const [openEditModal, setopenEditModal] = useState({
    open: false,
    row: undefined,
    column: undefined,
  });

  const StyledTableCell = styled(TableCell)`
    border: 1px solid #b8b8b8;
    minwidth: 50px;
    color: black;
  `;

  const HandleChange = (e, index, i) => {
    e.preventDefault();
    const newData = [...editableData];
    newData[index][i] = e.target.value;
    seteditableData(newData);
  };

  return (
    <>
      <TableContainer
        style={{
          height: isView ? "auto" : "calc(100% - 50px)",
          border: "1px solid #b8b8b8",
        }}
      >
        <Table
          sx={{ minWidth: 700 }}
          style={{ borderCollapse: "separate" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow
              style={{
                border: "1.3px solid #1a1a1a7d",
              }}
            >
              <StyledTableCell></StyledTableCell>
              {horizontalHeader?.map((value) => {
                return <StyledTableCell>{value}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((value, index) => {
              if (index === 0) return;
              return (
                <TableRow
                  style={
                    isView
                      ? {
                          background: "white",
                        }
                      : finalColor[index - 1]
                      ? { background: "#c3f7e7" }
                      : { background: "#efd3d3" }
                  }
                >
                  <StyledTableCell component="th" scope="row">
                    {index}
                  </StyledTableCell>
                  {value?.map((innerValue, i) => (
                    <React.Fragment key={`input-${index}-${i}`}>
                      <StyledTableCell
                        key={`input-${index}-${i}`}
                        sx={{
                          cursor: Edit ? "pointer" : "auto",
                        }}
                        onClick={() => {
                          if (!Edit) {
                            return;
                          }

                          setopenEditModal({
                            open: true,
                            row: index,
                            column: i,
                          });
                        }}
                      >
                        {String(innerValue)}
                      </StyledTableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {openEditModal.open && (
        <EditReconcillationResult
          editableData={editableData}
          seteditableData={seteditableData}
          isopen={openEditModal}
          setIsopen={setopenEditModal}
          setreconData={setreconData}
        />
      )}
    </>
  );
}

export default ReconciliationResultTable;
