import { CircularProgress } from "@mui/material";
import React from "react";

function CircularLoader() {
  return (
    <div className="loader-container-2">
      <CircularProgress style={{ color: "#50dfb2" }} />
    </div>
  );
}

export default CircularLoader;
