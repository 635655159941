import React, { useContext } from "react";
import { Dialog, DialogContent, Box } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledHeading,
} from "../../assets/styles/InputOutputFolder";
import { BsTrash3 } from "react-icons/bs";
import { UserDataContext } from "../../AppRouter";
import { Navigate } from "react-router-dom";

function DeleteConfirmation({
  isopen,
  setIsopen,
  detailsProps,
  handleUserTasks,
  page,
  type,
  handleReconciliatedData,
  handleImportHistory,
  setrefresh,
  setSelectedItems,
}) {
  const temp = sessionStorage.getItem("token");
  const { handleRulesetList } = useContext(UserDataContext);

  const handleCloseManual = () => {
    setIsopen({
      open: false,
      data: undefined,
    });
  };

  const HandleCreateFolder = async (e) => {
    e.preventDefault();
    if (isopen.isMultiple === true) {
      isopen.function(isopen.data, "delete");
      return;
    } else if (page === "Output Folder" || page === "Input Folder") {
      await axios
        .post(
          BASE_URL + "/delete-folder",
          {
            ...isopen.data,
          },
          {
            headers: {
              "X-Auth-Token": temp,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.error) {
            let message = response.data.message;
            toast.error(message);
            if (message === "Invalid Access Token.. Please login") {
              return () => {};
            }
          }

          if (response.data.success) {
            toast.success(response.data?.message);
            setSelectedItems([]);
          }

          handleUserTasks(page);
          handleCloseManual();
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err.response.data.message);
        });
    } else if (page === "ruleset") {
      try {
        let response = await axios.delete(
          BASE_URL + `/rule-set/${isopen?.data?.payload}`,
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          if (response.status === 200) {
            toast.success(response.data.message);
            handleRulesetList();
            handleCloseManual();
          } else {
            return;
          }
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    } else if (page === "ruleset_choose_template") {
      try {
        let response = await axios.delete(
          BASE_URL + `/ruleset-template/${isopen.data}`,
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          if (response.status === 200) {
            toast.success(response?.data?.message);
            setrefresh(true);
            // handleReconciliatedData();
            handleCloseManual();
          } else {
            return;
          }
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    } else if (page === "reconciliated") {
      try {
        let response = await axios.delete(
          BASE_URL + `/reconciliated/${isopen?.data?.payload}`,
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          if (response.data.success) {
            toast.success(response?.data?.message);
            handleReconciliatedData();
            handleCloseManual();
          } else {
            return;
          }
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    } else {
      if (page === "importhistory") handleImportHistory();
      else handleUserTasks(page);
      try {
        let response = await axios.post(
          BASE_URL + "/remove-s3-file",
          {
            file_id: isopen?.data?.payload,
            file_type: String(type).toUpperCase(),
          },
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          toast.success("Successfully Deleted...!");
          if (response.status === 200) {
            if (page === "importhistory") handleImportHistory();
            else handleUserTasks(page);
          } else {
            return;
          }

          handleCloseManual();
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "0rem 1rem",
            width: "420px",
            minHeight: "279px",
          },
        }}
        sx={{ zIndex: 1000 }}
        onClose={handleCloseManual}
        aria-labelledby="customized-dialog-title"
        open={isopen.open}
      >
        <Box
          component={"form"}
          method="POST"
          onSubmit={HandleCreateFolder}
          style={{
            background: "white",
            overflow: "unset",
            position: "relative",
          }}
        >
          <div
            className="cancle-btn"
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "500px",
              background: "#AC2734 0% 0% no-repeat padding-box",
              position: "absolute",
              top: "-10px",
              right: "-30px",
              cursor: "pointer",
            }}
            onClick={handleCloseManual}
          >
            <RxCross2
              style={{
                fontSize: "1.2rem",
                color: "white",
              }}
            />
          </div>

          <DialogContent disablePadding>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #AC2635",
                  margin: "0 auto",
                }}
              >
                <BsTrash3
                  style={{
                    fontSize: "2rem",
                    color: "#AC2635",
                  }}
                />
              </div>

              <StyledHeading
                style={{
                  textAlign: "center",
                  fontSize: "1.3rem",
                  margin: "0",
                  marginTop: "0.7rem",
                }}
              >
                {detailsProps.heading
                  ? detailsProps?.heading
                  : "Are you sure you want to delete?"}
              </StyledHeading>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                {detailsProps?.title}
              </p>

              <div
                style={{
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <StyledButton
                  style={{
                    background: "#50dfb2",
                    color: "white",
                  }}
                  type="submit"
                >
                  Confirm
                </StyledButton>
                <StyledButton
                  style={{
                    marginLeft: "1rem",
                    border: "2px solid #AC2635",
                    background: "transparent",
                    color: "#AC2635",
                  }}
                  onClick={handleCloseManual}
                >
                  Cancel
                </StyledButton>
              </div>
            </div>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteConfirmation;
