import React, { useContext, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { IoMdLogOut } from "react-icons/io";
import { GoBellSlash } from "react-icons/go";
import { LuBellRing } from "react-icons/lu";
import { MdOutlineLockReset } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../../utils";
import { DeleteAllFiles } from "../../utils/Services/api.pdfmasking";
import { UserDataContext } from "../../AppRouter";

function LogoutDialog({
  dialogOpen,
  setDialogOpen,
  anchorEl,
  notification,
  setNotification,
}) {
  const navigate = useNavigate();
  let temp = sessionStorage.getItem("token");
  const { setSelectedFiles, setUploadedFiles } = useContext(UserDataContext);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();
    setDialogOpen(false);
  };
  const handleLogout = async () => {
    await DeleteAllFiles().finally(() => {
      sessionStorage.clear();
    });
    setSelectedFiles([]);
    setUploadedFiles([]);
    // window.location.href = "/";
    navigate("/", { replace: true });
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setDialogOpen(false);
    setChangePasswordDialog(true);
  };

  const handleNotification = async () => {
    try {
      let response = await axios.put(
        BASE_URL + "/toggle-notification",
        {},
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (response?.data.blocked) {
        // toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.data.success) {
          setNotification(response.data.data.notification);
        } else {
          return;
        }
      }
    } catch (error) {
      // toast.error(error.response.data.message);
      console.log("Something went wrong");
    }
  };
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={dialogOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuList>
          {temp && decryptStorage(sessionStorage.getItem("role")) === "User" ? (
            <MenuItem onClick={handleNotification}>
              <ListItemIcon>
                {" "}
                {notification ? (
                  <LuBellRing style={{ color: "green" }} />
                ) : (
                  <GoBellSlash style={{ color: "red" }} />
                )}
              </ListItemIcon>
              <ListItemText>
                Notification {notification ? "Enabled" : "Disabled"}
              </ListItemText>
            </MenuItem>
          ) : (
            ""
          )}
          <MenuItem onClick={handleChangePassword}>
            <ListItemIcon>
              {" "}
              <MdOutlineLockReset />
            </ListItemIcon>
            <ListItemText>Change Password</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              {" "}
              <IoMdLogOut />{" "}
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <ChangePassword
        changePasswordDialog={changePasswordDialog}
        setChangePasswordDialog={setChangePasswordDialog}
      />
    </>
  );
}

export default LogoutDialog;
