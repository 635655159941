import { useEffect, useState } from "react";
import NoData from "./NoData";
import { Flex } from "../../assets/styles/Chatbot";
import PDFCarousel from "./PDFCarousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

const getBinaryURL = (file) => {
  const binaryString = atob(file.pdf_bin);

  const byteArray = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/pdf" });

  const url = URL.createObjectURL(blob);
  return {
    binary_url: url,
    file_id: file.pdf_file_id,
    file_name: file.pdf_file_name,
  };
};

function PdfViewer({ file }) {
  const [url, setUrl] = useState(() => {
    return [];
  });

  useEffect(() => {
    if (file.length > 0) {
      let urls = [];
      for (let i = 0; i < file.length; i++) {
        urls.push(getBinaryURL(file[i]));
      }

      setUrl(urls);
    }
  }, [file]);

  return (
    <>
      <Flex
        className="pdf-file-viewer"
        height="100%"
        width="100%"
        direction="column"
      >
        {file.length === 0 ? (
          <NoData />
        ) : (
          <>
            {url.length > 0 && <PDFCarousel key={url.join(",")} files={url} />}
            {/* <embed
              src={url}
              type="application/pdf"
              width="100%"
              height="100%"
              style={{
                backgroundColor: "white",
              }}
            /> */}

            {/* <Carousel dynamicHeight infiniteLoop showThumbs={false}>
              {url.map((sFile) => {
                return (
                  <div
                    style={{
                      height: "100%",
                    }}
                    key={sFile.file_name}
                  >
                    <embed
                      src={sFile.binary_url}
                      type="application/pdf"
                      width="100%"
                      height="100%"
                      style={{
                        backgroundColor: "white",
                      }}
                    />{" "}
                    <p className="legend">{sFile.file_name}</p>
                  </div>
                );
              })}
            </Carousel> */}
          </>
        )}
      </Flex>
    </>
  );
}

export default PdfViewer;
