import React from "react";

function LoadingStarter() {
  return (
    <div className="honeycomb">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingStarter;
