import React, { useContext} from "react";
import UploadImage from "../../assets/images/uploadImage.svg";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "../../AppRouter";

/**
 * Description placeholder
 * @date 10/4/2023 - 11:17:54 PM
 *
 * @returns {*}
 */
function UploadCSV() {
  const navigate = useNavigate();

  const { setUploadedCsv } = useContext(UserDataContext);

  function handleChange(e) {
    sessionStorage.removeItem("numArray")
    let data;
    if (!(e.target && e.target.files && e.target.files[0])) {
      return;
    }
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: false,
        complete: (results) => {
          data = results.data.map((value) => {
            return value.map((innerItem) => {
              return { value: innerItem };
            });
          });
          setUploadedCsv(data);
          sessionStorage.setItem("uploadCSVpayload", JSON.stringify(data));
          navigate("/create-custom-csv/uploaded-csv");
        },
      });
    }
  }

  return (
    <form className="upload-csv-container">
      <img src={UploadImage} alt="uploadedImage" />
      <p className="upload-csv-labeling">
        Upload CSV file <span style={{ color: "#100101" }}>here</span>{" "}
      </p>
      <p id="upload-csv-tag-line">Upload CSV to create custom template</p>
      <label htmlFor="upload-csv-input" id="upload-csv-input-label"></label>
      <input
        type="file"
        id="upload-csv-input"
        accept=".csv"
        name="file"
        onChange={handleChange}
        hidden
      />
    </form>
  );
}

export default UploadCSV;
