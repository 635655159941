import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { UserDataContext } from "../AppRouter";
import { AiOutlineEye } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

function ApplyReconciliation() {
  const applyButtonStyle = {
    color: "white",
    background: "#50DFB2",
    border: "1px solid #50DFB2",
    borderRadius: "10px",
    textTransform: "capitalize",
    "&:hover": {
      color: "white",
      background: "#50DFB2",
      border: "1px solid #50DFB2",
    },
  };

  const applyButtonDisableStyle = {
    color: "black",
    backgroundColor: "#E4E8EB",
    textTransform: "capitalize",
  };

  const { ruleSetData, sidebarView, handleRulesetList } =
    useContext(UserDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedData = location.state;
  const headings = ["Ruleset Name", "Action"];
  const temp = sessionStorage.getItem("token");
  const [applyEnable, setApplyEnable] = useState(false);
  const [selectedRule, setSelectedRule] = useState("");

  const handleRadioChange = (e) => {
    setSelectedRule(e.target.value);
    setApplyEnable(true);
  };

  const handleStartReconciliation = async () => {
    let files = selectedData.map((item) => {
      return item.id;
    });
    try {
      let response = await axios.post(
        BASE_URL + "/rule/apply",
        {
          files: files,
          ruleset_id: parseInt(selectedRule),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        navigate("/reconciliated");
      }
    } catch (error) {
      let message = error.data?.data?.message;
      toast.error(message);
    }
  };

  useEffect(() => {
    handleRulesetList();
  }, []);

  if (!sessionStorage.getItem("token")) {
    return <Navigate to={"/"}></Navigate>;
  }
  const handleView = (e, data) => {
    e.preventDefault();
    let array = [];
    for (let i = 0; i < data.rules.length; i++) {
      array.push(data.rules[i].rule);
    }
    sessionStorage.setItem("ruleArray", JSON.stringify(array));
    navigate("/createNewRuleset/createRuleset", {
      state: { type: "view", data },
    });
  };

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <div className="task-container">
            <div className="task-header-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <MdOutlineKeyboardBackspace
                    style={{
                      height: "35px",
                      width: "35px",
                      backgroundColor: "#DBDFE2",
                      borderRadius: "18px",
                      padding: "4px",
                      marginRight: "10px",
                      pointerEvents: "none",
                    }}
                  />
                </button>
                <div className="task-heading">Apply Reconciliation</div>
              </div>

              <div className="task-heading-button-container">
                <>
                  <Button
                    variant="filled"
                    disabled={!applyEnable}
                    style={
                      applyEnable ? applyButtonStyle : applyButtonDisableStyle
                    }
                    onClick={(e) => {
                      handleStartReconciliation();
                    }}
                  >
                    Start Reconciliation{" "}
                  </Button>
                </>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div className="apply-recon-table-body-container">
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          font: "normal normal 700 15px/21px Segoe UI",
                          backgroundColor: "#EEF3F6",
                          border: "none",
                          padding: "10px",
                          textAlign: "start",
                        }}
                      >
                        Selected file to reconcile
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedData?.map((data) => {
                      return (
                        <TableRow
                          style={{ display: "flex" }}
                          key={Math.random()}
                        >
                          <TableCell align="start" sx={{ padding: "0" }}>
                            <Checkbox
                              size="medium"
                              disabled
                              checked
                              style={{
                                margin: "0 auto",
                                color: "#88A99F",
                                padding: "11px",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="start"
                            sx={{
                              font: "normal normal 500 15px Segoe UI",
                              marginLeft: "0 auto",
                              width: "98%",
                              paddingTop: "13px",
                              textTransform: "capitalize",
                            }}
                          >
                            {data.filename}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="apply-recon-table-body-container">
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      {headings.map((heading) => {
                        return (
                          <TableCell
                            sx={{
                              font: "normal normal 700 15px/21px Segoe UI",
                              backgroundColor: "#EEF3F6",
                              borderBottom: "none",
                              padding: "10px",
                              textAlign:
                                heading === "Action" ? "center" : "start",
                            }}
                          >
                            {heading}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ruleSetData?.map((data) => {
                      return (
                        <TableRow>
                          <TableCell
                            align="start"
                            sx={{
                              padding: "0",
                            }}
                          >
                            <RadioGroup
                              value={selectedRule}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value={data.id}
                                control={
                                  <Radio
                                    size="medium"
                                    style={{
                                      marginLeft: "15px",
                                      color: "#50DFB2",
                                    }}
                                  />
                                }
                                label={
                                  <span
                                    style={{
                                      font: "normal normal 500 15px Segoe UI",
                                      marginLeft: "0 auto",
                                      maxWidth: "300px",
                                      minWidth: "180px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data.name}
                                  </span>
                                }
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell className="table-action-column">
                            {data.action.can_view && (
                              <button
                                style={{
                                  color: "#50DFB2",
                                  border: "1px solid #50DFB2",
                                  marginBottom: "4px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                onClick={(e) => handleView(e, data)}
                              >
                                <AiOutlineEye />
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyReconciliation;
