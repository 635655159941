import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";

export default function InfoComponent({ ...data }) {
  let { infoViewer, setInfoViewer } = data;

  const handleClose = () => {
    setInfoViewer({ status: false, data: {} });
  };
  return (
    <Dialog
      open={infoViewer.status}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "1rem",
          minWidth: "370px",
          width: "70vw",
          minHeight: "209px",
          zIndex: "9999999999999",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            zIndex: "9999999999999",
          }}
        >
          Tools and Techniques for PDF Masking
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "left",
          padding: "10px",
          height: "60vh",
          overflow: "auto",
          boxShadow: "inset 0px 0px 10px grey",
          zIndex: "9999999999999",
        }}
      >
        <dl className="data-defination">
          <dt className="data-heading">
            <b>Using Area Selection :</b>
          </dt>
          <dd>
            Step 1: Upload your PDF file using the "Upload" button.
            <br />
            Step 2: Create a selection by dragging from the upper-left corner to
            the lower-right corner to define the region you want to mask.
            <br /> Step 3:Select to apply masking on the current page or all
            pages.
            <br /> Step 4: Click "Submit" to conceal the selected area.
          </dd>
          <dt className="data-heading">
            <b>Using Text/Prompt Masking:</b>
          </dt>
          <dd>
            Step 1: Upload your PDF file using the "Upload" button. <br />
            Step 2: Choose the "Text-based Masking" option.
            <br /> Step 3: Enter relevant keywords or phrases to locate the data
            you want to mask.
            <br /> Step 4: Click "Apply" to automatically identify and mask the
            specified content.
            <br />
            <b>Note:</b>
            <li>You can apply both ways on current or all pages.</li>
            <li>Only textual data will be masked not graphical data.</li>
          </dd>
          {/* Other Provided Feature */}
          <dt className="data-heading">
            <b>Download Masked PDF:</b>
          </dt>
          <dd>
            Once satisfied with the masked PDF, you can download the masked PDF
            along with the the selected area highlighted or without the selected
            area highlighted.
          </dd>
          <dt className="data-heading">
            <b>Extract Masked PDF:</b>
          </dt>
          <dd>
            This will submit your masked PDF for extraction to our extraction
            model.
          </dd>
          <dt className="data-heading">
            <b>Reset PDF:</b>
          </dt>
          <dd>
            In case of any unconvinced masking, you can always reset to your
            original PDF.
          </dd>
          <dt className="data-heading">
            <b>Exit:</b>
          </dt>
          <dd>For exiting from the PDF masking tool.</dd>
        </dl>
      </div>
    </Dialog>
  );
}
