import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";
import {
  PDFWithBoundingBox,
  PDFWithoutBoundingBox,
} from "../../utils/Services/api.pdfmasking";
import { toast } from "react-toastify";

export default function SelectDownloadMethod({ ...data }) {
  let { downloadMethod, setDownloadMethod } = data;

  const handleClose = () => {
    setDownloadMethod({ state: false, data: {} });
  };
  const downloadFileMethod = (downloadFile) => {
    const linkSource = `data:application/pdf;base64,${
      downloadFile?.split("'")[1]
    }`;
    console.log(linkSource);
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = "output.pdf";
    downloadLink.click();
  };
  const handleClick = async (type) => {
    let payload = downloadMethod.data.mask_file_id;
    if (type === "withBounding") {
      await PDFWithBoundingBox(payload)
        .then((res) => {
          if (res.data.success) {
            downloadFileMethod(res.data.data.data);
            toast.success(res.data.message);
          } else if (!res.data.success) {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handleClose();
        });
    } else {
      await PDFWithoutBoundingBox(payload)
        .then((res) => {
          if (res.data.success) {
            downloadFileMethod(res.data.data.data);
            toast.success(res.data.message);
          } else if (!res.data.success) {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          handleClose();
        });
    }
  };
  return (
    <Dialog
      open={downloadMethod.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "10px",
          minWidth: "370px",
          width: "470px",
          minHeight: "150px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            height: "50px",
          }}
        >
          Select Download PDF Type
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "center",
          height: "70px",
        }}
      >
        <StyledButton
          autoFocus
          style={{
            background: "#50dfb2",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClick("withBounding");
          }}
        >
          With Selection Box
        </StyledButton>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            handleClick("withoutBounding");
          }}
          style={{
            marginLeft: "10px",
            border: "2px solid #50dfb2",
            background: "transparent",
            color: "#50dfb2",
          }}
        >
          Without Selection Box
        </StyledButton>
      </div>
    </Dialog>
  );
}
