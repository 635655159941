import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { UserDataContext } from "../AppRouter";
import Header from "./Header";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/index";
import AdminTableGrid from "./helperComponents/AdminTableGrid";
import { Navigate, useNavigate } from "react-router-dom";

const InputFolder = () => {
  const temp = sessionStorage?.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (temp) {
      handleUserTasks();
    }
  }, []);

  const { userInputData, setUserInputData, setUserOutputData, sidebarView } =
    useContext(UserDataContext);

  const [userTaskInputData, setUserTaskInputData] = useState(userInputData);

  useEffect(() => {
    setUserTaskInputData(userInputData);
  }, [userInputData]);

  if (!temp || temp === undefined) {
    return <Navigate to={"/"}></Navigate>;
  }

  const handleUserTasks = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-io-folder-details", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          setUserInputData(response?.data?.data?.Input);
          setUserOutputData(response?.data?.data?.Output);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log("something went worng..!");
      // toast.error(message);
    }
  };

  const tableHeading = [
    "Folder Name",
    "Last Updated Date",
    "Created On",
    "Action",
  ];
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} `}>
        <Header />
        <div className="right-container-content">
          <AdminTableGrid
            tableHeading={tableHeading}
            userTaskInputData={userTaskInputData}
            pageName="Input Folder"
            heading="Input Folder"
            type="pdf"
          />
        </div>
      </div>
    </div>
  );
};

export default InputFolder;
