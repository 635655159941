import React, { useContext, useEffect } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { UserDataContext } from "../../AppRouter";
import { Navigate } from "react-router-dom";
import ProcessingTime from "../../assets/images/processingTime.svg";
import { useNavigate } from "react-router";

function ReconciliatedLoadingPage() {
  const navigate = useNavigate();

  const { sidebarView } = useContext(UserDataContext);

  const temp = sessionStorage.getItem("token");

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <div className="task-container">
            <div
              className="ruleset-empty-screen-container"
              style={{ height: "100%" }}
            >
              <div id="ruleset-empty-screen-container-image">
                <img src={ProcessingTime} alt="" style={{ fontSize: "30px" }} />
              </div>
              <div id="ruleset-empty-screen-container-content">
                <p style={{ color: "#FC8A2E" }}>Reconciliation in Progress</p>
                <p>
                  Once the reconciliation is completed we will update you via
                  email and notification.
                </p>
              </div>
              <div>
                <button
                  className="reconciliatied-back-to-dashboard"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Back To Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReconciliatedLoadingPage;
