import axios from "axios";
import { ReactAWSS3 } from "../FileUpload";
import { toast } from "react-toastify";
import { BASE_URL } from "../index";

export const UploadPDFForMasking = async (uuid) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.post(
      BASE_URL + "/masking/upload-S3-file",
      {
        uuid: uuid,
      },
      {
        headers: {
          "X-Auth-Token": token,
        },
      }
    );
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};
export const BoundingBoxCoord = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.post(BASE_URL + "/masking/mask-pdf", payload, {
      headers: {
        "X-Auth-Token": token,
      },
    });
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};
export const ApplyChanges = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.post(BASE_URL + "/masking/mask-pdf", payload, {
      headers: {
        "X-Auth-Token": token,
      },
    });
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const PDFWithBoundingBox = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.get(
      BASE_URL + "/masking/download-mask-pdf-borders",
      {
        headers: {
          "X-Auth-Token": token,
          "Content-Type": "application/json",
        },
        params: {
          mask_file_id: payload,
        },
      }
    );
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const PDFWithoutBoundingBox = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.get(BASE_URL + "/masking/download-mask-pdf", {
      headers: {
        "X-Auth-Token": token,
      },
      params: {
        mask_file_id: payload,
      },
    });
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const ResetFileChanges = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.post(
      BASE_URL + "/masking/reset-mask-pdf",
      {
        mask_file_id: payload,
      },
      {
        headers: {
          "X-Auth-Token": token,
        },
      }
    );
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const DeleteFileOnS3 = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.delete(BASE_URL + "/masking/delete-pdf", {
      headers: {
        "X-Auth-Token": token,
      },
      params: {
        mask_file_id: payload,
      },
    });
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const ExtractPDF = async (payload) => {
  const token = sessionStorage.getItem("token");
  try {
    const resp = await axios.post(
      BASE_URL + "/masking/extract-masked-file",
      {
        mask_file_id: payload,
      },
      {
        headers: {
          "X-Auth-Token": token,
        },
      }
    );
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};

export const DeleteAllFiles = async () => {
  const token = sessionStorage.getItem("token");
  sessionStorage.removeItem("uuid");
  try {
    const resp = await axios.delete(BASE_URL + "/masking/clean-masking-files", {
      headers: {
        "X-Auth-Token": token,
      },
    });
    return resp;
  } catch (err) {
    console.log("Something went Wrong!");
  }
};
