import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LuFileSpreadsheet } from "react-icons/lu";
import { MdFileDownload } from "react-icons/md";
import { RxExit } from "react-icons/rx";
import { VscDebugRestart } from "react-icons/vsc";
import { BsInputCursorText } from "react-icons/bs";
import { Document, Page, pdfjs } from "react-pdf";
import SelectDownloadMethod from "./SelectDownloadMethod";
import ExitConfirmation from "./ExitConfirmation";
import MaskingByText from "./MaskingByText";
import ExtractPdfConfirm from "./ExtractPDFConfirm";
import ResetChanges from "./ResetChanges";
import { IoInformationCircleOutline } from "react-icons/io5";
import InfoComponent from "./InfoCompnent";
import CircularLoader from "../ReusableComponents/CircularLoader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PDFViewer = React.forwardRef(
  (
    {
      url,
      page,
      setIsDrawing,
      startPosition,
      setStartPosition,
      endPosition,
      setEndPosition,
      coordinates,
      setCoordinates,
      pageNumber,
      setNumPages,
      setFile,
      sendBboxCoordinates,
      setLoading,
      file,
      setViewer,
      openApply,
      setOpenApply,
      cicularLoaderStatus,
      setCicularLoaderStatus,
    },
    ref
  ) => {
    const canvasRef = useRef();
    const drawingRef = ref;
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    const [downloadMethod, setDownloadMethod] = useState({
      state: false,
      data: {},
    });
    const [exitViewer, setExitViewer] = useState({
      state: false,
    });
    const [extractPdf, setExtractPdf] = useState({
      status: false,
    });
    const [resetAll, setResetAll] = useState({
      status: false,
    });
    const [textMask, setTextMask] = useState({
      status: false,
    });
    const [infoViewer, setInfoViewer] = useState({ status: true });

    useEffect(() => {
      async function handleReload(e) {
        e.preventDefault();
        return () => {
          e.returnValue = true;
        };
      }
      async function handleUnload(e) {
        e.preventDefault();
        sessionStorage.removeItem("uuid");
        return () => {
          e.returnValue = true;
        };
      }
      window.addEventListener("beforeunload", handleReload, { capture: true });
      window.addEventListener("unload", handleUnload, { capture: true });
      return window.removeEventListener("beforeunload", handleUnload);
    }, []);

    function handleMouseDown(e) {
      let canvas = drawingRef.current;
      canvas.style.width = "0px";
      canvas.style.height = "0px";
      setEndPosition({ x: 0, y: 0 });
      var mousePos = getMousePosition(canvasRef.current, e);
      let data = [coordinates];
      data = {
        x0: mousePos.x,
        y0: mousePos.y,
      };
      setCoordinates(data);
      setIsDrawing(true);
      setStartPosition({ x: mousePos.x, y: mousePos.y });
    }

    function handleMouseUp(e) {
      var mousePos = getMousePosition(canvasRef.current, e);
      let data = {
        x1: mousePos.x,
        y1: mousePos.y,
        x0: coordinates.x0,
        y0: coordinates.y0,
      };
      setCoordinates(data);
      setIsDrawing(false);
      setEndPosition({ x: mousePos.x, y: mousePos.y });
      sendBboxCoordinates(data);
    }

    function getMousePosition(canvas, e) {
      var boundary = canvas.getBoundingClientRect();
      return {
        x: e.clientX - boundary.left,
        y: e.clientY - boundary.top,
      };
    }
    const handleDownload = (e) => {
      e.preventDefault();
      let id = sessionStorage.getItem("mask_file_id");
      setDownloadMethod({
        state: true,
        data: {
          mask_file_id: id,
        },
      });
      console.log(id);
    };
    const handleExit = (e) => {
      e.preventDefault();
      setExitViewer({
        state: true,
      });
    };
    const handleExtract = (e) => {
      e.preventDefault();
      setExtractPdf({
        state: true,
      });
    };
    const handleResetAll = (e) => {
      e.preventDefault();
      setResetAll({
        state: true,
      });
    };
    const handleTextMasking = (e) => {
      e.preventDefault();
      setOpenApply({
        ...openApply,
        usestates: {
          page_number: pageNumber,
        },
      });
      console.log(openApply);
      setTextMask({
        state: true,
        data: {
          pageNumber: pageNumber,
        },
      });
    };
    const handleInfoContainer = (e) => {
      e.preventDefault();
      setInfoViewer({ status: true });
    };
    useEffect(() => {
      if (startPosition && endPosition) {
        const div = drawingRef.current;
        div.style.backgroundColor = "rgba(0,0,0,0.1)";
        const width = endPosition.x - startPosition.x;
        const height = endPosition.y - startPosition.y;
        div.style.border = "2px solid red";
        div.style.width = width + "px";
        div.style.height = height + "px";
        div.style.top = Math.min(startPosition.y) + "px";
        div.style.left = Math.min(startPosition.x) + "px";
      }
    }, [startPosition, endPosition]);

    return (
      <>
        <div
          ref={canvasRef}
          onMouseDown={page === "drag" ? handleMouseDown : null}
          onMouseUp={page === "drag" ? handleMouseUp : null}
          className="page-viewer-container"
        >
          <div>
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<CircularLoader />}
            >
              <Page
                scale={0.8}
                pageNumber={pageNumber}
                canvasRef={canvasRef}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
            <div style={{ position: "absolute" }} ref={drawingRef}></div>
          </div>
        </div>
        <div className="pdf-button-container">
          <Tooltip title="Info" placement="right">
            <IconButton
              className="pdf-action-buttons"
              onClick={handleInfoContainer}
            >
              <IoInformationCircleOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mask Through text" placement="right">
            <IconButton
              className="pdf-action-buttons"
              onClick={handleTextMasking}
            >
              <BsInputCursorText />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset All" placement="right">
            <IconButton className="pdf-action-buttons" onClick={handleResetAll}>
              <VscDebugRestart />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download" placement="right">
            <IconButton className="pdf-action-buttons" onClick={handleDownload}>
              <MdFileDownload />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Extract PDF"
            placement="right"
            onClick={handleExtract}
          >
            <IconButton className="pdf-action-buttons">
              <LuFileSpreadsheet />
            </IconButton>
          </Tooltip>
          <Tooltip title="Exit" placement="right" onClick={handleExit}>
            <IconButton className="pdf-action-buttons">
              <RxExit />
            </IconButton>
          </Tooltip>
        </div>
        <SelectDownloadMethod
          setFile={setFile}
          downloadMethod={downloadMethod}
          setDownloadMethod={setDownloadMethod}
        />
        <ExitConfirmation
          setViewer={setViewer}
          setLoading={setLoading}
          setFile={setFile}
          setExitViewer={setExitViewer}
          exitViewer={exitViewer}
          setCicularLoaderStatus={setCicularLoaderStatus}
        />
        <ExtractPdfConfirm
          setExtractPdf={setExtractPdf}
          extractPdf={extractPdf}
          setCicularLoaderStatus={setCicularLoaderStatus}
        />
        <ResetChanges
          setResetAll={setResetAll}
          setFile={setFile}
          file={file}
          resetAll={resetAll}
          setCicularLoaderStatus={setCicularLoaderStatus}
        />
        <MaskingByText
          setTextMask={setTextMask}
          setFile={setFile}
          textMask={textMask}
          openApply={openApply}
          setOpenApply={setOpenApply}
          pageNumber={pageNumber}
          setCicularLoaderStatus={setCicularLoaderStatus}
        />
        <InfoComponent infoViewer={infoViewer} setInfoViewer={setInfoViewer} />
      </>
    );
  }
);
