import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import { Navigate, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsCheckLg } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { AiOutlineUndo } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import ReconciliationResultTable from "./helperComponents/ReconciliationResultTable";
import { Button, IconButton, Tooltip } from "@mui/material";
import TooltipComponent from "./helperComponents/TooltipComponent";
import ApplyreconciliationModal from "./helperComponents/HelperModal";

function ReconciliatedResult() {
  let temp = sessionStorage?.getItem("token");
  const { sidebarView } = useContext(UserDataContext);
  const location = useLocation();
  const navigate = useNavigate();
  const color_final = temp ? location.state["color-final"] : "";
  const resultData = location?.state?.data;
  const ruleData = location?.state?.results;
  const fileDetails = location?.state?.reconciliated_file_detail;
  const [resultColor, setResultColor] = useState(color_final);
  const [isEdit, setisEdit] = useState(false);
  const tempData = resultData ? JSON.parse(JSON.stringify(resultData)) : [];
  const [openModal, setopenModal] = useState(false);

  const [editableData, seteditableData] = useState(() => {
    let arr = resultData?.map((res) => {
      return res.slice();
    });
    return arr;
  });

  const [reconData, setreconData] = useState(() => {
    let arr = resultData?.map((res) => {
      return res.slice();
    });

    return arr;
  });

  const message = (
    <>
      <em>Red Color resembles- False</em>
      <br />
      <em>Green Color resembles- True</em>
      <br />
      <br />
      <>
        <em>Note: </em> In case the Excel File on which you are applying
        reconciliation has an empty header then it might also show an empty
        header in the reconciled result.
      </>
    </>
  );
  const ruleMessage = (
    <>
      <ul>
        <li>
          You can view the results of all the rules by clicking on "All Rules."
        </li>
        <br />
        <li>
          You can access the results of each rule by simply clicking on them.
        </li>
      </ul>
    </>
  );

  if (!temp || temp === undefined) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <>
      <div className="main-container">
        <div className={sidebarView.cssLeftContainer}>
          <Sidebar />
        </div>
        <div className={`${sidebarView.cssRightContainer}`}>
          <Header />
          <div className="right-container-content">
            <div
              style={{
                display: "block",
                position: "absolute",
                left: "10px",
                top: "50px",
                zIndex: "1000",
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                style={{ background: "#DBDFE2", height: "30px", width: "30px" }}
              >
                <BsArrowLeft style={{ color: "#656565" }} />
              </IconButton>
            </div>
            <div className="task-container">
              <div
                className="task-heading"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                Reconciliated Result
                <TooltipComponent message={message} position="right" />
              </div>
              <div className="result-container">
                <div
                  className="result-left-container"
                  style={{
                    overflowY: "hidden",
                    height: "450px",
                  }}
                >
                  <ReconciliationResultTable
                    data={reconData}
                    setreconData={setreconData}
                    finalColor={resultColor}
                    Edit={isEdit}
                    editableData={editableData}
                    seteditableData={seteditableData}
                  />

                  <div
                    className=""
                    style={{
                      marginTop: "1rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!isEdit && (
                      <Button
                        onClick={() => {
                          setisEdit(true);
                        }}
                        style={{
                          padding: "0.35rem 0.8rem",
                          border: "2px solid #0E0E4F",
                          boxShadow: "0px 5px 15px #2948981A",
                          color: "#0E0E4F",
                        }}
                      >
                        Edit Csv
                      </Button>
                    )}
                    {isEdit && (
                      <>
                        <Button
                          onClick={() => {
                            setisEdit(false);
                            seteditableData(tempData);
                            setreconData(tempData);
                          }}
                          style={{
                            padding: "0.35rem 0.8rem",
                            border: "2px solid #0E0E4F",
                            boxShadow: "0px 5px 15px #2948981A",
                            color: "#0E0E4F",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setopenModal(true);
                          }}
                          style={{
                            padding: "0.35rem 0.8rem",
                            border: "1px solid #0E0E4F",
                            boxShadow: "0px 5px 15px #2948981A",
                            color: "white",
                            background: "#000000 0% 0% no-repeat padding-box",
                            marginLeft: "1rem",
                          }}
                        >
                          Update
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                <div className="result-right-container">
                  <div
                    style={{
                      color: "#0E0101",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Rules
                      <TooltipComponent
                        message={ruleMessage}
                        position="bottom"
                      />
                    </span>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ background: "#101060", height: "35px" }}
                      onClick={() => {
                        setResultColor(location?.state["color-final"]);
                      }}
                    >
                      All Rules
                    </Button>
                  </div>

                  {ruleData?.map((eachRule) => {
                    return (
                      <>
                        <div
                          className="rule-card"
                          style={{
                            cursor: "pointer",
                            border:
                              eachRule.result === "sucess"
                                ? "2px solid #B3F5E0"
                                : eachRule.result === "failed"
                                ? "2px solid #FAD3D4"
                                : "2px solid #FAE7D7",
                          }}
                          value={JSON.stringify(eachRule["color-array"])}
                          onClick={(e) => {
                            let array = JSON.parse(
                              e.target.attributes[1].value
                            );
                            setResultColor(array);
                          }}
                        >
                          <span style={{ pointerEvents: "none" }}>
                            <div className="rule-name">
                              {eachRule.rule.name}
                            </div>
                            <div className="formula">
                              <span
                                style={{ color: "#43434A", fontWeight: 600 }}
                              >
                                Fomula-
                              </span>
                              {eachRule.rule.formula}
                            </div>
                            <div className="description">
                              <span
                                style={{ color: "#43434A", fontWeight: 600 }}
                              >
                                Des-
                              </span>
                              {eachRule.rule.description}
                            </div>
                            {eachRule.result === "sucess" && (
                              <button
                                className="status-button"
                                style={{
                                  background: "#B3F5E0",
                                  pointerEvents: "auto",
                                }}
                              >
                                <Tooltip title="Passed" placement="bottom">
                                  <IconButton>
                                    <BsCheckLg
                                      style={{
                                        color: "#0c976a",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </button>
                            )}
                            {eachRule.result === "failed" && (
                              <button
                                className="status-button"
                                style={{
                                  background: "#FAD3D4",
                                  pointerEvents: "auto",
                                }}
                              >
                                <Tooltip title="Failed" placement="bottom">
                                  <IconButton>
                                    <IoMdClose
                                      style={{ color: "red", fontSize: "20px" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </button>
                            )}
                            {eachRule.result === "pending" && (
                              <button
                                className="status-button"
                                style={{
                                  background: "#FAE7D7",
                                  pointerEvents: "auto",
                                }}
                              >
                                <Tooltip
                                  title="Partially Applied"
                                  placement="bottom"
                                >
                                  <IconButton>
                                    <AiOutlineUndo
                                      style={{
                                        color: "orange",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </button>
                            )}
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <ApplyreconciliationModal
          setisopen={setopenModal}
          isopen={openModal}
          data={editableData}
          fileDetails={fileDetails}
        />
      )}
    </>
  );
}

export default ReconciliatedResult;
