import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  List,
  ListItem,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import {
  StyledButton,
  StyledHeading,
  StyledInput,
  StyledInputLabel,
  StyledListItem,
} from "../../assets/styles/InputOutputFolder";

function InputOutputFolder({ isopen, setIsopen, handleUserTasks }) {
  const [params, setparams] = useState({
    template_id: "",
    folder_name: "",
  });
  const temp = sessionStorage.getItem("token");
  const [templateData, settemplateData] = useState([]);

  const handleCloseManual = () => {
    setIsopen(false);
    setparams({});
  };

  const HandleCreateFolder = (e) => {
    e.preventDefault();
    axios
      .post(
        BASE_URL + "/create-s3-directory",
        {
          ...params,
        },
        {
          headers: {
            "X-Auth-Token": temp,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response?.data?.error) {
          let message = response.data.data.message;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            return () => {};
          }
        }
        handleUserTasks();
        handleCloseManual();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const HandleChange = (e) => {
    setparams({
      ...params,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (isopen) {
      axios
        .get(BASE_URL + "/get-templates", {
          headers: {
            "X-Auth-Token": temp,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data?.error) {
            let message = response.data.message;
            toast.error(message);
            if (message === "Invalid Access Token.. Please login") {
              return () => {};
            }
          }

          settemplateData(response.data?.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  }, [isopen]);

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "0rem 1rem",
            minWidth: "400px",
            minHeight: "279px",
          },
        }}
        sx={{ zIndex: 1000 }}
        onClose={handleCloseManual}
        aria-labelledby="customized-dialog-title"
        open={isopen}
      >
        <Box
          component={"form"}
          method="POST"
          onSubmit={HandleCreateFolder}
          style={{
            background: "white",
            overflow: "unset",
            position: "relative",
          }}
        >
          <div
            className="cancle-btn"
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "500px",
              background: "#AC2734 0% 0% no-repeat padding-box",
              position: "absolute",
              top: "-10px",
              right: "-30px",
              cursor: "pointer",
            }}
            onClick={handleCloseManual}
          >
            <RxCross2
              style={{
                fontSize: "1.2rem",
                color: "white",
              }}
            />
          </div>

          <DialogContent disablePadding>
            <StyledHeading>Create Input/Output folder</StyledHeading>

            <List
              style={{
                padding: "0",
              }}
            >
              <StyledListItem>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    width: "100%",
                  }}
                >
                  <StyledInputLabel htmlFor="folder_name">
                    Folder Name
                  </StyledInputLabel>
                  <StyledInput
                    type="text"
                    name="folder_name"
                    id="folder_name"
                    placeholder="Folder Name"
                    value={params?.folder_name}
                    onChange={HandleChange}
                    required
                  />
                </div>
              </StyledListItem>

              <StyledListItem>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                    width: "100%",
                  }}
                >
                  <StyledInputLabel>Template ID</StyledInputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={params?.template_id}
                    name="template_id"
                    label="Template"
                    onChange={HandleChange}
                    required
                    sx={{
                      width: "100%",
                      height: "46px",
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        borderColor: "#50dfb2",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#50dfb2 !important",
                        borderWidth: "1px",

                        "&:hover": {
                          borderColor: "#50dfb2",
                        },
                      },
                    }}
                  >
                    {templateData.map((temp, i) => {
                      return (
                        <MenuItem key={i} value={temp?.template_id}>
                          {temp?.template_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </StyledListItem>

              <StyledListItem
                style={{
                  marginTop: "1rem",
                }}
              >
                <StyledButton type="submit">Create</StyledButton>
                <StyledButton
                  style={{
                    marginLeft: "1rem",
                    border: "2px solid #AC2635",
                    background: "transparent",
                    color: "#AC2635",
                  }}
                  onClick={handleCloseManual}
                >
                  Cancel
                </StyledButton>
              </StyledListItem>
            </List>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}

export default InputOutputFolder;
