import React, { useState } from "react";
import {
  FormContainer,
  StyledAddUser,
  StyledLoginButton,
} from "../../assets/styles/Login";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { BASE_URL } from "../../utils";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, DialogTitle } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import ResetPassword from "../../assets/images/resetpassword.png";
function ChangePassword({ changePasswordDialog, setChangePasswordDialog }) {
  const [params, setParams] = useState({
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();
  const [seePassword, setseePassword] = useState(false);
  const [seeConfirmPassword, setseeConfirmPassword] = useState(false);
  const token = sessionStorage.getItem("token");
  const onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setParams({
      ...params,
      [name]: value,
    });
  };

  const dataValidation = () => {
    let passReg =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+~`|}{[:;?><,./-=]).{8,}$/g;
    if (params?.password !== params?.confirm_password) {
      toast.error("The password confirmation field must match the password field.");
      return false;
    }
    if (!passReg.test(params?.password)) {
      toast.error(
        "Must have one Uppercase, Lowercase, digit, special character, Min. 8 character"
      );
      return false;
    }
    return true;
  };

  const HandleSumbit = async (e) => {
    e.preventDefault();
    if (dataValidation()) {
      try {
        await axios
          .post(
            BASE_URL + "/reset-password",
            {
              new_pass: params.password,
              confirm_new_pass: params.confirm_password,
            },
            {
              headers: {
                "X-Auth-Token": token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response?.data?.success) {
              toast.success(response?.data?.message);
            }
            setParams({});
            setChangePasswordDialog(false);
            navigate("/");
            sessionStorage.clear();
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            toast.error(message);
          });
      } catch (error) {
        let message = error.response.data.message;
        toast.error(message);
      }
    }
  };
  const handleClose = () => {
    setParams({});
    setChangePasswordDialog(false);
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "1rem",
          minWidth: "400px",
          width: "fit-content",
          minHeight: "279px",
          textAlign: "start",
          height: "fit-content",
        },
      }}
      sx={{ zIndex: 1000 }}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={changePasswordDialog}
    >
      <div
        className='cancle-btn'
        style={{
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "500px",
          background: "#AC2734 0% 0% no-repeat padding-box",
          position: "absolute",
          top: "-10px",
          right: "-10px",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        <RxCross2
          style={{
            fontSize: "1.2rem",
            color: "white",
          }}
        />
      </div>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={ResetPassword}
          alt=""
          style={{ height: "50px", width: "50px", marginBottom:"10px" }}
        />
        Change Password
        <p style={{ color: "grey", margin: "10px 0 0 0", fontSize: "11px" }}>
          After you have changed your password,
          <br /> please re-login using your new password.
        </p>
      </DialogTitle>
      <FormContainer onSubmit={HandleSumbit}>
        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          <StyledAddUser
            type={seePassword ? "text" : "password"}
            name='password'
            style={{
              paddingRight: "2.5rem",
            }}
            onChange={onChangehandler}
            placeholder="New Password"
            value={params?.password}
            required
          />
          <div
            style={{
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
            onClick={() => {
              setseePassword(!seePassword);
            }}
          >
            {!seePassword ? <VscEye /> : <VscEyeClosed />}
          </div>
        </div>

        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
        >
          <StyledAddUser
            type={seeConfirmPassword ? "text" : "password"}
            name='confirm_password'
            style={{
              paddingRight: "2.5rem",
            }}
            onChange={onChangehandler}
            placeholder="New Confirm Password"
            value={params?.confirm_password}
            required
          />

          <div
            style={{
              position: "absolute",
              top: "12px",
              right: "15px",
            }}
            onClick={() => {
              setseeConfirmPassword(!seeConfirmPassword);
            }}
          >
            {!seeConfirmPassword ? <VscEye /> : <VscEyeClosed />}
          </div>
        </div>
        <StyledLoginButton type={"submit"}>Change Password</StyledLoginButton>
      </FormContainer>
    </Dialog>
  );
}

export default ChangePassword;
