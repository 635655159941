import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { UserDataContext } from "../AppRouter";
import Header from "./Header";
import axios from "axios";
import { BASE_URL } from "../utils/index";
import AdminTableGrid from "./helperComponents/AdminTableGrid";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const OutputFolder = () => {
  const { setUserInputData, setUserOutputData, userOutputData, sidebarView } =
    useContext(UserDataContext);
  const navigate = useNavigate();
  const temp = sessionStorage?.getItem("token");
  const [userTaskInputData, setUserTaskInputData] = useState(userOutputData);

  useEffect(() => {
    setUserTaskInputData(userOutputData);
  }, [userOutputData]);

  useEffect(() => {
    if (temp) {
      handleUserTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!temp || temp === undefined) {
    return <Navigate to={"/"}></Navigate>;
  }

  const handleUserTasks = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-io-folder-details", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again")
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          setUserInputData(response?.data?.data?.Input);
          setUserOutputData(response?.data?.data?.Output);
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const tableHeading = [
    "Folder Name",
    "Last Updated Date",
    "Created On",
    "Action",
  ];
  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} `}>
        <Header />
        <div className="right-container-content">
          <AdminTableGrid
            tableHeading={tableHeading}
            userTaskInputData={userTaskInputData}
            pageName="Output Folder"
            heading="Output Folder"
            type="xlsx"
          />
        </div>
      </div>
    </div>
  );
};

export default OutputFolder;
