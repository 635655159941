import React from "react";
function DashboardSearchBar({ onChange, placeholder, value }) {
  return (
    <div className="dashboard-input-searchbar">
      <input
        type="text"
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        placeholder={placeholder || "Enter file Link"}
      />
    </div>
  );
}

export default DashboardSearchBar;
