import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import Sidebar from "./Sidebar";
import { decryptStorage } from "../utils";
import { Flex } from "../assets/styles/Chatbot";
import ChatBotSidebar from "./AIChatbotComponents/ChatBotSidebar";
import Chat from "./AIChatbotComponents/Chat";
import PdfViewer from "./AIChatbotComponents/PDFViewer";

function AIChatbot() {
  const {
    sidebarView,
    cicularLoaderStatus,
    setCicularLoaderStatus,
    selectedFiles,
    uploadedFiles,
  } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const role =
    temp && decryptStorage(sessionStorage.getItem("userDetails"))?.role;

  if (!sessionStorage.getItem("token")) {
    return <Navigate to={"/"}></Navigate>;
  }

  const currentFile = uploadedFiles.filter((file) => {
    return selectedFiles.includes(file.pdf_file_id);
  });

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
        <Header />
        <div
          className="right-container-content"
          style={{ padding: "10px 20px" }}
        >
          <Flex background="#001529" height="100%" width="100%" direction="row">
            <Flex
              background="#001529"
              height="100%"
              width="280px"
              className="chatbot_sidebar_container"
              style={{
                borderRight: "3px solid rgb(77, 77, 77)",
                overflow: "auto",
              }}
            >
              <ChatBotSidebar />
            </Flex>
            <Flex
              background="#001529"
              height="100%"
              width="35%"
              // flex={1}
              style={{
                borderRight: "3px solid rgb(77, 77, 77)",
              }}
            >
              <PdfViewer file={currentFile} />
            </Flex>

            <Flex background="#001529" height="100%" flex={1}>
              <Chat id={selectedFiles} key={selectedFiles.join(",")} />
            </Flex>
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default AIChatbot;
