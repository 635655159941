import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  align-items: ${(props) => props.align || "flex-start"};
  justify-content: ${(props) => props.justify || "flex-start"};
  padding: ${(props) => props.padding || "0px"};
  background: ${(props) => {
    return `${props.background}`;
  }};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  flex-direction: ${(props) => props.direction || "row"};
  gap: ${(props) => props.gap || "0px"};
  border-radius: ${(props) => props.borderRadius || "0px"};
`;
