import React from "react";
import { Dialog } from "@mui/material";
import { IoClose } from "react-icons/io5";
import ReconciliationResultTable from "./ReconciliationResultTable";

function ViewDataDialog({
  viewDialog,
  setViewDialog,
  detailsProps,
  handleUserTasks,
}) {

  const handleCloseManual = () => {
    setViewDialog({
      open: false,
      data: undefined,
    });
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            overflow: "unset",
            borderRadius: "15px",
            padding: "1.5rem",
            minWidth: "400px",
            width: "80%",
            minHeight: "279px",
            height: "500px",
          },
        }}
        sx={{ zIndex: 1000 }}
        onClose={handleCloseManual}
        aria-labelledby="customized-dialog-title"
        open={viewDialog.open}
      >
        <div className="cancel-btn" onClick={handleCloseManual}>
          <IoClose />
        </div>
        {viewDialog.data && (
          <ReconciliationResultTable
            data={viewDialog.data}
            Edit={false}
            isView={true}
          />
        )}
      </Dialog>
    </>
  );
}

export default ViewDataDialog;
