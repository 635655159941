import React, { useContext } from "react";
import RightHeadedArrow from "../../assets/images/Path 2710.svg";
import AddArrow from "../../assets/images/Group 37503.svg";
import { UserDataContext } from "../../AppRouter";

const DashBoardSummary =
  /**
   * Description placeholder
   * @date 10/4/2023 - 11:13:05 PM
   *
   * @returns {*}
   */
  () => {
    const { userData } = useContext(UserDataContext);
    const cardData = [
      {
        count: userData.progress ? userData.progress : 0,
        heading: "PDF Extraction",
        status: "In Progress",
        viewListURL: "/taskinprogress",
        fontColor: "#fafafa",
        background: "#0E0E4F",
      },
      {
        count: userData.completed ? userData.completed : 0,
        heading: "PDF Extraction",
        status: "Completed",
        viewListURL: "/taskcompleted",
        fontColor: "#fafafa",
        background: "#50DFB2",
      },
      {
        count: userData.reconsilated ? userData.reconsilated : 0,
        heading: "Total Files",
        status: "Reconciled",
        viewListURL: "/reconciliated",
        fontColor: "#fafafa",
        background: "#FC8A2E",
      },
      {
        count: userData.ruleset_list ? userData.ruleset_list : 0,
        heading: "Total",
        status: "Rulesets",
        viewListURL: "/rulesetlist",
        fontColor: "#fafafa",
        background: "#2e6afc",
      },
    ];
    return (
      <div className="summary-main-div">
        {cardData.map((value) => {
          return (
            <>
              <a href={value.viewListURL} className="summary-card">
                <div
                  className="summary-card-number"
                  style={{
                    color: value.fontColor,
                    background: value.background,
                  }}
                >
                  <div>{value.count}</div>
                </div>
                <div className="summary-card-data">
                  <div className="summary-card-headings">
                    <p
                      style={{
                        color: value.background,
                      }}
                    >
                      {value.heading}
                    </p>
                    <h4
                      style={{
                        color: value.background,
                      }}
                    >
                      {value.status}
                    </h4>
                  </div>
                </div>
              </a>
            </>
          );
        })}
      </div>
    );
  };

export default DashBoardSummary;
