import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { UserDataContext } from "../../AppRouter";
import { Button } from "@mui/material";
import ExcelTemplate from "../helperComponents/ExcelTemplate";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router";
import CreateRulesetForm from "./CreateRulesetForm";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import { Navigate } from "react-router-dom";
import ViewDataDialog from "../helperComponents/ViewDataDialog";

function CreateRuleset() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const temp = sessionStorage.getItem("token");
  const [data, setData] = useState();
  const [rulesetName, setRulesetName] = useState();
  const [columns, setColumns] = useState();
  const { sidebarView, selectedTemplate, setSelectedTemplate } =
    useContext(UserDataContext);
  const [ruleData, setRuleData] = useState({
    name: "",
    description: "",
    formula: "",
    id: "",
  });
  const [ruleId, setRuleId] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [viewDialog, setViewDialog] = useState({
    open: false,
    data: [],
  });

  useEffect(() => {
    if (temp) {
      let tempName;
      let selectedItem;
      let array = sessionStorage.getItem("ruleArray");
      if (state?.data) {
        getTemplate(state.data?.template_id);
        tempName = state.data?.name;
      } else {
        tempName = sessionStorage.getItem("rulesetName");
        selectedItem = JSON.parse(
          Object(sessionStorage.getItem("selectedTemplate"))
        );
      }
      setSelectedTemplate(selectedItem);
      if (selectedItem) {
        dataConvertor(selectedItem);
      }
      if (tempName) setRulesetName(tempName);
      if (array && array.length && tempName) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
      if (state && state.type === "view") {
        setSaveDisabled(true);
      }
    }
  }, [ruleData]);

  useEffect(() => {
    let array = sessionStorage.getItem("ruleArray");
    if (array && array.length && rulesetName) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [rulesetName]);

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }
  const dataConvertor = (selectedItem) => {
    let itr;
    itr = selectedItem?.data?.map((value) => {
      return value.map((innerItem) => {
        return { value: innerItem, readOnly: true };
      });
    });
    setColumns(selectedItem.modified_column);
    setData(itr);
  };

  const getTemplate = (id) => {
    axios
      .get(BASE_URL + `/ruleset-template/${id}`, {
        headers: {
          "X-Auth-Token": temp,
        },
      })
      .then((res) => {
        dataConvertor(res.data.data);
        setSelectedTemplate(res.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handleSaveRuleSet = async (e) => {
    e.preventDefault();
    let data =
      sessionStorage.getItem("ruleArray") &&
      Array.from(JSON.parse(sessionStorage.getItem("ruleArray")));
    let rules = [];
    let payload = {};
    rules = data?.map((value, index) => {
      return {
        rule: { id: value.id },
        rule_order_number: index + 1,
      };
    });
    payload = {
      name: rulesetName,
      rules: rules,
      template_id: selectedTemplate.id,
    };
    if (state?.type === "new") {
      try {
        let response = await axios.post(BASE_URL + "/rule-set", payload, {
          headers: {
            "X-Auth-Token": temp,
          },
        });
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          if (response.data.success) {
            toast.success(response?.data?.message);
            navigate("/rulesetlist");
            sessionStorage.removeItem("ruleArray");
            sessionStorage.removeItem("chooseTemplateIndex");
            sessionStorage.removeItem("selectedTemplate");
            sessionStorage.removeItem("rulesetName");
          } else {
            return;
          }
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    } else {
      try {
        let response = await axios.put(
          BASE_URL + `/rule-set/${state.data.id}`,
          payload,
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (!response?.data.success) {
          let message = response?.data?.message;
          if (message === "Invalid Access Token.. Please login") {
            return <Navigate to="/" />;
          }
        } else {
          if (response.data.success) {
            toast.success(response?.data?.message);
            navigate("/rulesetlist");
            sessionStorage.removeItem("ruleArray");
            sessionStorage.removeItem("chooseTemplateIndex");
            sessionStorage.removeItem("selectedTemplate");
            sessionStorage.removeItem("rulesetName");
          } else {
            return;
          }
        }
      } catch (error) {
        let message = error.response?.data?.message;
        toast.error(message);
      }
    }
  };
  const handleView = async (e) => {
    e.preventDefault();
    let temp = sessionStorage.getItem("token");
    let payload = {
      template_id: selectedTemplate.id,
      rules: ruleId.map((value) => {
        return { rule_id: value };
      }),
    };
    try {
      let response = await axios.post(
        BASE_URL + "/rule/apply/template",
        payload,
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.data.success) {
          setViewDialog({
            open: true,
            data: [
              [...response.data.data.template.modified_column],
              ...response.data.data.data,
            ],
            columnLabel: response.data.data.columns,
          });
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };
  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <>
      <div className="main-container">
        <div className={sidebarView.cssLeftContainer}>
          <Sidebar />
        </div>
        <div className={`${sidebarView.cssRightContainer} dashboard-div`}>
          <Header />
          <div
            className="right-container-content"
            style={{ overflow: "hidden" }}
          >
            <div className="task-container">
              <div className="task-header-container">
                <div
                  className="task-heading"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {state.type} Ruleset
                </div>
                <div className="task-heading-button-container">
                  <Button
                    variant="filled"
                    sx={{
                      color: "white",
                      background: "#50DFB2",
                      border: "1px solid #50DFB2",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      "&:hover": {
                        color: "white",
                        background: "#50DFB2",
                        border: "1px solid #50DFB2",
                      },
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/create-custom-csv");
                    }}
                  >
                    Create Custom CSV{" "}
                  </Button>
                </div>
              </div>
              <div className="template-content-form">
                <templateContainer
                  style={{
                    height: "100%",
                    alignItems: "center",
                    display: "flex",
                    padding: "10px",
                    width: "50%",
                  }}
                >
                  <div
                    className="csv-template-container"
                    style={{
                      height: "90%",
                      width: "calc(100% - 10px)",
                    }}
                  >
                    <templateHeading>{selectedTemplate?.name}</templateHeading>
                    <div
                      className="excel-template-conatiner"
                      style={{ height: "100%" }}
                    >
                      {data && <ExcelTemplate data={data} column={columns} />}
                    </div>
                    <div className="template-buttons">
                      <button
                        style={{
                          border: "1px solid #0e0e4f",
                          backgroundColor: "white",
                          color: "#0e0e4f",
                        }}
                        onClick={handleView}
                      >
                        view <AiOutlineEye />{" "}
                      </button>
                    </div>
                  </div>
                </templateContainer>
                <CreateRulesetForm
                  type={state.type}
                  ruleData={ruleData}
                  setRuleData={setRuleData}
                  setRulesetName={setRulesetName}
                  rulesetName={rulesetName}
                  setSaveDisabled={setSaveDisabled}
                  setRuleId={setRuleId}
                  ruleId={ruleId}
                />
              </div>
              <div className="choose-ruleset-buttons">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  back
                </button>
                {(state.type === "new" || state.type === "edit") && (
                  <button
                    onClick={(e) => {
                      handleSaveRuleSet(e);
                    }}
                    type="submit"
                    style={{
                      background: !saveDisabled ? "#50DFB2" : "transparent",
                      border: !saveDisabled ? "none" : "1px solid grey",
                      color: !saveDisabled ? "white" : "grey",
                    }}
                    disabled={saveDisabled}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewDialog.open && (
        <ViewDataDialog
          viewDialog={viewDialog}
          setViewDialog={setViewDialog}
          data={viewDialog.data}
        />
      )}
    </>
  );
}

export default CreateRuleset;
