import React, { useState } from "react";
import { useFormik } from "formik";
import RuleTable from "../helperComponents/RuleTable";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";

function CreateRulesetForm({
  type,
  rulesetName,
  setRulesetName,
  setSaveDisabled,
  setRuleData,
  ruleData,
  ruleId,
  setRuleId,
}) {
  const [open, setOpen] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const handleAddNewRuleset = (e) => {
    e.preventDefault();
    setRuleData({
      name: "",
      formula: "",
      description: "",
    });
  };
  const token = sessionStorage.getItem("token");
  const formik = useFormik({
    initialValues: {
      name: ruleData.name || "",
      formula: ruleData.formula || "",
      description: ruleData.description || "",
      id: ruleData.id || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (formEdit) {
        axios
          .put(BASE_URL + `/rule/${values.id}`, values, {
            headers: {
              "X-Auth-Token": token,
            },
          })
          .then((res) => {
            let resData = {
              description: res.data.data.description,
              id: res.data.data.id,
              name: res.data.data.name,
              formula: res.data.data.formula,
            };
            setRuleData(resData);
            setOpen(!open);
            let ruleArray = Array.from(
              JSON.parse(sessionStorage.getItem("ruleArray"))
            );
            for (let i = 0; i < ruleArray.length; i++) {
              if (values.id === ruleArray[i].id) {
                ruleArray[i] = values;
              }
            }
            sessionStorage.setItem("ruleArray", JSON.stringify(ruleArray));
            toast.success(res.data.message);
            formik.handleReset();
          })
          .catch((err) => {
            console.log(err);
          });
        setFormEdit(false);
      } else {
        axios
          .post(BASE_URL + "/rule", values, {
            headers: {
              "X-Auth-Token": token,
            },
          })
          .then((res) => {
            let resData = {
              description: res.data.data.description,
              id: res.data.data.id,
              name: res.data.data.name,
              formula: res.data.data.formula,
            };
            setRuleData(resData);
            setOpen(!open);
            let prevData = sessionStorage.getItem("ruleArray");
            if (!prevData) {
              sessionStorage.setItem(
                "ruleArray",
                JSON.stringify(new Array(resData))
              );
            } else {
              let ruleArray = Array.from(
                JSON.parse(sessionStorage.getItem("ruleArray"))
              );
              ruleArray.push(resData);
              sessionStorage.setItem("ruleArray", JSON.stringify(ruleArray));
            }

            formik.handleReset();
            toast.success("Rule Created");
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
          });
      }
    },
  });
  const handleTemplateName = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    setRulesetName(value);
    sessionStorage.setItem(name, value);
  };
  return (
    <div className="template-form">
      <input
        type="text"
        name="rulesetName"
        className="form-ruleset-name"
        placeholder="Enter Ruleset Name"
        style={{ marginBottom: "10px" }}
        onChange={handleTemplateName}
        value={rulesetName}
        disabled={type === "view"}
      />
      {type === "new" ? (
        <button
          className="form-add-new-button"
          onClick={(e) => {
            handleAddNewRuleset(e);
            setOpen(true);
          }}
        >
          Add New Rule
        </button>
      ) : (
        ""
      )}
      {open && (
        <form onSubmit={formik.handleSubmit} className="template-inner-form">
          <input
            type="text"
            className="inner-form-input"
            placeholder="Name"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
            required
            disabled={type === "view"}
          />
          <input
            type="text"
            className="inner-form-input"
            placeholder="Formula"
            style={{ textTransform: "uppercase" }}
            onChange={formik.handleChange}
            value={formik.values.formula}
            name="formula"
            required
            disabled={type === "view"}
          />
          <textarea
            name="description"
            className="inner-form--text-area"
            placeholder="Description"
            id=""
            cols="30"
            rows="10"
            required
            onChange={formik.handleChange}
            value={formik.values.description}
            disabled={type === "view"}
          ></textarea>
          <div className="inner-form-action-button">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setRuleData({});
                setOpen(false);
              }}
            >
              cancel
            </button>
            <button type="submit" style={{ cursor: "pointer", zIndex: "auto" }}>
              save
            </button>
          </div>
        </form>
      )}
      {!open && (
        <div className="rule-table">
          <RuleTable
            type={type}
            setRuleData={setRuleData}
            setSaveDisabled={setSaveDisabled}
            setOpen={setOpen}
            setFormEdit={setFormEdit}
            ruleId={ruleId}
            setRuleId={setRuleId}
          />
        </div>
      )}
    </div>
  );
}

export default CreateRulesetForm;
