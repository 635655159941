import React, { useEffect, useState } from "react";
import {
  FormContainer,
  LoginContainer,
  LoginFormContainer,
  LoginLeft,
  LoginRight,
  LogoContainer,
  StyledLoginButton,
  StyledLoginInput,
} from "./../assets/styles/Login";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { useNavigate } from "react-router-dom/dist";
import Logo from "./../assets/images/logo@2x.png";
import axios from "axios";
import { BASE_URL, decryptStorage, encryptStorage } from "../utils";
import { toast } from "react-toastify";

function ResetPassword() {
  useEffect(() => {
    if (
      !sessionStorage.getItem("reset_token") &&
      !window.location.pathname.split("/")[2]
    )
      navigate("/");
    else {
      sessionStorage.removeItem("userDetails");
      sessionStorage.removeItem("role");
      sessionStorage.removeItem("token");
    }
  }, []);
  const [params, setParams] = useState({
    email: sessionStorage.getItem("userDetails")?.email,
    password: "",
    confirm_new_pass: "",
    new_pass: "",
  });
  const [seeNewPassword, setseeNewPassword] = useState(false);
  const [seeNewConfirmPassword, setseeNewConfirmPassword] = useState(false);
  const temp = window.location.pathname.split("/")[2]
    ? window.location.pathname.split("/")[2]
    : sessionStorage.getItem("reset_token");

  const onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setParams({
      ...params,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const dataValidation = () => {
    let passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&()]).{8,}$/g;
    if (params?.new_pass !== params?.confirm_new_pass) {
      toast.error("Password and Confirm Password must be equal");
      return false;
    }
    if (!passReg.test(params?.new_pass)) {
      toast.error(
        "Must have one Uppercase, Lowercase, digit, special character, Min. 8 character"
      );
      return false;
    }
    return true;
  };

  const HandleSumbit = (e) => {
    e.preventDefault();

    if (dataValidation()) {
      try {
        axios
          .post(
            BASE_URL + "/reset-password",
            {
              new_pass: params.new_pass,
              confirm_new_pass: params.confirm_new_pass,
            },
            {
              headers: {
                "X-Auth-Token": temp,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response?.data?.success) {
              let role = response.data.data.role;
              let userDetails = response.data.data;
              sessionStorage.removeItem("reset_token");
              sessionStorage.setItem("token", userDetails.token);
              sessionStorage.setItem("role", encryptStorage(role));
              sessionStorage.setItem(
                "userDetails",
                encryptStorage(JSON.stringify(userDetails,"'"))
              );
              toast.success(response.data?.message);
              navigate("/");
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            toast.error(message);
          });
      } catch (error) {
        let message = error.response.data.message;
        toast.error(message);
      }
    }
  };
  return (
    <LoginContainer>
      <LoginRight></LoginRight>
      <LoginLeft>
        <LogoContainer
          style={{
            transform: "translateY(-25px)",
          }}
        >
          <img
            style={{
              width: "120px",
              height: "120px",
              objectFit: "contain",
            }}
            className="foliomax"
            src={Logo}
            alt="Foliomax"
          />{" "}
        </LogoContainer>
        <LoginFormContainer
          style={{
            top: "25%",
          }}
        >
          <div className="login_circle">
            <div className="login_circle-2"></div>
          </div>

          <div className="login-title">Reset Password Here</div>
          <div className="login-subtitle">Welcome to FolioMax</div>
          <FormContainer onSubmit={HandleSumbit}>
            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              <StyledLoginInput
                type={seeNewPassword ? "text" : "password"}
                name="new_pass"
                style={{
                  paddingRight: "2.5rem",
                }}
                onChange={onChangehandler}
                placeholder="New Password"
                value={params?.new_pass}
                required
              />

              <div
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "15px",
                }}
                onClick={() => {
                  setseeNewPassword(!seeNewPassword);
                }}
              >
                {!seeNewPassword ? <VscEye /> : <VscEyeClosed />}
              </div>
            </div>

            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              <StyledLoginInput
                type={seeNewConfirmPassword ? "text" : "password"}
                name="confirm_new_pass"
                style={{
                  paddingRight: "2.5rem",
                }}
                onChange={onChangehandler}
                placeholder="Confirm New Password"
                value={params?.confirm_new_pass}
                required
              />

              <div
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "15px",
                }}
                onClick={() => {
                  setseeNewConfirmPassword(!seeNewConfirmPassword);
                }}
              >
                {!seeNewConfirmPassword ? <VscEye /> : <VscEyeClosed />}
              </div>
            </div>

            <StyledLoginButton type="submit">Reset Password</StyledLoginButton>
          </FormContainer>
        </LoginFormContainer>
      </LoginLeft>
    </LoginContainer>
  );
}

export default ResetPassword;
