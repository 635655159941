import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import TooltipComponent from "./TooltipComponent";

function RuleTable({
  type,
  setSaveDisabled,
  setOpen,
  setRuleData,
  setFormEdit,
  setRuleId,
  ruleId,
}) {
  const [rules, setRules] = useState([]);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("ruleArray"))
      ? Array.from(JSON.parse(sessionStorage.getItem("ruleArray")))
      : [];
    setRules(data);
  }, []);

  const handleDeleteRule = (e) => {
    e.preventDefault();
    let array = JSON.parse(sessionStorage.getItem("ruleArray"));
    array = array.filter((value) => {
      return value.id != e.target.value;
    });
    if (array.length === 0) {
      setSaveDisabled(true);
      sessionStorage.removeItem("ruleArray");
    } else sessionStorage.setItem("ruleArray", JSON.stringify(array));
    setRules(array);
    toast.success("Rule Deleted Successfully");
  };

  const handleEdit = (e, value) => {
    if (type !== "view") {
      e.preventDefault();
      setRuleData(value);
      setOpen(true);
      setFormEdit(true);
    }
  };
  const handleSelection = (e, id) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setRuleId([...ruleId, id]);
    } else {
      setRuleId((prevData) => {
        return prevData.filter((value) => {
          // eslint-disable-next-line eqeqeq
          return value != id;
        });
      });
      setChecked(-1);
    }
  };
  return (
    <>
      <span
        style={{
          position: "absolute",
          right: "25px",
          top: "70px",
        }}
      >
        <TooltipComponent
          message="Double Click to edit rule"
          position="bottom"
        />
      </span>
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow style={{ background: "white" }}>
            <TableCell className="rule-table-cell">Rule Name</TableCell>
            <TableCell className="rule-table-cell">Formula</TableCell>
            <TableCell className="rule-table-cell">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules &&
            rules?.map((value) => (
              <TableRow
                value={value}
                onDoubleClick={(e) => handleEdit(e, value)}
                key={value.id}
                style={{ cursor: "pointer" }}
              >
                <TableCell className="rule-table-cell">
                  {type !== "view" ? (
                    <Checkbox
                      size="small"
                      style={{ margin: "0", color: "#50DFB2" }}
                      onChange={(e) => handleSelection(e, value.id)}
                      value={ruleId.includes(value.id)}
                      checked={ruleId.includes(value.id)}
                    ></Checkbox>
                  ) : (
                    ""
                  )}
                  {value?.name}
                </TableCell>
                <TableCell className="rule-table-cell">
                  {value?.formula}
                </TableCell>
                <TableCell className="rule-table-cell">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {value?.description}{" "}
                    {type !== "view" ? (
                      <button
                        onClick={handleDeleteRule}
                        value={value?.id}
                        style={{
                          border: "1px solid #b9b9bf",
                          color: "#b9b9bf",
                          height: "25px",
                          width: "25px",
                          borderRadius: "100%",
                          display: "flex",
                          position: "relative",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          background: "transparent",
                        }}
                      >
                        <FiTrash2 style={{ pointerEvents: "none" }} />{" "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
}

export default RuleTable;
