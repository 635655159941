import React, { useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import CircularLoader from "../ReusableComponents/CircularLoader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageViewer = React.forwardRef(
  (
    {
      url,
      pageNumber,
      setPageNumber,
      setNumPages,
      value,
      highlight,
      setHighlight,
    },
    ref
  ) => {
    const canvasRef = useRef();
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    return (
      <div
        ref={canvasRef}
        className="page-viewer-selection"
        style={{ border: highlight-1 === value ? "2px solid black" : "" }}
        onClick={(e) => {
          e.preventDefault();
          setHighlight(pageNumber);
          setPageNumber(pageNumber);
        }}
      >
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<CircularLoader />}
        >
          <Page
            scale={0.175}
            pageNumber={pageNumber}
            canvasRef={canvasRef}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
        <span className="page-selection-page-number">{pageNumber}</span>
      </div>
    );
  }
);
export default PageViewer;
