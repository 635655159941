import React, { useContext, useEffect } from "react";
import { UserDataContext } from "../../AppRouter";
import { useNavigate } from "react-router-dom";
import TooltipComponent from "./TooltipComponent";

/**
 * Description placeholder
 * @date 10/4/2023 - 11:12:45 PM
 *
 * @returns {*}
 */
function Buildtable() {
  const row = [1, 2, 3, 4, 5, 6, 7];
  const column = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const { setRowCol, rowCol } = useContext(UserDataContext);
  const navigate = useNavigate();
  useEffect(() => {
    setRowCol({
      row: "",
      column: "",
    });
    sessionStorage.removeItem("uploadCSVpayload");
  }, []);
  function dragSelect(e, row, col) {
    e.preventDefault();
    setRowCol({
      row: row,
      column: col,
    });
    let dragColor = document.getElementsByClassName("table-grid");
    Array.from(dragColor).map((value) => {
      if (
        Number(value.attributes.row.value) <= Number(row) &&
        Number(value.attributes.column.value) <= Number(col)
      ) {
        value.id = "highlighted-table-grid";
      } else {
        value.id = "not-highlighted";
      }
    });
  }
  const message = (
    <>
      <p>Please select the columns and rows by selecting the boxes.</p>
      <em>* If column/row limit exceeds, use Upload CSV.</em>
    </>
  );
  return (
    <div className="build-table-container">
      <p
        style={{
          display: "inline-flex",
          alignItems: "center",
          margin: 0,
          padding: 0,
        }}
      >
        Build from Scratch
        <TooltipComponent message={message} position="bottom" />
      </p>
      <div className="small-div-container" style={{ overflow: "hidden" }}>
        {row.map((rowValue) => {
          return (
            <div className="outer-small-box">
              {column.map((colvalue) => {
                return (
                  <div
                    className="table-grid"
                    id="not-highlighted"
                    row={rowValue}
                    column={colvalue}
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      color: "#bdbdbd",
                      cursor: "pointer",
                    }}
                    onClick={(e) => dragSelect(e, rowValue, colvalue)}
                  >
                    {String.fromCharCode("A".charCodeAt() - 1 + colvalue) +
                      rowValue}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div>
        <button
          disabled={!Boolean(rowCol.row)}
          onClick={(e) => {
            e.preventDefault();
            sessionStorage.setItem("rowCol", JSON.stringify(rowCol));
            navigate("/create-custom-csv/custom-table");
          }}
        >
          Create
        </button>
      </div>
    </div>
  );
}

export default Buildtable;
