import React, { useContext, useEffect, useState } from "react";
import {
  ForgotPasswordContainer,
  FormContainer,
  LoginContainer,
  LoginFormContainer,
  LoginLeft,
  LoginRight,
  LogoBox,
  LogoContainer,
  NameContainer,
  StyledLoginButton,
  StyledLoginInput,
} from "./../assets/styles/Login";
import MenuButtonCollapsed from "../assets/images/menu-collapse.svg";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom/dist";
import Logo from "./../assets/images/logo2x.png";
import axios from "axios";
import { BASE_URL, decryptStorage, encryptStorage } from "../utils";
import { toast } from "react-toastify";
import { Flex } from "../assets/styles/Chatbot";
import { UserDataContext } from "../AppRouter";

function NewLogin() {
  const [params, setParams] = useState({
    email: "",
    password: "",
  });
  const [seePassword, setseePassword] = useState(false);
  const temp = sessionStorage.getItem("token");
  const { setSidebarView } = useContext(UserDataContext);

  const onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setParams({
      ...params,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("reset_token")) {
      navigate("/reset-password");
    } else if (temp) {
      navigate("/chatbot");
    } else {
      navigate("/");
    }
  }, []);

  const HandleSumbit = (e) => {
    e.preventDefault();

    try {
      axios
        .post(
          BASE_URL + "/login-user",
          {
            email: params.email,
            password: params.password,
          },
          {
            headers: {
              "X-Auth-Token": temp,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response?.data?.error) {
            let message = response.data.message;
            toast.error(message);
            if (message === "Invalid Access Token.. Please login") {
              return () => {};
            }
          }
          toast.success("Successfull Login ...!");
          let role = response.data.data.role;
          let userDetails = response.data.data;
          sessionStorage.setItem("token", userDetails.token);
          sessionStorage.setItem("reset_token", userDetails.token);
          sessionStorage.setItem("role", encryptStorage(role));
          sessionStorage.setItem(
            "userDetails",
            encryptStorage(JSON.stringify(userDetails, "'"))
          );

          setSidebarView({
            value: true,
            cssClassSidebar: "collapsed-sidebar",
            message: "Expand",
            icon: MenuButtonCollapsed,
            cssLeftContainer: "collapsed-left-container",
            cssRightContainer: "collapsed-right-container",
          });
          if (!response.data.data.pass_reset)
            navigate("/reset-password", { replace: true });
          else {
            sessionStorage.removeItem("reset_token");
            navigate("/chatbot");
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.message;
          toast.error(message);
        });
    } catch (error) {
      let message = error.response.data.message;
      toast.error(message);
    }
  };
  return (
    <LoginContainer>
      <LoginRight></LoginRight>
      <LoginLeft>
        <LogoContainer>
          {/* <img
            style={{
              width: "120px",
              height: "120px",
              objectFit: "contain",
            }}
            className="foliomax"
            src={Logo}
            alt="Foliomax"
          />{" "} */}

          <Flex direction="column" align="center">
            <div className="sidebar-logo">
              <img
                src={Logo}
                style={{
                  width: "80px",
                }}
              />
            </div>

            <Flex
              style={{
                fontSize: "1.1rem",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                letterSpacing: "4px",
                margin: "0px",
                position: "relative",
              }}
            >
              FOLIOMAX
              <div className="logo__circle-R logo__circle-sidebar"></div>
            </Flex>
          </Flex>
        </LogoContainer>
        <LoginFormContainer>
          <div className="login_circle">
            <div className="login_circle-2"></div>
          </div>
          <div className="login-subtitle">Welcome to FolioMax</div>
          <FormContainer onSubmit={HandleSumbit}>
            <StyledLoginInput
              type="email"
              name="email"
              onChange={onChangehandler}
              placeholder="Email"
              value={params?.email}
              required
            />

            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              <StyledLoginInput
                type={seePassword ? "text" : "password"}
                name="password"
                style={{
                  paddingRight: "2.5rem",
                }}
                onChange={onChangehandler}
                placeholder="Password"
                value={params?.password}
                required
              />

              <div
                style={{
                  position: "absolute",
                  top: "12px",
                  right: "15px",
                }}
                onClick={() => {
                  setseePassword(!seePassword);
                }}
              >
                {!seePassword ? <VscEye /> : <VscEyeClosed />}
              </div>
            </div>

            <ForgotPasswordContainer
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "0",
              }}
            >
              <Link to={"/forgetpassword"}>Forgot Password</Link>
            </ForgotPasswordContainer>

            <StyledLoginButton type="submit">Login</StyledLoginButton>
          </FormContainer>
        </LoginFormContainer>
      </LoginLeft>
    </LoginContainer>
  );
}

export default NewLogin;
