import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { Flex } from "../../assets/styles/Chatbot";
import NoData from "./NoData";
import axios from "axios";
import { BASE_URL, decryptStorage } from "../../utils";
import { toast } from "react-toastify";
import EditConversationDiaflog from "./EditConversationDiaflog";
import { RxCross1 } from "react-icons/rx";
import DeleteStarterModal from "./DeleteStarterModal";

function ConversationStarterDialog({ open, onClose }) {
  const [loading, setLoading] = useState(false);
  const [textArea, showTextArea] = useState(false);
  const temp = sessionStorage.getItem("token");
  const role = decryptStorage(sessionStorage.getItem("userDetails"))?.role;
  const [selectedInstruction, setSelectedInstruction] = useState(null);
  const [currentInstructions, setCurrentInstructions] = useState([]);
  const [showFullInstruction, setShowFullInstruction] = useState(null);
  const [openDeleteModal, setopenDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [openEditConvo, setOpenEditConvo] = useState({
    open: false,
    data: null,
  });
  const [addConversationValue, setaddConversationValue] = useState("");
  const HandleSave = async () => {
    if (textArea) {
      setLoading(true);
      try {
        let response = await axios.post(
          BASE_URL + "/add-starter",
          { conversation_starter: addConversationValue },
          {
            headers: {
              "X-Auth-Token": temp,
            },
          }
        );
        if (response?.data.blocked) {
          toast.error("Please Login Again");
          sessionStorage.clear();
          window.location.reload();
        } else {
          if (response.status === 201) {
            showTextArea(false);
            setaddConversationValue("");
            setLoading(false);
            toast.success(response.data.message);
          } else {
            return;
          }
        }
      } catch (error) {
        // console.log(error.response?.data?.message);
        toast.error(error.response?.data?.message);
        setLoading(false);
        showTextArea(false);
      }
    }
  };

  const getStaticStarters = async () => {
    try {
      let response = await axios.get(BASE_URL + "/get-static-starters", {
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setCurrentInstructions(response.data.data);
        } else {
          return;
        }
      }
    } catch (error) {
      console.log(error.response?.data?.message);
      // toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    if (open) {
      getStaticStarters();
    }
  }, [open, textArea, openEditConvo.open]);

  const HandleInstructionDelete = async (id) => {
    try {
      let response = await axios({
        method: "delete",
        url: `${BASE_URL}/delete-starter`,
        data: {
          starter_id: id,
        },
        headers: {
          "X-Auth-Token": temp,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again");
        sessionStorage.clear();
        window.location.reload();
      } else {
        if (response.status === 200) {
          setopenDeleteModal({
            open: false,
            data: null,
          });
          getStaticStarters();
          toast.success(response.data.message);
        } else {
          return;
        }
      }
    } catch (error) {
      // console.log(error.response?.data?.message);
      toast.error(error.response?.data?.message);
    }

    // http()
    //   .delete("delete-starter", {
    //     data: {
    //       starter_id: id,
    //     },
    //   })
    //   .then((res) => {
    //     http()
    //       .get("get-static-starters")
    //       .then((res) => {
    //         setCurrentInstructions(res.data.data);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //     Notification.success(res.data.message);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          setLoading(false);
          showTextArea(false);
          setSelectedInstruction(null);
          setaddConversationValue("");
          setShowFullInstruction(null);
          onClose();
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Conversation Starters
          <Flex
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setLoading(false);
              showTextArea(false);
              setSelectedInstruction(null);
              setaddConversationValue("");
              setShowFullInstruction(null);
              onClose();
            }}
          >
            <RxCross1
              style={{
                fontSize: "1.2rem",
              }}
            />
          </Flex>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "470px",
          }}
        >
          <Button
            onClick={() => {
              showTextArea(true);
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#1677ff",
              fontSize: "0.8rem",
            }}
          >
            Add Conversations
          </Button>

          {textArea && (
            <Flex flex="1">
              <textarea
                placeholder="Enter custom conversation here..."
                className="custom_textarea"
                value={addConversationValue}
                onChange={(e) => {
                  setaddConversationValue(e.target.value);
                }}
                rows={5}
              ></textarea>
            </Flex>

            // <textArea
            //   placeholder="Enter custom conversation here..."
            //   autoSize={{ minRows: 5 }}
            //   value={addConversationValue}
            //   onChange={(e) => {
            //     setaddConversationValue(e.target.value);
            //   }}
            //   style={{
            //     marginTop: "1rem",
            //     fontSize: "1.1rem",
            //   }}
            // />
          )}

          {!textArea && (
            <Flex direction="column" className="radio-main-box">
              <span
                style={{
                  margin: "1rem 0rem",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                Added Conversations:
              </span>
              {currentInstructions.length === 0 && (
                <NoData width={"200px"} height={"200px"} />
              )}

              <Flex
                direction="column"
                width="100%"
                gap="0.6rem"
                className="radio-group"
              >
                {currentInstructions.map((instr) => (
                  <Flex
                    align="center"
                    width="100%"
                    key={instr.id}
                    className="radio-option"
                  >
                    <input
                      type="radio"
                      name="instruction"
                      value={instr.id}
                      disabled
                      checked={selectedInstruction === instr.id}
                      onChange={(e) => setSelectedInstruction(e.target.value)}
                      style={{
                        marginRight: "0.8rem",
                      }}
                    />
                    {showFullInstruction === instr.id ? (
                      <Input
                        placeholder="Enter custom conversation here..."
                        //   autoSize={{ minRows: 2, maxRows: 6 }}

                        value={instr.conversation_starter}
                        readOnly
                        style={{
                          maxWidth: "350px",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          maxWidth: "350px",
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          setShowFullInstruction(instr.id);
                        }}
                      >
                        {instr.conversation_starter}
                      </span>
                    )}

                    {((instr.created_by_admin === true && role === "Admin") ||
                      (instr.created_by_admin === false &&
                        role === "User")) && (
                      <>
                        <Flex
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // HandleInstructionDelete(instr.id);
                            setOpenEditConvo({
                              open: true,
                              data: {
                                ...instr,
                              },
                            });
                          }}
                        >
                          <CiEdit
                            style={{
                              fontSize: "1rem",
                            }}
                          />
                        </Flex>
                        <Flex
                          style={{
                            marginLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setopenDeleteModal({
                              open: true,
                              data: instr.id,
                            });
                            // HandleInstructionDelete(instr.id);
                          }}
                        >
                          <MdDeleteOutline
                            style={{
                              color: "red",
                              fontSize: "1rem",
                            }}
                          />
                        </Flex>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            key="back"
            variant="outlined"
            color="error"
            sx={{
              textTransform: "none",
              fontSize: "0.8rem",
            }}
            onClick={() => {
              setLoading(false);
              showTextArea(false);
              setSelectedInstruction(null);
              setaddConversationValue("");
              setShowFullInstruction(null);
              onClose();
            }}
          >
            Cancel
          </Button>

          {textArea && (
            <Button
              key="submit"
              variant="contained"
              //   loading={loading}
              onClick={HandleSave}
              sx={{
                backgroundColor: "#1677ff",
                textTransform: "none",
                fontSize: "0.8rem",
              }}
            >
              {loading && (
                <CircularProgress
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              {textArea ? "Save conversation" : "Select instruction"}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {openEditConvo && (
        <EditConversationDiaflog
          isopen={openEditConvo}
          setConvoClose={setOpenEditConvo}
        />
      )}

      {openDeleteModal.open && (
        <DeleteStarterModal
          open={openDeleteModal}
          setOpen={setopenDeleteModal}
          HandledeleteStarter={HandleInstructionDelete}
        />
      )}
    </React.Fragment>
  );
}

export default ConversationStarterDialog;
