import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";
import { BoundingBoxCoord } from "../../utils/Services/api.pdfmasking";
import { toast } from "react-toastify";

export default function ApplyChangeDialog({ ...data }) {
  let { openApply, setOpenApply, setCicularLoaderStatus } = data;

  const handleClose = () => {
    setCicularLoaderStatus(false);
    setOpenApply({ state: false, data: {} });
  };
  const handleClick = async (pageNumber) => {
    setCicularLoaderStatus(true);
    let payload = {
      ...openApply.data,
    };
    payload["page_number"] = pageNumber ? pageNumber - 1 : "all";
    await BoundingBoxCoord(payload)
      .then((res) => {
        if (res.data.success) {
          openApply.usestates.setFile({
            ...openApply.usestates.file,
            files: res.data.data.data,
            mask_file_id: res.data.data["mask-file"].id,
          });
          toast.success(res.data.message);
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  };
  return (
    <Dialog
      open={openApply.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "1rem",
          minWidth: "370px",
          width: "370px",
          minHeight: "209px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            height: "50px",
          }}
        >
          Do you want to apply changes on?
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "center",
          height: "70px",
        }}
      >
        <StyledButton
          autoFocus
          style={{
            background: "#50dfb2",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            console.log(openApply.usestates.page_number, "---pjg");
            handleClick(openApply.usestates.page_number);
          }}
        >
          Current Page
        </StyledButton>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          style={{
            marginLeft: "1rem",
            border: "2px solid #50dfb2",
            background: "transparent",
            color: "#50dfb2",
          }}
        >
          All Pages
        </StyledButton>
      </div>
    </Dialog>
  );
}
