const AWS = require("aws-sdk");
require("aws-sdk/lib/maintenance_mode_message").suppress = true;

let S3_BUCKET;
let REGION;
let accessKeyId;
let secretAccessKey;

(function () {
  S3_BUCKET = process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME;
  REGION = process.env.REACT_APP_AWS_STORAGE_BUCKET_REGION;
  accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
})();

export function ReactAWSS3(file, uuid, setProgress, fileItem) {
  AWS.config.update({
    accessKeyId: accessKeyId,
    secretAccessKey: secretAccessKey,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  let filename = `foliomax/${uuid}/${file.name}`;
  const params = {
    Bucket: S3_BUCKET,
    Key: filename, // file directory -> basePath/uuid/filename
    Body: file,
  };

  /**
   * Description placeholder
   * @date 10/3/2023 - 5:05:39 PM
   *
   * @type {*}
   */
  let upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      // File uploading progress
      setProgress((prev) => ({
        ...prev,
        [fileItem]: parseInt((evt.loaded * 100) / evt.total),
      }));
    })
    .promise();
  return upload;
}
