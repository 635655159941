import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "./Header";
import { UserDataContext } from "../AppRouter";
import TableGrid from "./helperComponents/TableGrid";
import RuleSetListEmpty from "./EmptyScreens/RuleSetListEmpty";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../utils";
import { toast } from "react-toastify";

function AdminSetting() {
  const { sidebarView } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const [allUsers, setallUsers] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [inputtyped, setinputtyped] = useState("");

  const getAllUser = (params) => {
    axios
      .get(BASE_URL + "/user-details", {
        headers: {
          "X-Auth-Token": temp,
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        if (response?.data?.error) {
          let message = response.data.message;
          toast.error(message);
          if (message === "Invalid Access Token.. Please login") {
            return () => {};
          }
        } else {
          setallUsers(response.data.data);
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        toast.error(message);
      });
  };

  useEffect(() => {
    if (refresh) {
      getAllUser();
      setrefresh(false);
      setinputtyped("");
    }
  }, [refresh]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      getAllUser({
        search: inputtyped,
      });
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputtyped]);

  const tableHeading = ["First Name", "Last Name", "Email", "Action"];

  if (!temp) {
    return <Navigate to={"/"}></Navigate>;
  }

  return (
    <div className="main-container">
      <div className={sidebarView.cssLeftContainer}>
        <Sidebar />
      </div>
      <div className={`${sidebarView.cssRightContainer}`}>
        <Header />
        <div className="right-container-content">
          <TableGrid
            tableHeading={tableHeading}
            userTaskInputData={allUsers}
            pageName="Settings"
            type="pdf"
            pageButton="admin_setting"
            getAllUser={getAllUser}
            allSelectEnable={false}
            setrefresh={setrefresh}
            setinputtyped={setinputtyped}
            inputtyped={inputtyped}
            action={{
              canEdit: false,
              canDelete: false,
              canDownload: false,
              canView: false,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminSetting;
