import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";
import {
  ResetFileChanges,
} from "../../utils/Services/api.pdfmasking";
import { toast } from "react-toastify";

export default function ResetChanges({ ...data }) {
  let { setResetAll, setFile, file, resetAll, setCicularLoaderStatus } = data;

  const handleClose = () => {
    setCicularLoaderStatus(false);
    setResetAll({ state: false, data: {} });
  };

  const handleClick = async (type) => {
    let payload = sessionStorage.getItem("mask_file_id");
    setCicularLoaderStatus(true);
    await ResetFileChanges(payload)
      .then((res) => {
        if (res.data.success) {
          setFile({
            ...file,
            files: res.data.data.data,
            mask_file_id: res.data.data["mask-file"].id,
          });
          toast.success(res.data.message);
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleClose();
      });
  };
  return (
    <Dialog
      open={resetAll.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "10px",
          minWidth: "370px",
          width: "470px",
          minHeight: "150px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            height: "50px",
          }}
        >
          Abandon all edits! Resetting will erase all changes to this document.
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "center",
          height: "70px",
        }}
      >
        <StyledButton
          autoFocus
          style={{
            background: "#50dfb2",
            color: "white",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          Confirm
        </StyledButton>
        <StyledButton
          onClick={(e) => {
            e.preventDefault();
            handleClose();
          }}
          style={{
            marginLeft: "1rem",
            border: "2px solid red",
            background: "transparent",
            color: "red",
          }}
        >
          Cancel
        </StyledButton>
      </div>
    </Dialog>
  );
}
