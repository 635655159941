import * as React from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoClose } from "react-icons/io5";
import { StyledButton } from "../../assets/styles/InputOutputFolder";

export default function ConfirmDialogBox({ ...data }) {
  const navigate = useNavigate();
  let {
    enableApplyReconciliation,
    setEnableApplyReconciliation,
    selectedData,
  } = data;

  const handleClose = () => {
    setEnableApplyReconciliation(false);
  };
  return (
    <Dialog
      open={enableApplyReconciliation}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "unset",
          borderRadius: "15px",
          padding: "1rem",
          minWidth: "370px",
          width:"370px",
          minHeight: "209px",
        },
      }}
    >
      <div className="cancel-btn" onClick={handleClose}>
        <IoClose />
      </div>
      <DialogContent>
        <DialogContentText
          style={{
            color: "#0D0101",
            fontSize: "",
            fontWeight: "bold",
            textAlign: "center",
            padding: "10px",
            marginTop: "8px",
          }}
        >
          Are you sure you want to apply Reconciliation?
        </DialogContentText>
      </DialogContent>
      <div
        style={{
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        <StyledButton
          autoFocus
          style={{
            background:"#50dfb2", color:"white"
          }}
          onClick={() => {
            navigate("/applyreconciliation", { state: selectedData });
          }}
        >
          Confirm
        </StyledButton>
        <StyledButton
          onClick={handleClose}
          style={{
            marginLeft: "1rem",
            border: "2px solid #AC2635",
            background: "transparent",
            color: "#AC2635",
          }}
        >
          Cancel
        </StyledButton>
      </div>
    </Dialog>
  );
}
