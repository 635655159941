import React, { useCallback, useContext, useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import axios from "axios";
import { BASE_URL } from "../../utils";
import { toast } from "react-toastify";
import { UserDataContext } from "../../AppRouter";
import { Navigate, useNavigate } from "react-router";
import moment from "moment/moment";
import { BiReset, BiFilterAlt } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import DateFormatter from "../../utils/DateFormatter";

function AdminSubTable({ ...data }) {
  const { tableHeading, userTaskInputData, type, pageName } = data;
  const { setUserInputData, setUserOutputData } = useContext(UserDataContext);
  const temp = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);

  const [searchFilter, setSearchFilter] = useState(false);
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [filename, setFileName] = useState();

  const [startDate, setstartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [dateDialog, setDateDialog] = useState(false);

  const [sortByName, setsortByName] = useState(false);
  const [sortByDate, setsortByDate] = useState(false);
  const [filterstatus, setfilterstatus] = useState(false);
  const [inputstatus, setinputstatus] = useState("");

  const checkboxHandler = (e) => {
    let isSelected = e.target.checked;
    let value = parseInt(e.target.value);

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((id) => {
          // eslint-disable-next-line eqeqeq
          return id != value;
        });
      });
    }
  };

  const allCheckboxHandler = () => {
    if (userTaskInputData.length === selectedItems.length) {
      setSelectedItems([]);
    } else {
      let allFileId = userTaskInputData.map((value) => value.file_id);
      setSelectedItems(allFileId);
    }
  };
  const handleUserTasks = async () => {
    let url =
      pageName === "Output Folder"|| pageName === "Input Folder"
        ? "/get-io-folder-details"
        : "/get-user-files-details";
    try {
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },
        params: {
          tab: pageName === "Output Folder" ? "output" : "input",
          file_name: filename,
          upload_by: inputstatus,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again")
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          if (pageName === "Output Folder"|| pageName === "Input Folder") {
            setUserInputData(response?.data?.data?.Input);
            setUserOutputData(response?.data?.data?.Output);
          } else {
            setUserInputData(response?.data?.data?.root.Input);
            setUserOutputData(response?.data?.data?.root.Output);
          }
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const deleteHandler = async (file) => {
    try {
      let response = await axios.post(
        BASE_URL + "/remove-s3-file",
        {
          file_id: [file],
          file_type: String(type).toUpperCase(),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.data.success) {
          toast.success(response?.data.message);
          handleUserTasks();
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.data?.data?.message;
      toast.error(message);
    }
  };

  const base64ToArrayBuffer = (base, contentType, fileName) => {
    let binaryString = window.atob(base);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    switch (contentType) {
      case "txt":
        contentType = "text/plain";
        break;
      case "xlsx":
        contentType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case "xls":
        contentType = "application/vnd.ms-excel";
        break;
      case "pdf":
        contentType = "application/pdf";
        break;
      case "pptx":
        contentType =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        break;
      case "ppt":
        contentType = "application/vnd.ms-powerpoint";
        break;
      case "docx":
        contentType =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        break;
      case "doc":
        contentType = "application/msword";
        break;
      default:
        contentType = "";
    }
    saveByteArray(fileName, bytes, contentType);
  };

  /**
   *
   * @param {String} reportName | File name
   * @param {Byte} byte | Byte array
   * @param {String} contentType | Type of content/file
   */
  const saveByteArray = (reportName, byte, contentType) => {
    let blob = new Blob([byte], { type: contentType });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    let fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const downloadHandler = async (file) => {
    try {
      let response = await axios.post(
        BASE_URL + "/get-s3-file",
        {
          file_id: [file],
          file_type: String(type).toUpperCase(),
        },
        {
          headers: {
            "X-Auth-Token": temp,
          },
        }
      );
      if (!response?.data.success) {
        let message = response?.data?.message;
        if (message === "Invalid Access Token.. Please login") {
          return <Navigate to="/" />;
        }
      } else {
        if (response.data.success) {
          // eslint-disable-next-line array-callback-return
          toast.success("Successfully Downloaded!");
          response.data.data.map((value) => {
            let file = String(value.file).slice(2, -1);
            base64ToArrayBuffer(
              file,
              String(value.file_type).toLowerCase(),
              value.filename
            );
          });
        } else {
          return;
        }
      }
    } catch (error) {
      let message = error.response?.data?.message;
      toast.error(message);
    }
  };

  const handleFilterOpening = (e) => {
    setSearchFilter(!searchFilter);
    if (searchFilter) setFileName();
  };
  const handleDateFilter = (e) => {
    setDateDialog(!dateDialog);
    if (dateDialog) {
      setDate("");
      setstartDate("");
      setEndDate("");
      handleDateFilterApply(e);
    }
  };

  const Filter = ({ value }) => {
    if (value === "Upload By") {
      return (
        <>
          <button
            onClick={() => {
              setfilterstatus(!filterstatus);
              if (filterstatus) setinputstatus({});
            }}
            className="filter-state-handler"
          >
            {filterstatus ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }

    if (value === "File Name") {
      return (
        <>
          <button
            onClick={handleFilterOpening}
            className="filter-state-handler"
          >
            {searchFilter ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
    if (value === "Upload Date") {
      return (
        <>
          <button onClick={handleDateFilter} className="filter-state-handler">
            {dateDialog ? <GrFormClose /> : <BiFilterAlt />}
          </button>
        </>
      );
    }
  };

  const handleDateFilterApply = async (e) => {
    e.preventDefault();
    let url =
      pageName === "Output Folder"|| pageName === "Input Folder"
        ? "/get-io-folder-details"
        : "/get-user-files-details";
    try {
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },

        params: {
          file_name: filename,
          tab: pageName === "Output Folder"? "output" : "input",
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again")
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          if (pageName === "Output Folder"|| pageName === "Input Folder") {
            setUserInputData(response?.data?.data?.Input);
            setUserOutputData(response?.data?.data?.Output);
          } else {
            setUserInputData(response?.data?.data?.root.Input);
            setUserOutputData(response?.data?.data?.root.Output);
          }
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const handleDateFilterApply2 = async (e) => {
    e.preventDefault();
    let url =
      pageName === "Output Folder"|| pageName === "Input Folder"
        ? "/get-io-folder-details"
        : "/get-user-files-details";
    try {
      let response = await axios.get(BASE_URL + url, {
        headers: {
          "X-Auth-Token": temp,
        },

        params: {
          file_name: filename,
          tab: pageName === "Output Folder" ? "output" : "input",
          start_date:
            startDate !== "" ? moment(startDate).format("MM/DD/YYYY") : null,
          end_date:
            EndDate !== "" ? moment(EndDate).format("MM/DD/YYYY") : null,
        },
      });
      if (response?.data.blocked) {
        toast.error("Please Login Again")
        sessionStorage.clear();
        navigate("/");
      } else {
        if (response.status === 200) {
          if (pageName === "Output Folder"|| pageName === "Input Folder") {
            setUserInputData(response?.data?.data?.Input);
            setUserOutputData(response?.data?.data?.Output);
          } else {
            setUserInputData(response?.data?.data?.root.Input);
            setUserOutputData(response?.data?.data?.root.Output);
          }
        } else {
          return;
        }
      }
    } catch (error) {
      // let message = error.response?.data?.message;
      // toast.error(message);
      console.log("Something went wrong..!");
    }
  };

  const DateFilterComponent = () => {
    return (
      <>
        <form className="dateFilterForm" onSubmit={handleDateFilterApply2}>
          <input
            type="date"
            className="date-filter"
            placeholder="From"
            value={startDate}
            onChange={(e) => {
              e.preventDefault();
              setstartDate(e.target.value);
            }}
          />
          <input
            type="date"
            className="date-filter"
            min={startDate}
            placeholder="To"
            value={EndDate}
            onChange={(e) => {
              e.preventDefault();
              setEndDate(e.target.value);
            }}
          />
          <div style={{ display: "flex" }}>
            <button className="apply-button" type="submit">
              Apply
            </button>
            <button
              className="reset-button"
              type="reset"
              onClick={() => {
                setDate("");
                setstartDate("");
                setEndDate("");
              }}
            >
              <BiReset />
            </button>
          </div>
        </form>
      </>
    );
  };

  const SearchFilterComponent = useCallback(() => {
    return (
      <>
        <div
          className="searchFilterForm"
          style={{
            top: "auto",
          }}
        >
          <input
            type="text"
            placeholder="..search"
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            value={filename}
          />
        </div>
      </>
    );
  }, []);

  useEffect(() => {
    handleUserTasks();
  }, [filename, inputstatus]);

  function compareNameAscending(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name?.toUpperCase();
    const bandB = b.name?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  function compareNameDescending(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.name?.toUpperCase();
    const bandB = b.name?.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  return (
    <>
      <TableRow>
        <TableCell
          align="center"
          sx={{ padding: "0", width: "30px" }}
        ></TableCell>
        {tableHeading &&
          tableHeading.map((value) => {
            return (
              <>
                <TableCell
                  style={{
                    font: "normal normal 700 13px/21px Segoe UI",
                    color: "#0D0D4E",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {value === "Upload By" && filterstatus && (
                      <>
                        <div
                          className="searchFilterForm"
                          style={{
                            top: "150px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="..search"
                            onChange={(e) => {
                              setinputstatus(e.target.value);
                            }}
                            value={inputstatus}
                          />
                        </div>
                      </>
                    )}

                    {value === "File Name" && (
                      <button
                        style={{
                          marginLeft: "0px",
                        }}
                        className="filter-state-handler"
                        onClick={() => {
                          setsortByName(!sortByName);

                          if (sortByName) {
                            userTaskInputData.sort(compareNameAscending);
                          }

                          if (!sortByName) {
                            userTaskInputData.sort(compareNameDescending);
                          }
                        }}
                      >
                        {sortByName ? <FaSortDown /> : <FaSortUp />}
                      </button>
                    )}

                    {value === "Upload Date" && (
                      <button
                        style={{
                          marginLeft: "0px",
                        }}
                        className="filter-state-handler"
                        onClick={() => {
                          setsortByDate(!sortByDate);

                          if (sortByDate) {
                            userTaskInputData.sort((a, b) => {
                              return (
                                new Date(a.uploaded_at) -
                                new Date(b.uploaded_at)
                              );
                            });
                          }

                          if (!sortByDate) {
                            userTaskInputData.sort((a, b) => {
                              return (
                                new Date(b.uploaded_at) -
                                new Date(a.uploaded_at)
                              );
                            });
                          }
                        }}
                      >
                        {sortByDate ? <FaSortDown /> : <FaSortUp />}
                      </button>
                    )}

                    {value}
                    {<Filter value={value} />}
                  </span>

                  {/* Name Filter */}
                  {value === "File Name" && searchFilter && (
                    <SearchFilterComponent />
                  )}
                  {/* Date Filter */}
                  {value === "Upload Date" && dateDialog && (
                    <DateFilterComponent />
                  )}
                </TableCell>
              </>
            );
          })}
      </TableRow>
      {userTaskInputData &&
        userTaskInputData?.map((inputData, index) => (
          <TableRow>
            <TableCell
              align="center"
              sx={{ padding: "0", width: "30px" }}
            ></TableCell>
            <TableCell sx={{ font: "normal normal 500 13px/21px Segoe UI" }}>
              {pageName === "Output Folder"|| pageName === "Input Folder"
                ? inputData?.name
                : inputData.filename}
              {/* file name */}
            </TableCell>
            <TableCell sx={{ font: "normal normal 500 13px/21px Segoe UI" }}>
              {pageName === "Output Folder"|| pageName === "Input Folder"
                ? DateFormatter(inputData?.uploaded_at)
                : DateFormatter(inputData?.created_at)}
            </TableCell>
            <TableCell sx={{ font: "normal normal 500 13px/21px Segoe UI" }}>
              {pageName === "Output Folder"|| pageName === "Input Folder"
                ? inputData?.user
                : DateFormatter(inputData.created_at)}
            </TableCell>
            <TableCell>
              <div className="table-action-column">
                <button
                  style={{
                    border: "2px solid #50DFB2",
                  }}
                  onClick={() => downloadHandler(inputData?.file_id)}
                >
                  <HiDownload
                    style={{
                      color: "#50DFB2",
                    }}
                  />
                </button>
                <button
                  style={{
                    border: "2px solid #E11D1D",
                  }}
                  onClick={() => deleteHandler(inputData?.file_id)}
                >
                  <RiDeleteBinLine
                    style={{
                      color: "#E11D1D",
                    }}
                  />
                </button>
              </div>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}

export default AdminSubTable;
